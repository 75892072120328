

import _ from 'lodash'
import { MDBBadge, MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { Fragment, useEffect, useState } from 'react'
import Item from '../../../../classes/firebase/Item/Item'
import AdminItemEditForm from '../../../../classes/firebase/Item/forms/AdminItemEditForm'
import Product from '../../../../classes/firebase/Product/Product'
import ModalForm from '../../../../oldComponents/Modal/ModalForm'
export default function Items(props) {
    const { order, setOrder, active, products } = props
    const [selectedItems, setSelectedItems] = useState([])
    const [itemGroups, setItemGroups] = useState([])

    const [activeItem, setActiveItem] = useState(null)
    const [modal, setModal] = useState(false)

    useEffect(() => setItemGroups(Object.entries(_.groupBy([...order?.items], (i) => i?.product?.name || null))
        ?.sort((a, b) => a?.[0]?.localeCompare(b?.[0]))?.map((group, idx) => {
            return {
                product: [...products]?.find(p => p?.name === group?.[0]) || { name: 'Other Items' },
                items: group?.[1],
                show: true
            }
        })), [order.items])

    useEffect(() => {
        if (activeItem) {
            if (!modal) {
                setModal(true)
            }
        }
        else {
            if (modal) {
                setModal(false)
            }
        }
        if (selectedItems?.length) {
            setSelectedItems([])
        }
        return
    }, [activeItem])

    useEffect(() => {
        setModal(false)
        return setSelectedItems([])
    }, [active])



    const remove = async (e, item) => {
        e.preventDefault()
        await Item.delete(item)
        setOrder(order => {
            const newOrder = _.cloneDeep(order)
            const newItems = [...newOrder?.items]
            const idx = [...order?.items]?.findIndex((i) => i?.id === item?.id)
            if (idx !== -1) {
                newItems.splice(idx, 1)
            }
            newOrder.items = newItems
            return newOrder
        })
        return setActiveItem(null)
    }

    const save = async (e, item) => {
        e.preventDefault()
        await item.submit(e)
        setOrder(order => {
            const newOrder = _.cloneDeep(order)
            const newItems = [...newOrder?.items]
            const idx = [...order?.items]?.findIndex((i) => i?.id === item?.id)
            idx === -1 ? newItems.push(item) : newItems[idx] = item
            newOrder.items = newItems
            return newOrder
        })
        return setActiveItem(null)
    }

    const handleStatusUpdate = async (e, v) => {
        e.preventDefault()
        const newItems = await Promise.all([...order.items]?.map(async (item, idx) => {
            if ([...selectedItems]?.includes(item.id) && item.status !== v) {
                const newItem = item.onChange('status', v)
                await newItem.submit(e)
                return newItem
            }
            return item
        }))
        setOrder(order.onChange('items', newItems))
        return setSelectedItems([])
    }
    return (
        <>
            <MDBTable align='middle' className="border">
                <MDBTableHead>
                    <tr>
                        <th scope='col' className="text-start">
                            <div className='d-flex align-items-center'>
                                <MDBCheckbox
                                    checked={selectedItems?.length === order?.items?.length}
                                    onChange={(e) => {
                                        if (selectedItems?.length === order?.items?.length) {
                                            return setSelectedItems([])
                                        } else {
                                            return setSelectedItems([...order.items]?.map(i => i.id))
                                        }
                                    }}
                                />
                                {selectedItems?.length > 0 &&
                                    <MDBDropdown group>
                                        <MDBDropdownToggle size='sm' color='secondary'>Mark Items</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem className="btn w-100 border-bottom shadow-0"
                                                onClick={async (e) => await handleStatusUpdate(e, 'With Customer')}>With Customer</MDBDropdownItem>
                                            <MDBDropdownItem className="btn w-100 border-bottom shadow-0"
                                                onClick={async (e) => await handleStatusUpdate(e, 'In Storage')}>In Storage</MDBDropdownItem>
                                            <MDBDropdownItem className="btn w-100 shadow-0"
                                                onClick={async (e) => await handleStatusUpdate(e, 'Delivered')}>Delivered</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                }
                            </div>
                        </th>
                        <th scope='col' className="text-end">
                            <MDBBtn
                                size="sm"
                                color='link'
                                onClick={async () => setActiveItem(await Item.init({ order: order, user: order.user }))}>
                                New
                            </MDBBtn>
                        </th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody className="bg-white">
                    {[...itemGroups]
                        ?.map((itemGroup, idx) => {
                            return (
                                <Fragment key={`itemGroup-${idx}`}>
                                    <tr >
                                        <td colSpan={2} className="text-start">
                                            <div className='d-flex align-items-center'>
                                                <MDBBtn
                                                    tag='a'
                                                    color='none'
                                                    className="me-2"
                                                    style={{ color: '#3b5998' }}
                                                    onClick={() => setItemGroups(itemGroups => {
                                                        const copy = [...itemGroups]
                                                        copy[idx].show = !copy[idx].show
                                                        return copy
                                                    })}>
                                                    <MDBIcon fas icon={`angle-${itemGroup?.show ? 'up' : 'down'}`} size="lg" />
                                                </MDBBtn>
                                                <p className="mb-0">{itemGroup?.product?.name} {`(${itemGroup?.items?.length})`}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    {[...itemGroup?.items]?.map((item, j) => (
                                        <tr key={`order-Items-${j}-${idx}`} className={itemGroup?.show ? '' : 'd-none'}>
                                            <td className="text-start">
                                                <div className='d-flex align-items-center ms-1'>
                                                    <MDBCheckbox
                                                        checked={selectedItems?.includes(item?.id)}
                                                        onChange={(e) => {
                                                            const copy = _.cloneDeep(selectedItems)
                                                            if (e.target.checked) {
                                                                copy.push(_.cloneDeep(item).id)
                                                            } else {
                                                                _.remove(copy, (id) => id === _.cloneDeep(item).id)
                                                            }
                                                            return setSelectedItems(_.uniq(copy))

                                                        }}
                                                    />
                                                    <img
                                                        src={typeof item?.images[0] === 'object' ? URL.createObjectURL(item?.images[0]) : item?.images[0] ||
                                                            (item?.product?.images[0] ? item?.product?.images[0] : Product.defaultProductImage)}
                                                        alt=''
                                                        style={{ width: '45px', height: '45px' }}
                                                        className=''
                                                    />
                                                    <div className='ms-3'>
                                                        <p className='fw-bold mb-1'>{item?.product?.name || item?.notes}</p>
                                                        <p className='text-muted mb-0'>{String(item?.number)}</p>
                                                        <MDBBadge color={item?.status === 'Delivered' ? 'success' : item?.status === 'In Storage' ? 'light' : 'danger'} light>
                                                            {item?.status}
                                                        </MDBBadge>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <MDBBtn
                                                    size="sm"
                                                    color='link'
                                                    onClick={async () => setActiveItem(item)}>
                                                    Edit
                                                </MDBBtn>
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>
                            )
                        })}
                </MDBTableBody>
            </MDBTable>
            <ModalForm
                title={activeItem?.number ? `Item ${activeItem?.number}` : 'New Item'}
                visible={modal}
                setVisible={setActiveItem}
                form={
                    <AdminItemEditForm
                        item={activeItem}
                        setItem={setActiveItem}
                        submit={save}
                        remove={remove}
                        products={products}
                    />} />
        </>
    )
}
