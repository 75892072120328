import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBSelect, MDBTextArea } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import ItemImagesCarousel from "../views/ItemImagesCarousel"
import { cleanSelectData } from "../../../../utils/formatting/formattingUtils"
import Product from "../../Product/Product"
import Price from "../../Price/Price"
import _ from 'lodash'
export default function AdminItemEditForm(props) {
    const { item, setItem, submit, remove, products } = props
    if (products?.length) {
        return (
            <div>
                <h5 className="mb-4">Images</h5>
                <ItemImagesCarousel item={item} setItem={setItem} />
                <hr />
                <MDBSelect
                    label="Status"
                    className='my-3'
                    preventFirstSelection
                    search
                    data={['With Customer', 'In Storage', 'Delivered']?.map((d, idx) => (
                        {
                            text: d,
                            value: d,
                            defaultSelected: item?.status === d,
                            name: `status`
                        }
                    ))}
                    onValueChange={(e) => setItem(item.onChange(e.name, e.value))}
                />
                <MDBSelect
                    label="Item Type"
                    className='my-3'
                    preventFirstSelection
                    search
                    data={cleanSelectData([...products]?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((d, idx) => (
                        {
                            text: d?.name,
                            value: d,
                            defaultSelected: item?.product?.id === d?.id,
                            name: `product`
                        }
                    )))}
                    onValueChange={(e) => setItem(item.onChange(e.name, e.value))}
                />

                {item?.product?.name === 'Other/Misc Item' &&
                    <>
                        <MDBInput
                            label='$/month'
                            type='number'
                            value={item?.price?.unit_amount / 100 || 0}
                            className="mb-3"
                            onChange={async (e) => {
                                const customPrice = {
                                    product: _.cloneDeep(item)?.product?.id,
                                    currency: 'usd',
                                    recurring: {
                                        interval: 'month',
                                        interval_count: 1
                                    },
                                    unit_amount: e.target.value * 100,
                                    custom: true
                                }
                                return setItem(item?.onChange(`price`, customPrice))
                            }}
                        />
                    </>}

                <MDBTextArea
                    name={'notes'}
                    value={item?.notes || ''}
                    label="Notes or Requests"
                    className='mb-3'
                    rows={4}
                    onChange={(e) => setItem(item.onChange(e.target.name, e.target.value))}
                />



                <MDBRow>
                    <MDBCol className="d-flex justify-content-start">
                        <MDBBtn onClick={async (e) => await submit(e, item)}>Save</MDBBtn>
                    </MDBCol>
                    <MDBCol className="d-flex justify-content-start">
                        <MDBBtn color="danger" onClick={async (e) => await remove(e, item)}>Delete</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}

