import {
    MDBBtn, MDBBtnGroup, MDBCard,
    MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBRadio, MDBRow
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderLocationSelect from '../../../classes/firebase/Location/forms/OrderLocationSelect';
import Order from '../../../classes/firebase/Order/Order';
import OrderSeasonSelect from '../../../classes/firebase/Season/forms/OrderSeasonSelect';
import User from '../../../classes/firebase/User/User';
import ContactForm from '../../../classes/local/Contact/forms/ContactForm';
import OrderContactFields from '../../../oldComponents/Order/Forms/FormElements/OrderContactFields';

import { useAuth } from '../../../contexts/AuthContext';
import { GenerateRandomNumber } from '../../../utils/formatting/formattingUtils';

export default function CreateOrder() {
    const { user } = useAuth()
    const navigate = useNavigate()
    const [order, setOrder] = useState(null)
    const [validated, setValidated] = useState(false)
    const [loading, setLoading] = useState(true)
    const [step, setStep] = useState(0)


    useEffect(() => {
        (async () => {
            setOrder(await Order.init({ user: new User(user) }))
        })()
    }, [])

    useEffect(() => {
        order && setLoading(false)
    }, [order])
    return (
        <MDBContainer>
            <MDBCard className='text-black m-1 m-sm-2 m-md-5' style={{ borderRadius: '25px', minHeight: '75vh' }}>
                <MDBCardBody>
                    <MDBRow className='d-flex flex-column justify-content-center align-items-center'>
                        <MDBCol md='10' lg='6' className='d-flex flex-column '>

                            <h5 className="text-center h4 fw-bold mb-5 mx-1 mx-md-4 mt-4">New Order</h5>
                            {!loading &&
                                <>
                                    <CreateOrderFormStepOne order={order} setOrder={setOrder} active={step === 0} setValidated={setValidated} />

                                    <CreateOrderFormStepTwo order={order} setOrder={setOrder} active={step === 1} setValidated={setValidated} />

                                    <CreateOrderFormReview order={order} setOrder={setOrder} active={step === 2} setValidated={setValidated} />

                                    <MDBRow className='mt-4 mb-0'>
                                        <MDBCol className='d-flex justify-content-start' >
                                            <MDBBtn style={{ display: step === 0 && 'none' }} onClick={() => setStep(step - 1)}>Previous</MDBBtn>
                                        </MDBCol>
                                        <MDBCol className='d-flex justify-content-end'>
                                            {step === 2 ? <MDBBtn
                                                onClick={(e) => order.submit(e)?.then(() => navigate(`/account/orders/${order.number}`))}
                                                disabled={!validated}>Submit</MDBBtn>
                                                : <MDBBtn onClick={() => setStep(step + 1)} disabled={!validated}>Next</MDBBtn>}
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            }
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>

        </MDBContainer>
    )
}

const CreateOrderFormStepOne = ({ order, setOrder, active, setValidated }) => {
    const contactIndex = 0


    useEffect(() => {
        if (order && active) {
            if (order?.location?.id &&
                order?.season?.id &&
                order.contact?.[contactIndex]?.first &&
                order.contact?.[contactIndex]?.last &&
                order.contact?.[contactIndex]?.address?.street &&
                order.contact?.[contactIndex]?.address?.city &&
                order.contact?.[contactIndex]?.address?.state &&
                order.contact?.[contactIndex]?.address?.zip &&
                order.contact?.[contactIndex]?.email &&
                order.contact?.[contactIndex]?.phone) {
                setValidated(true)
            } else { setValidated(false) }
        }
    }, [order, active])

    return (
        <div style={{ display: !active && 'none' }}>
            <h6 className='mb-3 border-bottom'>Order Info</h6>
            <div className='mb-3'>
                <OrderLocationSelect order={order} setOrder={setOrder} />
            </div>
            <div className='mb-3'>
                <OrderSeasonSelect order={order} setOrder={setOrder} />
            </div>
            <div className='form-text'>
                I am a
            </div>
            <MDBBtnGroup className='w-100 d-flex justify-content-center mb-4'>
                <MDBCol size='6'>
                    <MDBRadio
                        wrapperTag='span'
                        btn
                        btnColor='secondary'
                        label='Student'
                        labelClass='w-100 rounded-0'
                        id='studentButton'
                        checked={order?.contact?.[contactIndex]?.type === 'Student'}
                        onChange={(e) => setOrder(order => {
                            const o = { ...order }
                            o.contact[0].type = !e.target.checked ? 'Student' : 'Parent'
                            o.contact[1].type = e.target.checked ? 'Student' : 'Parent'
                            return new Order(o)
                        })}
                    />
                </MDBCol>
                <MDBCol size='6'>
                    <MDBRadio
                        btn
                        btnColor='secondary'
                        label='Parent'
                        labelClass='w-100 rounded-0'
                        id='parentButton'
                        checked={order?.contact?.[contactIndex]?.type === 'Parent'}
                        onChange={(e) => setOrder(order => {
                            const o = { ...order }
                            o.contact[0].type = !e.target.checked ? 'Student' : 'Parent'
                            o.contact[1].type = e.target.checked ? 'Student' : 'Parent'
                            return new Order(o)
                        })}
                    />
                </MDBCol>
            </MDBBtnGroup>
            <ContactForm contact={order?.contact[0]} setContact={(contact) => setOrder(order.onChange('contact[0]', contact))} />
        </div>
    )
}

const CreateOrderFormStepTwo = ({ order, setOrder, active, setValidated }) => {
    const contactIndex = 1

    useEffect(() => {
        if (order && active) {
            if (
                order.contact?.[contactIndex]?.first &&
                order.contact?.[contactIndex]?.last &&
                order.contact?.[contactIndex]?.address?.street &&
                order.contact?.[contactIndex]?.address?.city &&
                order.contact?.[contactIndex]?.address?.state &&
                order.contact?.[contactIndex]?.address?.zip &&
                order.contact?.[contactIndex]?.email &&
                order.contact?.[contactIndex]?.phone) {
                setValidated(true)
            } else { setValidated(false) }
        }
    }, [order, active])

    return (
        <div style={{ display: !active && 'none' }}>
            <h6 className='mb-3 border-bottom'>{order?.contact?.[contactIndex]?.type} Contact Info</h6>

            <ContactForm contact={order?.contact[1]} setContact={(contact) => setOrder(order.onChange('contact[1]', contact))} />
        </div>
    )
}

const CreateOrderFormReview = ({ order, setOrder, active, setValidated }) => {

    useEffect(() => {
        if (order && active) {
            if (order.number) { setValidated(true) }
            else { setValidated(false) }
        }
    }, [order, active])

    useEffect(() => {
        if (active) {
            setOrder(order.onChange('number', `${order?.location?.reservationPrefix}-${GenerateRandomNumber(6)}`))
        }
    }, [active])

    return (
        <div style={{ display: !active && 'none' }}>
            <h6 className='mb-3 border-bottom'>Review</h6>
            <p>Campus: {order?.location?.name}</p>
            <p>Season: {order?.season?.name}</p>
            <MDBRow>
                {order?.contact?.map((person, idx) => (
                    <MDBCard shadow='0' border background='white' className='mb-3 h-100' key={`review-Contact${idx}`} >
                        <MDBCardHeader >{person?.type} {person?.isDefault && `(Default Contact)`}</MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardTitle>{person?.first || 'First'} {person?.last || 'Last'}</MDBCardTitle>
                            <p className="mb-1">{person?.address?.street || 'Street'}</p>
                            <p className="mb-1">{person?.address?.city || 'City'} {person?.address?.state || 'State'} {person?.address?.zip || 'Zip'}</p>
                            <p className="mb-1">{person?.phone || '(XXX)-XXX-XXXX'}</p>
                            <p className="mb-1">{person?.email || 'example@example.com'}</p>
                        </MDBCardBody>
                    </MDBCard>
                ))}
            </MDBRow>
        </div>
    )
}




