import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import Location from '../../../classes/firebase/Location/Location'
import Order from '../../../classes/firebase/Order/Order'
import _ from 'lodash'
export default function AdminCalendar() {
    const [orders, setOrders] = useState([])
    const [locations, setLocations] = useState([])

    useEffect(() => {
        (async () => {
            return setOrders(await Order.getCollection())
        })()
    }, [])

    useEffect(() => {
        (async () => {
            return setLocations(await Location.getCollection())
        })()
    }, [])

    const [today, setToday] = useState(new Date())
    const [currentMonth, setCurrentMonth] = useState(null)

    useEffect(() => {
        return setCurrentMonth(updateMonth(today?.getMonth(), today?.getFullYear()))
    }, [])

    const updateMonth = (newMonth, newYear) => {

        const firstDay = new Date(newYear, newMonth, 1)
        const lastDay = new Date(newYear, newMonth + 1, 0)

        const numDays = lastDay?.getDate()
        return {
            title: firstDay?.toLocaleDateString('en-US', { month: 'long' }),
            monthNumber: firstDay?.getMonth(),
            yearNumber: firstDay?.getFullYear(),
            firstDay: firstDay?.getDay(),
            numDays: numDays,
            numRows: Math.ceil(numDays / 7)
        }

    }

    const iterateMonth = (change) => {
        if (change === 1 && currentMonth?.monthNumber === 11) {
            return setCurrentMonth(updateMonth(1, currentMonth?.yearNumber + 1))
        }
        else if (change === -1 && currentMonth?.monthNumber === 0) {
            return setCurrentMonth(updateMonth(11, currentMonth?.yearNumber - 1))
        }
        else {
            return setCurrentMonth(updateMonth(currentMonth?.monthNumber + change, currentMonth?.yearNumber))
        }
    }

    return (
        <MDBContainer fluid className="mt-3">
            <MDBRow className='justify-content-center'>
                <MDBCol md='9'>
                    {false && orders && orders?.map((order, idx) => (
                        <p>{order?.number}</p>
                    ))}
                </MDBCol>
            </MDBRow>
            <div className='border'>
                <div className='text-center border-bottom'>
                    <MDBRow center>
                        <MDBCol>
                            <MDBBtn
                                color='link'
                                onClick={() => iterateMonth(-1)}
                            >
                                Previous
                            </MDBBtn>
                        </MDBCol>

                        <MDBCol>
                            <h6>{currentMonth?.title} {currentMonth?.yearNumber}</h6>
                        </MDBCol>

                        <MDBCol>
                            <MDBBtn
                                color='link'
                                onClick={() => iterateMonth(1)}
                            >
                                Next
                            </MDBBtn>

                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBTable small>
                    <MDBTableHead >
                        <tr>
                            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']?.map((day, idx) => (
                                <th
                                    scope='col'
                                    className='text-center'
                                    style={{ width: '100px' }}
                                    key={`calHeader${day}`}>{day}</th>
                            ))}

                        </tr>
                    </MDBTableHead>

                    <MDBTableBody>
                        {currentMonth && _.range(0, currentMonth?.numRows + 1)?.map((week) => (
                            <tr key={`calendar${week}`}>
                                {_.range(0, 7)?.map((day) => {
                                    const currentDate = (week * 7) + day - currentMonth?.firstDay + 1

                                    if (currentDate > 0 && currentDate <= currentMonth?.numDays) {
                                        const fullDate = new Date(currentMonth?.yearNumber, currentMonth?.monthNumber, currentDate)

                                        const pickups = [...orders]?.filter(o => {
                                            if (o?.pickup?.date?.date === fullDate?.toLocaleDateString('en-US')) {
                                                return true
                                            }
                                            if (new Date(o?.pickup?.date?.date) - fullDate === 0) {
                                                return true
                                            }
                                            return false
                                        })?.sort((a, b) => {
                                            a?.pickup?.date?.time?.localeCompare(b?.pickup?.date?.time)
                                        })

                                        const dropoffs = [...orders]?.filter(o => {
                                            if (o?.dropoff?.date?.date === fullDate?.toLocaleDateString('en-US')) {
                                                return true
                                            }
                                            if (new Date(o?.dropoff?.date?.date) - fullDate === 0) {
                                                return true
                                            }
                                            return false
                                        })?.sort((a, b) => {
                                            a?.pickup?.date?.time?.localeCompare(b?.pickup?.date?.time)
                                        })
                                        return (
                                            <td key={`day${week}${day}`}>
                                                <MDBRow start>
                                                    {currentDate}
                                                </MDBRow>
                                                <MDBRow start>
                                                    {pickups?.length > 0 && <p className='fw-bold mb-0 text-center'>Pickups</p>}
                                                    {pickups?.map((o, j) => (
                                                        <a
                                                            href={`/account/orders/${o?.id}`}
                                                            className={`ps-1 pe-0 ${o?.pickup?.complete ? 'text-decoration-line-through' : ''}`}
                                                            key={`pickup${week}${day}${j}`}>
                                                            {o?.contact?.[0]?.fullName ?
                                                                o?.contact?.[0]?.fullName :
                                                                o?.user?.name}
                                                        </a>

                                                    ))}
                                                    {dropoffs?.length > 0 && <p className='fw-bold mb-0'>Dropoffs</p>}
                                                    {dropoffs?.map((o, j) => (
                                                        <a
                                                            href={`/account/orders/${o?.id}`}
                                                            className={`ps-1 pe-0 ${o?.dropoff?.complete ? 'text-decoration-line-through' : ''}`}
                                                            key={`dropoff${week}${day}${j}`}>
                                                            {o?.contact?.[0]?.fullName ?
                                                                o?.contact?.[0]?.fullName :
                                                                o?.user?.name}
                                                        </a>

                                                    ))}
                                                </MDBRow>
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={`day${week}${day}`}>
                                            </td>
                                        )
                                    }
                                })}

                            </tr>
                        ))}

                    </MDBTableBody>
                </MDBTable>
            </div>
        </MDBContainer>
    )
}

