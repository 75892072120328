import Model from "../Model";
import image from "./image";
import requests from "./requests";
import _ from 'lodash'

export default class utils {

    static requests = requests

    onChange(path, data) {
        const obj = _.set(_.cloneDeep(this), path, data)
        return obj
    }

    


}

