import { MDBBtn, MDBContainer, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Order from "../../../classes/firebase/Order/Order";
import User from "../../../classes/firebase/User/User";

import { useAuth } from "../../../contexts/AuthContext";
import { AccountOrdersPane } from "./tabs/AccountOrdersPane";
import { AccountSettingsPane } from "./tabs/AccountSettingsPane";


export default function Account() {
    const [user, setUser] = useState(null)
    const [orders, setOrders] = useState(null)
    const [loading, setLoading] = useState(true)
    const { currentUser } = useAuth()
    useEffect(() => {
        (async () => {
            const user = await User.get({ id: currentUser?.uid })
            const orders = await user.getOrders()
            setUser(user)
            return setOrders(orders)
        })()
    }, [])

    useEffect(() => {
        orders && setLoading(false)
    }, [orders])

    const [tab, setTab] = useState(0)
    const tabs = [
        {
            title: 'Orders',
            pane: <AccountOrdersPane orders={orders} loading={loading} />
        },
        {
            title: 'Account Settings',
            pane: <AccountSettingsPane user={user} />
        },
    ]

    return (

        <MDBContainer style={{ maxWidth: '800px' }} >            
            <MDBTabs className='mb-3'>
                {tabs?.map((t, idx) => (
                    <MDBTabsItem key={`tab${idx}`}>
                        <MDBTabsLink onClick={() => setTab(idx)} active={tab === idx}>
                            {t?.title}
                        </MDBTabsLink>
                    </MDBTabsItem>
                ))}
            </MDBTabs>

            <MDBTabsContent>
                {tabs?.map((t, idx) => (
                    <MDBTabsPane show={tab === idx} key={`pane${idx}`}>
                        {t?.pane}
                    </MDBTabsPane>
                ))}
            </MDBTabsContent>
        </MDBContainer>

    )
}
