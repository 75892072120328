import * as fb from "firebase/firestore";
import Address from "../Address/Address";
import Dorm from "../Dorm/Dorm";

import Model from "../Model";
import Season from "../Season/Season";

export default class Location extends Model {
    constructor(data) {
        super(data)

        this.name = data.name
        this.active = data.active
        this.reservationPrefix = data.reservationPrefix
        this.address = data.address
    }

    static collectionName = 'locations'


    static async init(data) {
        const d =
        {
            name: data?.name || null,
            active: data?.active || false,
            reservationPrefix: data?.reservationPrefix || null,
            address: Address?.init(data?.address)

        }
        return new Location(d)
    }

    static transformToFirestore(data) {
        return {
            name: data?.name || null,
            active: data?.active || false,
            reservationPrefix: data?.reservationPrefix || null,
            address: Address?.toObject(data?.address)
        }
    }

    async getDorms() {
        const q = fb.query(Dorm.collection, fb.where('location', '==', this.ref))
        const req = await fb.getDocs(q)
        return await Promise.all(req?.docs?.map(async (doc) => await doc.data()))
    }

    async getSeasons() {
        const q = fb.query(Season.collection, fb.where('location', '==', this.ref))
        const req = await fb.getDocs(q)
        return await Promise.all(req?.docs?.map(async (doc) => await doc.data()))
    }

}