import { MDBCheckbox, MDBInput, MDBTable, MDBTableBody } from "mdb-react-ui-kit";
import { useEffect, useState } from 'react';
import AddressForm from "../../../../classes/local/Address/forms/AddressForm";
import FormCard from "../../Layouts/FormCard";

export default function Summary(props) {
    const { location, updateLocation, active } = props

    useEffect(() => setEditedLocation(location), [location])

    const [editedLocation, setEditedLocation] = useState(null)


    const reset = () => setEditedLocation(location)

    const submit = async (e) => {
        e.preventDefault()
        return await updateLocation(e, editedLocation)
    }


    return (
        <div>
            <FormCard
                title='General'
                formView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        <MDBInput
                                            value={editedLocation?.name || ''}
                                            onChange={(e) => setEditedLocation(editedLocation.onChange('name', e.target.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Address
                                    </td>
                                    <td>
                                        <AddressForm address={editedLocation?.address} setAddress={(address) => setEditedLocation(editedLocation.onChange('address', address))} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Reservation Prefix
                                    </td>
                                    <td>
                                        <MDBInput
                                            value={editedLocation?.reservationPrefix || ''}
                                            onChange={(e) => setEditedLocation(editedLocation.onChange('reservationPrefix', e.target.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Active
                                    </td>
                                    <td>
                                        <MDBCheckbox
                                            checked={editedLocation?.active}
                                            onChange={(e) => setEditedLocation(editedLocation.onChange('active', e.target.checked))}
                                        />
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                displayView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        {location?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Address
                                    </td>
                                    <td>
                                        {location?.address?.toString()}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Reservation Prefix
                                    </td>
                                    <td>
                                        {location?.reservationPrefix}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Active
                                    </td>
                                    <td>
                                        {location?.active ? 'Yes' : 'No'}
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                submit={submit}
                reset={reset}
                active={active}
            />
        </div>
    )
}
