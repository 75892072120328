import _ from 'lodash';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBListGroup, MDBListGroupItem, MDBPopconfirm, MDBPopconfirmMessage, MDBTextArea, MDBTypography } from "mdb-react-ui-kit";
import { useEffect, useState } from 'react';
import { useAuth } from "../../../../contexts/AuthContext";
import { collection, getDoc, getDocs, or, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/firebaseConfig';
export default function History({ order, active }) {
    const [logs, setLogs] = useState([])
    useEffect(() => {
        const load = async () => {
            if (!_.get(order, 'id')) return setLogs([])
            const logs = await getDocs(query(collection(db, 'changeLog'),
                or(
                    where('document', '==', _.get(order, 'ref')),
                    where('document', 'in', _.map(_.get(order, 'items'), 'ref'))
                )))
            const data = await Promise.all(_.map(logs.docs, async l => {
                const data = { ...l.data(), id: _.get(l, 'id'), ref: _.get(l, 'ref') }
                if (_.get(data, 'document.parent.id') === 'items') {
                    _.set(data, 'collection', 'Items')
                    _.set(data, 'document', await getDoc(_.get(data, 'document')).then(d => d.data()).catch(e => _.get(data, 'document')))
                }
                return data
            }))
            return setLogs(data)
        }
        load()
    }, [_.get(order, 'id')])
    return (
        <div>
            <MDBCard className="rounded-0 mb-2 mb-lg-4">
                <MDBCardHeader><MDBCardTitle className="mb-0">Order History</MDBCardTitle></MDBCardHeader>
                <MDBCardBody>
                    <MDBListGroup>
                        {[...logs]?.sort((a, b) => b?.time - a?.time)?.map((log, idx) => (
                            <MDBListGroupItem key={`log-${idx}`} className="mt-3 bg-white rounded-0 border">
                                <div className=''>
                                    <div>
                                        {_.get(log, 'collection') === 'Items' &&
                                            <p className='fw-bold mb-0'>Item {_.get(log, 'document.number')}</p>
                                        }
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            {[...log?.changes]?.map((update, j) => <p className='mb-1' key={`change-${idx}-${j}`}>{update}</p>)}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0 text-muted">
                                            {log?.time?.toDate()?.toLocaleString('en-US')}
                                        </p>
                                    </div>
                                </div>

                            </MDBListGroupItem>
                        ))}
                    </MDBListGroup>
                </MDBCardBody>
            </MDBCard>
        </div>
    )
}
