import * as fb from 'firebase/firestore';
import { db, functions } from '../../../firebase/firebaseConfig';
import Model from "../../Model"
import Order from "../Order/Order"
import { httpsCallable } from 'firebase/functions';
import _ from 'lodash'

export default class User extends Model {
    constructor(data) {
        super(data)
        this.first = data.first
        this.last = data.last
        this.email = data.email
        this.phone = data.phone
        this.role = data.role
        this.customer = data.customer
    }

    static collectionName = 'users'

    static populate(data) {
        return new User(
            {
                first: data?.first || null,
                last: data?.last || null,
                email: data?.email || null,
                phone: data?.phone || data?.phoneNumber || null,
                role: data?.role || 'customer',
                customer: data?.customer || null
            })
    }

    static async init(data) {
        const d =
        {
            first: data?.first || null,
            last: data?.last || null,
            email: data?.email || null,
            phone: data?.phone || data?.phoneNumber || null,
            role: data?.role || 'customer',
            customer: data?.customer || null
        }
        return new User(d)
    }

    static transformToFirestore(data) {
        const { customer, ...filtered } = data
        return filtered
    }

    static getName(data){
        return `${data?.first} ${data?.last}`
    }

    get name() {
        return `${this?.first} ${this?.last}`
    }

    async getOrders() {
        //reformat this to avoid having to get all nested fields of order
        return await Order.query('user', this?.ref)
    }

    async getStripeCustomer() {
        try {
            const doc = await fb.getDoc(fb.doc(db, 'customers', this.id))
            if (doc.exists) {
                return this.customer = doc.data()
            }
            return this.customer = null
        }
        catch {
            return this.customer = null
        }

    }

    async createCustomerPortalSession(returnUrl) {
        try {
            const dummyCustomer = await _.cloneDeep(this).getStripeCustomer()
            if (dummyCustomer?.id) {
                const req = httpsCallable(functions, 'api-http-stripe-requests-customer-createPortalSession')
                const res = await req({ id: dummyCustomer?.id, return_url: returnUrl })
                return res?.data?.url || null
            }

            return null
        }
        catch {
            return null
        }
    }


}