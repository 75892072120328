import { doc, setDoc } from 'firebase/firestore'
import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCheckbox, MDBCol, MDBContainer, MDBInput, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatPhoneNumber } from '../../utils/formatting/formattingUtils'
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase/firebaseConfig'

export default function Authenticate() {
  const { login, signup, resetPassword } = useAuth()
  const navigate = useNavigate()
  const [tab, setTab] = useState('Login')
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [loginUser, setLoginUser] = useState({
    email: '',
    password: ''
  })
  const [signupUser, setSignupUser] = useState({
    first: '',
    last: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: false
  })

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [resetPasswordMessage, setResetPasswordMessage] = useState()

  const signupChange = (e) => {
    setSignupUser({ ...signupUser, [e.target.name]: e.target.name === 'phone' ? formatPhoneNumber(e.target.value) : e.target.value })
  }

  const loginChange = (e) => {
    setLoginUser({ ...loginUser, [e.target.name]: e.target.value })
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError()
    try {
      await login(loginUser.email, loginUser.password)
      navigate("/account")
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  const handleSignup = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError()
    if (signupUser.password === signupUser.confirmPassword) {
      try {
        const response = await signup(signupUser.email, signupUser.password)
        if (response.user.uid) {
          const newUser = {
            id: response.user.uid,
            first: signupUser.first,
            last: signupUser.last,
            phone: signupUser.phone,
            email: signupUser.email
          }
          await setDoc(doc(db, 'users', newUser.id), newUser, { merge: true })
          navigate("/account")
        }

      } catch (error) {
        setError(error.message)
        setLoading(false)
      }
    }

    else {
      setError("Passwords must match")
    }
    setLoading(false)
  }


  return (
    <MDBContainer>
      <MDBRow center className='text-center my-3'>
        <MDBCol sm='6'>
          <MDBTabs pills justify className='mb-3'>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => setTab('Login')}
                active={tab === 'Login'}
              >
                Login
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => setTab('Register')}
                active={tab === 'Register'}
              >
                Register
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane show={tab === 'Login'}>
              <form onSubmit={(e) => handleLogin(e)}>
                <MDBCard>
                  {error && <MDBCardHeader>  <MDBAlert show color="danger">{error}</MDBAlert></MDBCardHeader>}
                  <MDBCardBody>
                    <MDBInput className='mb-4' type='email' name='email' value={loginUser.email} label='Email address' autoComplete='true' onChange={(e) => loginChange(e)} />
                    <MDBInput className='mb-4' type='password' name='password' value={loginUser.password} label='Password' autoComplete='true' onChange={(e) => loginChange(e)} />
                    <MDBBtn rounded type='submit' disabled={loading} className='mb-4'>
                      Sign in
                    </MDBBtn>

                  </MDBCardBody>
                  <MDBCardFooter>
                    <MDBRow center className='mb-4'>
                      <MDBBtn onClick={(e) => setTab("Forgot Password")} type="button" rounded>Forgot Password</MDBBtn>
                    </MDBRow>
                  </MDBCardFooter>
                </MDBCard>
              </form>

            </MDBTabsPane>
            <MDBTabsPane show={tab === 'Register'}>
              <form onSubmit={(e) => handleSignup(e)}>
                <MDBCard>
                  {error && <MDBCardHeader>  <MDBAlert show color="danger">{error}</MDBAlert></MDBCardHeader>}
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm='6'>
                        <MDBInput className='mb-4' label='First Name' autoComplete='true' name='first' value={signupUser.first} onChange={(e) => signupChange(e)} />
                      </MDBCol>
                      <MDBCol sm='6'>
                        <MDBInput className='mb-4' label='Last Name' autoComplete='true' name='last' value={signupUser.last} onChange={(e) => signupChange(e)} />
                      </MDBCol>
                    </MDBRow>
                    <MDBInput className='mb-4' type='email' label='Email address' autoComplete='true' name='email' value={signupUser.email} onChange={(e) => signupChange(e)} />
                    <MDBInput className='mb-4' type='tel' label='Phone' autoComplete='true' name='phone' value={signupUser.phone} onChange={(e) => signupChange(e)} />
                    <MDBInput className='mb-4' type='password' label='Password' autoComplete='true' name='password' value={signupUser.password} onChange={(e) => signupChange(e)} />
                    <MDBInput className='mb-4' type='password' label='Repeat password' autoComplete='true' name='confirmPassword' value={signupUser.confirmPassword} onChange={(e) => signupChange(e)} />

                    <MDBCheckbox
                      checked={signupUser?.acceptedTerms === true}
                      onChange={(e) => setSignupUser({ ...signupUser, 'acceptedTerms': !signupUser.acceptedTerms })}
                      wrapperClass='d-flex justify-content-center mb-2'
                      label='I have read and agree to the terms'
                    />
                    <p className='mb-4'>
                      <a href="/terms" target="_blank">Read our Terms and Conditions</a>
                    </p>
                  </MDBCardBody>
                  <MDBCardFooter>
                    <MDBBtn rounded type='submit' disabled={!signupUser.acceptedTerms || loading || !signupUser?.email || !signupUser?.password || !signupUser?.phone} className='mb-4'>
                      Register
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCard>
              </form>

            </MDBTabsPane>
          </MDBTabsContent>

          <MDBTabsContent>
            <MDBTabsPane show={tab === 'Forgot Password'}>
              <form onSubmit={(e) => {
                e.preventDefault()
                resetPassword(forgotPasswordEmail)
                setResetPasswordMessage("Please check your inbox for further instructions")
              }
              }>
                <MDBCard>
                  {error && <MDBCardHeader>  <MDBAlert show color="danger">{error}</MDBAlert></MDBCardHeader>}
                  {resetPasswordMessage && <MDBCardHeader>  <MDBAlert show color="success">{resetPasswordMessage}</MDBAlert></MDBCardHeader>}
                  <MDBCardBody>

                    <MDBInput className='mb-4' type='email' label='Email address' autoComplete='true' value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)} />

                  </MDBCardBody>
                  <MDBCardFooter>
                    <MDBBtn rounded type='submit' disabled={!forgotPasswordEmail} className='mb-4'>
                      Submit
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCard>
              </form>

            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

