import React from "react"
import { hydrateProduct } from "./ProductAPI"

export const defaultProduct = (item = null) => {
    return new Promise(async (resolve) => {
        let tmpObj = {
            name: item?.name || '',
            description: item?.description || '',
            active: item?.active || false,
            sizeUnit: item?.sizeUnit || 'cu. ft.',
            sizeVisibleOnCard: item?.sizeVisibleOnCard || false,
            category: item?.category || '',
            image: item?.image || defaultProductImage(),
            sortOrder: item?.sortOrder || '',
            pricingType: item?.pricingType || '',
            id: item?.id || null,
            stripeId: item?.stripeId || null
        }
        resolve(await hydrateProduct(tmpObj))
    })
}
export const productSizeMultiplier = () => {
    return parseFloat(3).toFixed(2)
}

export const defaultPrice = (item = null, idx = null) => {
    let tmpObj = {
        name: item?.name || '',
        description: item?.description || '',
        amount: item?.amount || null,
        sizeRange: item?.sizeRange || [null, null],
        stripeId: item?.stripeId || null
    }
    if (idx === 0 && (tmpObj?.sizeRange[0] === '' || tmpObj?.sizeRange[0] === null)) {
        tmpObj.sizeRange[0] = 0
    }
    return tmpObj
}

export const defaultProductImage = () => {
    return require('../../assets/siteImages/itemPlaceHolder.png')
}

export const defaultProductPricingOptions = () => {
    return ['Single', 'Tiered', 'Dynamic']
}



export const defaultDynamicSizePrice = (item = null) => {
    return {
        name: item?.name || '',
        description: item?.description || '',
        sizeBreakpoint: item?.sizeBreakpoint || '',
        amount: item?.amount || null,
        multiplier: item?.multiplier || null,
        type: item?.type || null
    }
}


export const defaultTieredPrice = (item = null) => {
    return {
        name: item?.name || '',
        description: item?.description || '',
        amount: item?.amount || null
    }
}