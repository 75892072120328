import React, { useEffect, useState } from 'react';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBDatatable
} from 'mdb-react-ui-kit';
import _ from 'lodash'
import Order from '../../../classes/firebase/Order/Order';

export default function Billing() {
    const [tab, setTab] = useState('Active')
    const handleTab = (t) => t !== tab ? setTab(t) : null
    const [orders, setOrders] = useState([])

    useEffect(() => {
        (async () => Promise.all([Order.getCollection()])
            ?.then(async ([orders]) => {
                return setOrders(await Promise.all(orders?.map(async (o) => {
                    await o?.user?.getStripeCustomer()
                    await o?.getSubscription()
                    return o
                })))
            })
        )()
    }, [])


    return (
        <>
            <MDBTabs pills justify className='mb-3'>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleTab('Active')} active={tab === 'Active'}>
                        Active
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleTab('Pending')} active={tab === 'Pending'}>
                        Pending
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
                <MDBTabsPane show={tab === 'Active'}>
                    <MDBDatatable
                        data={{
                            columns: [
                                { label: 'Order', field: 'order' },
                                { label: 'Customer', field: 'customer' },
                                { label: 'Status', field: 'status' },
                                { label: '$/Month', field: 'monthly' },
                                { label: 'Total', field: 'total' }
                            ],
                            rows: [...orders]?.filter((o) => o?.billing?.subscription)?.map(o => {
                                const row = {
                                    order: o?.number,
                                    customer: o?.user?.customer?.email
                                }
                                if (o?.billing?.subscription?.object === 'subscription') {
                                    row.status = o?.billing?.subscription?.status
                                    const subtotal = _.sum([...o?.billing?.subscription?.items?.data]?.map(i => i?.plan?.amount / 100))
                                    const percent_off = o?.billing?.subscription?.discount?.coupon?.percent_off
                                    const amount_off = o?.billing?.subscription?.discount?.coupon?.amount_off
                                    let coupon = 0
                                    if (percent_off) {
                                        coupon = (100 - percent_off) / 100 * subtotal
                                    }
                                    else if (amount_off) {
                                        coupon = amount_off / 100
                                    }
                                    row.monthly = subtotal - coupon
                                    row.total = row.monthly * parseInt(o?.season?.months)
                                }
                                else {
                                    row.status = o?.billing?.subscription?.schedule?.status
                                    const subtotal = 0//_.sum([...o?.billing?.subscription?.schedule?.phases?.[0]?.items]?.map(i => i?.plan?.amount / 100))
                                    const percent_off = o?.billing?.subscription?.subscription?.schedule?.discount?.coupon?.percent_off
                                    const amount_off = o?.billing?.subscription?.subscription?.schedule?.discount?.coupon?.amount_off
                                    let coupon = 0
                                    if (percent_off) {
                                        coupon = (100 - percent_off) / 100 * subtotal
                                    }
                                    else if (amount_off) {
                                        coupon = amount_off / 100
                                    }
                                    row.monthly = subtotal - coupon
                                    row.total = row.monthly * parseInt(o?.season?.months)
                                }
                                return row

                            })
                        }}

                    />

                </MDBTabsPane>
                <MDBTabsPane show={tab === 'tab2'}>Tab 2 content</MDBTabsPane>
                <MDBTabsPane show={tab === 'tab3'}>Tab 3 content</MDBTabsPane>
            </MDBTabsContent>
        </>
    )
}
