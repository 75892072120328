import { MDBBtn } from "mdb-react-ui-kit"
import { useState } from "react"
import { defaultProductImage } from "../../../oldComponents/Product/DefaultProduct"
import AddImageButton from "../../../forms/reusableComponents/AddImageButton"

export default function ItemImagesCarousel({ item, setItem }) {
    const [currentIndex, setCurrentIndex] = useState(0)
    return (
        <div>
            <div className="border-bottom pb-1">
                {item?.images && [...item?.images]?.map((img, idx) => (
                    <img
                        style={{ maxWidth: '300px', maxHeight: '200px', minHeight: '200px', objectFit: 'contain', display: currentIndex === idx ? `block` : `none` }}
                        key={`itmImg-${idx}`}
                        className={`w-100 mx-auto`}
                        src={typeof img === 'object' ? URL.createObjectURL(img) : img || defaultProductImage()}
                        alt='...'
                    />
                ))}
            </div>
            <div>
                <AddImageButton callback={(f) => {
                    setItem(item.onChange('images', [...item?.images, f]))
                    setCurrentIndex(item?.images?.length)
                }} />
                {item?.images && [...item?.images]?.map((img, idx) => (
                    <MDBBtn
                        style={{
                            justifyContent: 'center',
                            boxSizing: 'content-box',
                            flex: '0 1 auto',
                            width: '78px',
                            height: '78px',
                            padding: '0',
                            marginRight: '3px',
                            marginLeft: '3px',
                            textIndent: '-999px',
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: 0,
                            opacity: currentIndex === idx ? `1` : `.5`,
                            transition: 'opacity .6s ease',
                            boxShadow: 'none'
                        }}
                        key={`itmImgButton-${idx}`}
                        type='button'
                        onClick={(e) => setCurrentIndex(idx)}
                    >
                        <img
                            className="d-block w-100 img-fluid align-items-center justify-content-center"
                            src={typeof img === 'object' ? URL.createObjectURL(img) : img || defaultProductImage()}
                        />
                    </MDBBtn>
                ))}
            </div>
        </div>
    )
}
