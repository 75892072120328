import * as fb from "firebase/firestore";
import { MDBSelect } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { cleanSelectData } from "../../utils/formatting/formattingUtils";
import Contact from "../Contact/Contact";
import Dorm from "../Dorm/Dorm";
import Item from "../Item/Item";
import Location from "../Location/Location";
import Model from "../Model";
import Season from "../Season/Season";
import User from "../User/User";
import OrderItems from "./Forms/OrderItems";

export default class Order extends Model {
    constructor(data) {
        super(data)
        this.number = data.number
        this.cancelled = data.cancelled
        this.season = data.season
        this.user = data.user
        this.location = data.location
        this.contact = data.contact
        this.pickup = data.pickup
        this.dropoff = data.dropoff
        this.boxes = data.boxes
        this.billing = data.billing
    }

    static collectionName = 'orders'

    static async getByNumber(number) {
        const q = fb.query(this.collection, fb.where('number', '==', number))
        const req = await fb.getDocs(q)
        return await req?.docs?.[0]?.data()
    }


    async getItems() {
        const docs = await Order.getDocs(fb.query(Item.collection, fb.where('order', '==', this.ref)))
        return docs
    }


    static defaultContactField(contact = null, idx = null) {
        return {
            type: contact?.type || '',
            isDefault: contact?.isDefault || false,
            first: contact?.first || '',
            last: contact?.last || '',
            phone: contact?.phone || '',
            email: contact?.email || '',
            street: contact?.street || '',
            street2: contact?.street2 || '',
            city: contact?.city || '',
            state: contact?.state || '',
            zip: contact?.zip || '',
            index: (typeof contact?.index === 'number') ? contact?.index : (typeof idx === 'number') ? idx : null

        }
    }

    static calculateContactField(data) {
        const arr = []
        if (data?.length === 0 || !Array.isArray(data)) {
            arr.push(Contact.init({ type: 'Student', isDefault: true, index: 0 }))
            arr.push(Contact.init({ type: 'Parent', index: 1 }))
        }
        else {
            data.forEach((c, idx) => {
                arr.push(Contact.init({ ...c, index: c?.index ? c?.index : idx }))
            })

        }
        return arr
    }

    static dormSelect = (order, setOrder, mode, dorms = null) => {
        const [options, setOptions] = useState([])
        useEffect(() => {
            (async () => {
                if (dorms) {
                    setOptions([...dorms]?.filter((d) => d?.location?.id === order?.location?.id))
                }
                else {
                    setOptions(await order?.location.getDorms())
                }
            }
            )()
        }, [])

        return (
            <MDBSelect
                preventFirstSelection
                label='Dorm'
                data={cleanSelectData(options && [...options].concat([Dorm.otherDorm({ location: order?.location || null })]))?.map((d, idx) => (
                    {
                        text: d?.name,
                        value: d,
                        defaultSelected: order?.[mode]?.dorm?.name === 'Other' ?
                            order?.[mode]?.dorm?.name === d?.name
                            :
                            (d?.id && order?.[mode]?.dorm?.id === d?.id),
                        name: `${mode}.dorm`
                    }
                ))}
                className='mb-3'
                onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
            />
        )
    }

    static locationSelect = (order, setOrder, locations) => {
        const [options, setOptions] = useState([])
        useEffect(() => {
            if (locations) {
                setOptions(locations)
            }
            else {
                (async () => {
                    const q = fb.query(Location.collection, fb.where('active', '==', true))
                    const req = await fb.getDocs(q)
                    const locations = await Promise.all(req?.docs?.map(async (doc) => {
                        return doc.data()
                    }))
                    setOptions(locations)
                })()
            }
        }, [])

        return (
            <MDBSelect
                preventFirstSelection
                style={{ width: '300px' }}
                label='Campus'
                data={cleanSelectData(options && [...options]
                    ?.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
                    ?.map((i) => (
                        { text: `${i.name || ''}`, value: i, defaultSelected: order?.location?.id === i?.id }
                    )))}
                onValueChange={(e) => setOrder(new Order({ ...order, location: e.value }))}
            />
        )
    }

    static seasonSelect = (order, setOrder, seasons) => {
        const [options, setOptions] = useState([])
        const [refreshing, setRefreshing] = useState(false)

        useEffect(() => {
            if (seasons) {
                setOptions(seasons)
            }
            else {
                (async () => {
                    const q = fb.query(Season.collection, fb.where('active', '==', true))
                    const req = await fb.getDocs(q)
                    const seasons = await Promise.all(req?.docs?.map(async (doc) => await doc.data()))
                    setOptions(seasons)
                })()
            }
        }, [])
        useEffect(() => {
            if (!order?.location || (order?.location?.id !== order?.season?.location?.id)) {
                setOrder(order.onChange('season', null))
                setRefreshing(true)
            }
        }, [order?.location])

        useEffect(() => {
            setRefreshing(false)
        }, [refreshing])

        return (
            <>
                {refreshing ?
                    <MDBSelect
                        style={{ width: '300px' }}
                        label='Storage Season'
                        data={cleanSelectData([])}
                        key={1}
                        disabled
                    />
                    :
                    <MDBSelect
                        style={{ width: '300px' }}
                        preventFirstSelection
                        label='Storage Season'
                        data={cleanSelectData(options && [...options]
                            ?.filter((s) => s?.location?.id === order?.location?.id)?.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
                            ?.map((i) => (
                                { text: `${i.name || ''}`, value: i, defaultSelected: order?.season?.id === i?.id, name: 'season' }
                            )))}
                        disabled={!order?.location?.id}
                        onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
                        key={2}
                    />
                }
            </>
        )
    }

    static async init(data) {

        const d =
        {
            number: data?.number || null,
            cancelled: data?.cancelled || false,
            location: data?.location instanceof Location ? data?.location : await Location.get(data?.location) || null,
            season: data?.season instanceof Season ? data?.season : await Season.get(data?.season) || null,
            user: data?.user instanceof User ? data?.user : await User.get(data?.user) || null,
            contact: this.calculateContactField(data?.contact),
            pickup: {
                date: {
                    date: data?.pickup?.date?.date || null,
                    time: data?.pickup?.date?.time || null,
                    isCustom: data?.pickup?.date?.isCustom || false
                },
                dorm: data?.pickup?.dorm instanceof Dorm ? data?.pickup?.dorm : await Dorm.get(data?.pickup?.dorm) || null,
                buildingNumber: data?.pickup?.buildingNumber || null,
                room: data?.pickup?.room || null,
                complete: data?.pickup?.complete || false,
                notes: data?.pickup?.notes || null
            },
            dropoff: {
                date: {
                    date: data?.dropoff?.date?.date || null,
                    time: data?.dropoff?.date?.time || null,
                    isCustom: data?.dropoff?.date?.isCustom || false
                },
                dorm: data?.dropoff?.dorm instanceof Dorm ? data?.dropoff?.dorm : await Dorm.get(data?.dropoff?.dorm) || null,
                buildingNumber: data?.dropoff?.buildingNumber || null,
                room: data?.dropoff?.room || null,
                complete: data?.dropoff?.complete || false,
                notes: data?.dropoff?.notes || null
            },
            boxes: {
                status: data?.boxes?.status || null,
                trackingNumber: data?.boxes?.trackingNumber || null
            },
            billing: {
                depositPaid: data?.billing?.depositPaid || false,
                invoiceCreated: data?.billing?.invoiceCreated || false,
                balance: data?.billing?.balance || 0
            }
        }
        return new Order(d)
    }

    static transformToFirestore(data) {
        const copy = {
            ...data,
            location: data?.location?.ref || null,
            season: data?.season?.ref || null,
            user: data?.user?.ref || null,
            pickup: {
                ...data.pickup,
                dorm: (data?.pickup?.dorm?.ref ? data?.pickup?.dorm?.ref : Dorm.toFirestore(data?.pickup?.dorm)) || null
            },
            dropoff: {
                ...data.dropoff,
                dorm: (data?.dropoff?.dorm?.ref ? data?.dropoff?.dorm?.ref : Dorm.toFirestore(data?.dropoff?.dorm)) || null
            },
            contact: data.contact?.map((c) => Contact?.toObject(c))
        }
        if (copy?.contact?.[0]?.type === 'Parent') {
            copy.contact = [copy?.contact?.[1], copy?.contact?.[0]]
        }
        return copy
    }





    static Items = (order) => <OrderItems order={order} />


    static tabs = (order, setOrder) => [
        {
            title: 'Dashboard',
            active: true,
            view: this.Dashboard(order)
        },
        {
            title: 'Contact',
            active: true,
            view: this.Contact(order, setOrder)
        },
        {
            title: 'Pickup',
            active: true,
            view: this.Pickup(order, setOrder)
        },
        {
            title: 'Dropoff',
            active: true,
            view: this.Dropoff(order, setOrder)
        },
        {
            title: 'Items',
            active: true,
            view: this.Items(order)
        }
    ]

}