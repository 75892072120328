import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabsContent, MDBTabsPane } from 'mdb-react-ui-kit'
import React, { useRef, useState } from 'react'
import SideNav from '../../components/Order/SideNav'
import AdminOrderSummaryForm from '../../components/forms/Admin-Order/AdminOrderSummaryForm'
import { useOrderContext } from '../../contexts/OrderContext'
import _ from 'lodash'
import { useAdminContext } from '../../contexts/AdminContext'

export default function Order() {
    const { order, subscriptionItems } = useOrderContext()
    const [view, setView] = useState('Summary')
    const contentRef = useRef(null)
    return (
        <div>
            <SideNav order={order} view={view} setView={setView} contentRef={contentRef} />
            <div ref={contentRef}>
                <MDBTabsContent>
                    <MDBTabsPane show={view === 'Summary'}>
                        <MDBRow className='row-cols-1 row-cols-md-3 g-4 mb-3'>
                            <MDBCol>
                                <MDBCard className='px-3'>
                                    <MDBCardHeader className='text-start ps-0'>
                                        <h5>Pickup</h5>
                                    </MDBCardHeader>
                                    <MDBListGroup light>
                                        <MDBListGroupItem className='d-flex justify-content-between align-items-center px-2'>
                                            Student Contact Info
                                            <MDBIcon fas icon="check" />
                                        </MDBListGroupItem>
                                        <MDBListGroupItem className='d-flex justify-content-between align-items-center px-2'>
                                            Parent Contact Info
                                            <MDBIcon fas icon="check" />
                                        </MDBListGroupItem>
                                    </MDBListGroup>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <MDBCard>
                            <MDBCardBody className='px-4'>
                                <AdminOrderSummaryForm />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBTabsPane>
                    <MDBTabsPane show={view === 'Items'}>
                        <OrderItemsCustomerView />
                        <Items />
                    </MDBTabsPane>
                    <MDBTabsPane show={view === 'Billing'}>
                        <Billing />
                    </MDBTabsPane>
                </MDBTabsContent>
            </div>
        </div>
    )
}

const Billing = () => {
    const { customer, subscription, invoices, subscriptionItems } = useOrderContext()
    return (
        <div className='p-3'>
            <div className=''>
                <h6 className='border-bottom'>BILLING INFORMATION</h6>
                <div className='d-flex justify-content-between' style={{ maxWidth: '30rem' }}>
                    <p>Name</p>
                    <p>{customer?.name}</p>
                </div>
                <div className='d-flex justify-content-between' style={{ maxWidth: '30rem' }}>
                    <p>Email</p>
                    <p>{customer?.email}</p>
                </div>
                <div className='d-flex justify-content-between' style={{ maxWidth: '30rem' }}>
                    <p>Status</p>
                    <p>{customer?.delinquent ? 'Delinquent' : 'Current'}</p>
                </div>
            </div>
            <div className=''>
                <h6 className='border-bottom'>PRICING</h6>
                <MDBTable align='middle' small>
                    <MDBTableHead>
                        <tr style={{ fontSize: '12px' }}>
                            <th scope='col'>
                                DESCRIPTION
                            </th>
                            <th scope='col'>
                                QTY
                            </th>
                            <th scope='col'>
                                TOTAL
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {_.orderBy([...subscriptionItems], ['price.product.name'], ['asc'])?.map((item, idx) => {
                            return (
                                <tr style={{ fontSize: '12px' }} key={`activeSubItem${idx}`}>
                                    <td>
                                        {item?.price?.product?.name} - ${(item?.price?.unit_amount / 100)?.toFixed(2)}
                                    </td>
                                    <td>
                                        {item?.quantity}
                                    </td>
                                    <td>
                                        ${(item?.price?.unit_amount / 100 * item?.quantity)?.toFixed(2)} / month
                                    </td>
                                </tr>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
            </div>
            <div className=''>
                <h6 className='border-bottom'>INVOICES</h6>
                <MDBTable align='middle' small>
                    <MDBTableHead>
                        <tr style={{ fontSize: '12px' }}>
                            <th scope='col'>
                                STATUS
                            </th>
                            <th scope='col'>
                                DATE
                            </th>
                            <th scope='col'>
                                AMOUNT
                            </th>
                            <th scope='col'>
                                INVOICE NUMBER
                            </th>
                            <th scope='col'>
                                LINK
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {_.orderBy([...invoices], ['period_end'], ['desc'])?.map((invoice, idx) => {
                            return (
                                <tr style={{ fontSize: '12px' }} key={`activeInvoiceRow${idx}`}>
                                    <td>
                                        {invoice?.status?.toUpperCase()}
                                    </td>
                                    <td>
                                        {new Date(invoice?.period_end * 1000)?.toLocaleDateString()}
                                    </td>
                                    <td>
                                        ${(invoice?.total / 100)?.toFixed(2)}
                                    </td>
                                    <td>
                                        {invoice?.number}
                                    </td>
                                    <td>
                                        <a href={subscription?.latest_invoice?.hosted_invoice_url}>View</a>
                                    </td>
                                </tr>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
            </div>

        </div>
    )
}

const Items = () => {
    const { items } = useOrderContext()
    const { products } = useAdminContext()

    return (
        <div className='p-3'>
            <div className=''>
                <h6 className='border-bottom'>ITEMS</h6>
                <MDBTable align='middle' small>
                    <MDBTableHead>
                        <tr style={{ fontSize: '12px' }}>
                            <th scope='col'>
                                PRODUCT
                            </th>
                            <th scope='col'>
                                STATUS
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {_.orderBy([...items], ['status'], ['desc'])?.map((item, idx) => {
                            return (
                                <tr style={{ fontSize: '12px' }} key={`activeItemRow${idx}`}>
                                    <td>
                                        {item?.number} - {_.find([...products], ['ref', item?.product])?.name}
                                    </td>
                                    <td>
                                        {item?.status}
                                    </td>
                                </tr>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
            </div>
        </div>
    )
}

const OrderItemsCustomerView = () => {
    const { order, items } = useOrderContext()
    return (
        <div className='p-3'>
            <div className=''>
                <h6 className='border-bottom mb-3'>ITEMS</h6>
                <MDBRow className='row-cols-1 row-cols-md-4 g-4'>
                    {[...items]?.map((item, i) => (
                        <MDBCol key={`item${i}`}>
                            <MDBCard>
                                <MDBRow className='g-0'>
                                    <MDBCol md='4' lg='12'>
                                        <div style={{
                                            height: '265px',
                                            objectFit: 'cover',
                                            overflow: 'hidden',
                                            verticalAlign: 'middle'
                                        }}>
                                            <MDBCardImage
                                                src={item?.images?.[0]}
                                                alt='...'
                                                fluid
                                                className='h-100 w-100'
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md='8' lg='12'>
                                        <MDBCardBody>
                                            <MDBCardTitle>{item?.number}</MDBCardTitle>
                                            <MDBCardText>
                                                {item?.notes}
                                            </MDBCardText>
                                            <MDBCardText>
                                                <small className='text-muted'>Last updated 3 mins ago</small>
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>
                    ))}

                </MDBRow>
            </div>
        </div>
    )
}


