import { MDBBtn, MDBListGroupItem } from 'mdb-react-ui-kit'
import React from 'react'

export default function OrderContactListCard({ contact, setEditedContact }) {


    return (
        <MDBListGroupItem className='d-flex justify-content-between align-items-center px-2' style={{ height: '210px' }}>
            <div className='d-flex align-items-center'>
                <div className='ms-3'>
                    <p className='fw-bold mb-1'>Name: {contact?.first} {contact?.last} {contact?.isDefault && '(Default)'}</p>
                    <p className='text-muted mb-0'>{contact?.type}</p>
                    <p className='text-muted mb-0'>{contact?.address?.street}</p>
                    <p className='text-muted mb-0'>{contact?.address?.street2}</p>
                    <p className='text-muted mb-0'>{contact?.address?.city} {contact?.address?.state} {contact?.address?.zip}</p>
                    <p className='text-muted mb-0'>Phone: {contact?.phone}</p>
                    <p className='text-muted mb-0'>Email: {contact?.email}</p>
                </div>
            </div>
            <MDBBtn size='lg' rounded color='link' onClick={() => setEditedContact(contact)}>
                Edit
            </MDBBtn>
            
        </MDBListGroupItem>
    )


}
