import { MDBCol, MDBFooter, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { useLocation } from 'react-router-dom';

export default function Footer() {
    const location = useLocation()
    if (location.pathname.includes('admin')) { return null }
    else {
        return (
            <MDBFooter bgColor='light' className='text-center text-muted border-top pt-3 mt-5'>
                <div className='container-fluid mt-md-3 ps-5'>
                    <MDBRow>
                        <MDBCol size='auto'>
                            <a href='/privacyPolicy' className='me-4 text-reset'>
                                Privacy Policy
                            </a>
                            <a href='/terms' className='me-4 text-reset'>
                                Terms and Conditions
                            </a>
                        </MDBCol>
                        <MDBCol>
                            <p>
                                © 2022 Copyright Valet Student Storage, LLC
                            </p>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBFooter>
        )
    }
}