import { MDBCheckbox, MDBDatepicker, MDBInput, MDBSelect, MDBTable, MDBTableBody } from "mdb-react-ui-kit";
import { useEffect, useState } from 'react';
import OrderDormSelect from "../../../../classes/firebase/Dorm/forms/OrderDormSelect";
import AddressForm from "../../../../classes/local/Address/forms/AddressForm";
import FormCard from "../../Layouts/FormCard";
import _ from 'lodash'
export default function PickupDropoff(props) {
    const { order, updateOrder, active, mode } = props
    const objectMode = mode?.toLowerCase()

    useEffect(() => setEditedOrder(order), [order])

    const [editedOrder, setEditedOrder] = useState(null)


    const reset = () => setEditedOrder(_.cloneDeep(order))

    const submit = async (e) => {
        e.preventDefault()
        return await updateOrder(e, editedOrder)
    }

    return (
        <div>
            <FormCard
                title={mode}
                formView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        {mode} Complete
                                    </td>
                                    <td>
                                        <MDBCheckbox
                                            checked={editedOrder?.[objectMode]?.complete}
                                            onChange={(e) => setEditedOrder(editedOrder?.onChange(`${objectMode}.complete`, e.target.checked))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Date
                                    </td>
                                    <td>
                                        <MDBDatepicker
                                            inputToggle
                                            inputLabel='Date'
                                            format={'mm/dd/yyyy'}
                                            value={order?.[objectMode]?.date?.date || ''}
                                            onChange={(e) => setEditedOrder(editedOrder.onChange(`${objectMode}.date.date`, e))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Time
                                    </td>
                                    <td>
                                        <MDBSelect
                                            label="Time"
                                            className='mb-3'
                                            preventFirstSelection
                                            data={['Morning (9AM-12PM)', 'Afternoon (1PM-4PM)']?.map((d, idx) => (
                                                {
                                                    text: d,
                                                    value: d,
                                                    defaultSelected: editedOrder?.[objectMode]?.date?.time === d,
                                                    name: `${objectMode}.date.time`
                                                }
                                            ))}
                                            onValueChange={(e) => setEditedOrder(editedOrder.onChange(e.name, e.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dorm
                                    </td>
                                    <td>
                                        <OrderDormSelect order={editedOrder} setOrder={setEditedOrder} mode={objectMode} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Address
                                    </td>
                                    <td>
                                        {editedOrder?.[objectMode]?.dorm?.name === 'Other' ?
                                            <AddressForm
                                                address={editedOrder?.[objectMode]?.dorm.address}
                                                setAddress={(address) => setEditedOrder(order.editedOrder(`${objectMode}.dorm.address`, address))}
                                            />
                                            :
                                            editedOrder?.[objectMode]?.dorm?.address?.toString() || ''
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Room
                                    </td>
                                    <td>
                                        <MDBInput
                                            value={editedOrder?.[objectMode]?.room || ''}
                                            onChange={(e) => setEditedOrder(editedOrder.onChange(`${objectMode}.room`, e.target.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Customer Notes
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.notes || ''}
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                displayView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        {mode} Complete
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.complete ? 'Yes' : 'No'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Date
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.date?.date || 'No Date Selected'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Time
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.date?.time || 'No Time Selected'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dorm
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.dorm?.name || 'No Dorm Selected'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Address
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.dorm?.address?.toString() || ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Room
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.room || ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Customer Notes
                                    </td>
                                    <td>
                                        {order?.[objectMode]?.notes || ''}
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                submit={submit}
                reset={reset}
                active={active}
            />
        </div>
    )
}
