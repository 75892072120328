import Model from "../Model";
import imageCompression from 'browser-image-compression';
import Price from "../Stripe/Price";
import Order from "../Order/Order";
import User from "../User/User";
import Product from "../Stripe/Product";
export default class Item extends Model {
    constructor(data) {
        super(data)
        this.product = data.product
        this.price = data.price
        this.order = data.order
        this.user = data.user
        this.images = data.images
        this.status = data.status
        this.notes = data.notes
    }

    static collectionName = 'items'
    static get imageSavePath() {
        return `items/${this?.order?.id}/${this?.item?.id}`
    }

    static async init(data) {
        const item = new Item(
            {
                product: data?.product ? (data?.product instanceof Product ? data?.product : await Product.get(data?.product, { getPrices: true }) || null) : null,
                price: data?.price ? (data?.price instanceof Price ? data?.price : await Price.get(data?.price) || null) : null,
                order: data?.order ? (data?.order instanceof Order ? data?.order : await Order.get(data?.order) || null) : null,
                user: data?.user ? (data?.user instanceof User ? data?.user : await User.get(data?.user) || null) : null,
                images: data?.images || [],
                status: data?.status || null,
                notes: data?.notes || null
            }
        )
        if (item?.product && !item?.price) {
            if (item?.product?.default_price) {
                item.price = [...item?.product?.prices]?.find(p => p?.id === item?.product?.default_price?.id)
            }
        }
        return item
    }

    static async uploadImages(data) {
        if (data?.images?.length > 0) {
            const imgs = await Promise.all(data?.images?.map(async (image, idx) => await Item.uploadImage(image, `items/${data?.order?.id}/${data?.id}`)))
            return imgs
        }
        return []
    }

    static transformToFirestore(data) {
        
        const copy = {
            ...data,
            product: data?.product?.id || null,
            price: data?.price?.id || null,
            order: data?.order?.ref || null,
            user: data?.user?.ref || null,
            images: data?.images || []
        }
        
        if (copy?.product && !copy?.price) {
            if (copy?.product?.default_price) {
                copy.price = [...copy?.product?.prices]?.find(p => p?.id === copy?.product?.default_price?.id)
            }
        }
        return copy
    }


}
