import Address from "../Address/Address"
import _ from 'lodash'
export default class Contact {
    constructor(data) {
        this.first = data.first
        this.last = data.last
        this.phone = data.phone
        this.email = data.email
        this.isDefault = data.isDefault
        this.type = data.type
        this.index = data.index
        this.address = data.address
    }


    static init(data) {
        return new Contact({
            first: data?.first,
            last: data?.last,
            phone: data?.phone,
            email: data?.email,
            isDefault: data?.isDefault,
            type: data?.type,
            index: data?.index,
            address: Address.init(data?.address)
        })
    }

    static toObject(data) {
        return {
            first: data?.first || null,
            last: data?.last || null,
            phone: data?.phone || null,
            email: data?.email || null,
            isDefault: data?.isDefault || null,
            type: data?.type || null,
            index: data?.index || null,
            address: Address?.toObject(data?.address)
        }
    }

    onChange(path, data) {
        const obj = _.set(_.cloneDeep(this), path, data)
        return obj
    }

}
