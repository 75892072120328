import { MDBContainer } from 'mdb-react-ui-kit'
import React from 'react'
import Table from '../../components/Order/Table'
import { useAdminContext } from '../../contexts/AdminContext'

export default function Orders() {
    const { orders } = useAdminContext()
    return (
        <MDBContainer fluid>
            <Table orders={orders} />
        </MDBContainer>
    )
}
