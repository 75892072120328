

import _ from 'lodash'
import { collection, getDoc, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../../firebase/firebaseConfig'
import { MDBContainer, MDBListGroup, MDBListGroupItem, MDBTypography } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'

export default function ChangeLog() {
    const [changes, setChanges] = useState([])

    useEffect(() => {
        (async () => {
            const orders = await getDocs(collection(db, 'orders')).then(snap => snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref })))
            const items = await getDocs(collection(db, 'items')).then(snap => snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref })))
            const changes = await getDocs(collection(db, 'changeLog'))

            return setChanges(await Promise.all(changes.docs.map(async (doc) => {
                const c = doc.data()
                if (c?.document) {
                    c.documentCollection = c?.document?.path
                    c.document = await getDoc(c?.document)?.then(d => ({ ...d.data(), id: d.id }))
                    if (_.get(c, 'document.order')) {
                        _.set(c, 'orderLink', `/admin/orders/${_.get(c, 'document.order.id')}`)
                    } else {
                        _.set(c, 'orderLink', `/admin/orders/${_.get(c, 'document.id')}`)
                    }
                }
                return c
            })))


        }
        )()
    }, [])
    
    return (
        <MDBContainer>
            <div className="mt-3">
                <MDBListGroup>
                    {[...changes]?.sort((a, b) => b?.time - a?.time)?.map((change, idx) => (
                        <MDBListGroupItem key={`change-${idx}`} className="mt-3 bg-white rounded-0 border">
                            <div className=''>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <MDBTypography tag="h6" className="fw-bold mb-2">
                                            {change?.documentCollection}
                                        </MDBTypography>
                                        <p className="mb-2">
                                            <Link
                                                to={_.get(change, 'orderLink')}>
                                                {change?.document?.number}
                                            </Link>
                                        </p>
                                    </div>
                                    <div>
                                        {[...change?.changes]?.map((update, j) => <p key={`change-${idx}-${j}`}>{update}</p>)}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center border-bottom">
                                    <p className="mb-0 text-muted">
                                        {change?.time?.toDate()?.toLocaleString('en-US')}
                                    </p>
                                </div>
                            </div>

                        </MDBListGroupItem>
                    ))}
                </MDBListGroup>
            </div>
        </MDBContainer>
    )
}
