import { MDBSelect } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { cleanSelectData } from '../../../../utils/formatting/formattingUtils'
import Dorm from '../Dorm'

export default function OrderDormSelect({ order, setOrder, mode }) {
    const { user } = useAuth()
    const [options, setOptions] = useState([])
    useEffect(() => {
        (async () => {
            if (user.role === 'admin') {
                return setOptions([...order?.location?.dorms])
            }
            return setOptions([...order?.location?.dorms?.filter(d=>d.active)])
        }
        )()
    }, [])

    return (
        <MDBSelect
            preventFirstSelection
            search
            label='Dorm'
            data={cleanSelectData(options && [...options].concat([Dorm.otherDorm({ location: order?.location || null })]))?.map((d, idx) => (
                {
                    text: d?.name,
                    value: d,
                    defaultSelected: order?.[mode]?.dorm?.name === 'Other' ?
                        order?.[mode]?.dorm?.name === d?.name
                        :
                        (d?.id && order?.[mode]?.dorm?.id === d?.id),
                    name: `${mode}.dorm`
                }
            ))}
            className='mb-3'
            onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
        />
    )
}
