import * as fb from "firebase/firestore";
import Model from "../Model";
import Order from "../Order/Order";

export default class User extends Model {
    constructor(data) {
        super(data)
        this.first = data.first
        this.last = data.last
        this.email = data.email
        this.phone = data.phone
        this.role = data.role
        this.stripeId = data.stripeId
    }

    static collectionName = 'users'

    static async init(data) {
        const d =
        {
            first: data?.first || null,
            last: data?.last || null,
            email: data?.email || null,
            phone: data?.phone || null,
            role: data?.role || 'customer',
            stripeId: data?.stripeId || null,
        }
        return new User(d)
    }

    async getUserOrders() {
        const q = fb.query(Order.collection, fb.where('user', '==', this.ref))
        const req = await fb.getDocs(q)
        return await Promise.all(req?.docs?.map(async (doc) => await doc.data()))
    }

}