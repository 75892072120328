import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React from "react";

export default function About() {
  return (
    <MDBRow center>
      <MDBCol xl='6' lg='7' md='8'>
        <MDBCard className="mt-3">
          <MDBCardBody>
            <MDBCardTitle className='text-center'>
              <MDBTypography tag='div' className='display-5 pb-3 mb-3 border-bottom'>
                Our Story
              </MDBTypography>
            </MDBCardTitle>

            <p>
              The story of Valet Student Storage is one that starts with two friends - Harry and Luke.
            </p>

            <p>Harry and Luke met on a Friday night in the Fall of 2018 and over the course of their first conversation, discovered their mutual interest in entrepreneurial ventures (as one does on a Friday night at W&L 🤷‍♂️). This would come to be the first in a continuing series of conversations regarding business as well as the forming of a lasting partnership between them. These ideas manifested in April 2019 when Harry brought up the idea of a storage company for students at W&L. The pitch went a bit like this -</p>

            <p>Storing your stuff sucks. You need a lot of help to do it, doing it is sweaty and stressful, and storage units are hot and far away. If we could provide a service that would get rid of the sweaty nightmare of storage, it would make life a lot easier for W&L students.</p>

            <p>Luke was sold.</p>

            <p>That spring, Valet Student Storage was born. In the years since, we’ve been refining our business model, building the Valet team, and finding ways to make storage easier and cheaper for you, our classmates.</p>

            <p>Our mission has always been to get rid of the hassle and stress of storing your stuff and we’re excited to continue our mission in the W&L community this storage season!</p>

            <p>Sincerely,</p>

            <p>Harry Shepherd ’20</p>
            <p>Luke Alli ’22</p>
            <p>The Valet Team</p>

          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>

  )
}