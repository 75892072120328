import React from 'react'

export default function FormSelect({
    label = '',
    name = '',
    value = '',
    options = [],
    onChange = (e) => { },
    disabled = false
}) {
    return (
        <>
            <label htmlFor={name}>{label}</label>
            <select
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}>
                {[...options]?.map((o, i) => (
                    <option key={`${name}-option${i}`} value={o?.value}>{o?.text}</option>
                ))}
            </select>
        </>
    )
}
