import { MDBBtn, MDBCheckbox, MDBCol, MDBInput, MDBRow, MDBSelect, MDBTextArea } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { cleanSelectData, getDateRange } from '../../../../utils/formatting/formattingUtils'
import AddressForm from '../../../local/Address/forms/AddressForm'
import OrderDormSelect from '../../Dorm/forms/OrderDormSelect'
import Order from '../Order'

export default function OrderPickupDropoffForm({ order, setOrder, submit, mode }) {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (order) {
            if (order?.season?.[mode]?.open === false || order?.[mode]?.complete) {
                setDisabled(true)
            }
        }
    }, [order])

    return (
        <form onSubmit={async (e) => await submit(e, order)}>
            {mode === 'pickup' &&
                <>
                    <MDBCheckbox
                        checked={order?.boxes?.requested||false}
                        label='Request Large Boxes'
                        onChange={(e) => setOrder(order.onChange('boxes.requested', e.target.checked))}
                        wrapperClass='mb-3'
                    />
                    {order?.boxes?.requested &&
                        <MDBInput
                            value={order?.boxes?.numberRequested || 0}
                            onChange={(e) => setOrder(order.onChange('boxes.numberRequested', parseInt(e.target.value)))}
                            type='number'
                            label='Number Requested'
                            className='mb-3'
                            min={0}
                            max={10}
                            step={1}
                        />
                    }
                </>
            }
            <OrderDormSelect order={order} setOrder={setOrder} mode={mode} />
            {order?.[mode]?.dorm?.name === 'Other' &&
                <AddressForm address={order?.[mode]?.dorm.address} setAddress={(address) => setOrder(order.onChange(`${mode}.dorm.address`, address))} />
            }
            {order?.[mode]?.dorm?.name !== 'Other' &&
                <MDBInput
                    name={`${mode}.room`}
                    value={order?.[mode]?.room || ''}
                    label="Building/Room"
                    className='mb-3'
                    type={'text'}
                    onChange={(e) => setOrder(order.onChange(e.target.name, e.target.value))}
                />
            }
            {disabled &&
                <>
                    <p className="form-text mb-1">Date and time selections are closed or currently being determined.</p>
                    <p className="form-text mb-1">You can still update your housing information.</p>
                </>
            }
            <MDBSelect
                label="Date"
                className='mb-3'
                preventFirstSelection
                disabled={disabled}
                data={cleanSelectData(getDateRange({ ...order?.season }?.[mode]?.min, { ...order?.season }?.[mode]?.max, true)?.map((d, idx) => (
                    {
                        text: d,
                        value: d,
                        defaultSelected: order?.[mode]?.date?.date === d,
                        name: `${mode}.date.date`
                    }
                )))}
                onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
            />
            <MDBSelect
                label="Time"
                className='mb-3'
                preventFirstSelection
                disabled={disabled}
                data={cleanSelectData(['Morning (9AM-12PM)', 'Afternoon (1PM-4PM)']?.map((d, idx) => (
                    {
                        text: d,
                        value: d,
                        defaultSelected: order?.[mode]?.date?.time === d,
                        name: `${mode}.date.time`
                    }
                )))}
                onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
            />


            <MDBTextArea
                name={`${order?.[mode]}.notes`}
                value={order?.[mode]?.notes || ''}
                label="Notes or Requests"
                className='mb-3'
                rows={4}
                onChange={(e) => setOrder(order.onChange(e.target.name, e.target.value))}
            />
            <MDBRow>
                <MDBCol className="d-flex justify-content-start">
                    <MDBBtn type="submit">Save</MDBBtn>
                </MDBCol>
            </MDBRow>
        </form>
    )
}

