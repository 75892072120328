import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { MDBBtn, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit'
import ModalForm from '../../../../../oldComponents/Modal/ModalForm'
import OrderContactListCard from './OrderContactListCard'
import OrderContactForm from '../../forms/OrderContactForm'
import Contact from '../../../../local/Contact/Contact'

export default function OrderContactCustomerLayout(props) {
    const { order, setOrder } = props
    const [modal, setModal] = useState(false)
    const [editedContact, setEditedContact] = useState(null)

    useEffect(() => {
        editedContact ? setModal(true) : setModal(false)
    }, [editedContact])
   

    const submit = async (e, contact) => {
        e.preventDefault()
        const copy = _.cloneDeep(order)
        const idx = typeof contact?.index === 'number' ? contact?.index : copy?.contact?.length
        if (e?.nativeEvent?.submitter?.id === 'removeButton') {
            copy.contact.splice(idx, 1)
        }
        else { copy.contact[idx] = contact }
        copy.contact = copy.contact?.map((contact, idx) => (
            contact.onChange('index', idx)
        ))
        await copy.submit(e)
        setOrder(_.cloneDeep(copy))
        return setEditedContact(null)
    }

    return (
        <div>
            <MDBListGroup light>
                <MDBListGroupItem className='d-flex justify-content-center align-items-center' style={{ height: '50px' }}>
                    <div className='d-flex align-items-center'>
                        <MDBBtn
                            size="lg"
                            rounded
                            color='link'
                            onClick={() => setEditedContact(Contact.init({ index: [...order?.contact]?.length }))}
                        >
                            Add Contact
                        </MDBBtn>
                    </div>
                </MDBListGroupItem>
                {[...order?.contact]?.map((contact, index) => (
                    <OrderContactListCard contact={contact} setEditedContact={setEditedContact} key={`order-Contact-${index}`} />
                ))}
            </MDBListGroup>
            <ModalForm visible={modal} setVisible={setEditedContact} form={<OrderContactForm initialState={editedContact} submit={submit} />} />
        </div>
    )
}
