import _ from 'lodash'

export default class Address {
    constructor(data) {
        this.street = data.street
        this.street2 = data.street2
        this.city = data.city
        this.state = data.state
        this.zip = data.zip
    }

    static init(data) {
        return new Address({
            street: data?.street || null,
            street2: data?.street2 || null,
            city: data?.city || null,
            state: data?.state || null,
            zip: data?.zip || null
        })
    }

    toString() {
        return `${this.street} ${this.street2 || ''}, ${this.city} ${this.state}, ${this.zip}`.replace(' ,', ',')
    }

    static toObject(data) {
        return {
            street: data?.street || null,
            street2: data?.street2 || null,
            city: data?.city || null,
            state: data?.state || null,
            zip: data?.zip || null
        }
    }

    onChange(path, data) {
        const obj = _.set(_.cloneDeep(this), path, data)
        return obj
    }

}
