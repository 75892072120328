import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";


export default function AdminRoute({ children }) {
    const { user, isAuthenticated } = useAuth()
    return isAuthenticated ? user?.role === 'admin' ? children : <Navigate to="/account" /> : <Navigate to="/authenticate" />
}

