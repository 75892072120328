import { DocumentSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import Model from "../../Model";
import Order from "../Order/Order";
import Price from "../Price/Price";
import Product from "../Product/Product";
import User from "../User/User";
import _ from 'lodash'
export default class Item extends Model {
    constructor(data) {
        super(data)
        this.product = data.product
        this.price = data.price
        this.order = data.order
        this.user = data.user
        this.images = data.images
        this.status = data.status
        this.notes = data.notes
        this.number = data.number
        this.customerLocked = data.customerLocked
    }

    static collectionName = 'items'

    /**
     * @param {DocumentSnapshot} snapshot
     */
    static async populateSnapshot(snapshot) {
        if (snapshot.exists()) {
            const data = {
                ...snapshot.data(),
                ref: snapshot.ref,
                id: snapshot.id
            }
            return {
                ...data,
                product: await Product.ensureReference(_.get(data, 'product')),
                price: _.get(data, 'price.custom') ? _.get(data, 'price') : await Price.ensureReference(_.get(data, 'price')),
                order: await Order.ensureReference(_.get(data, 'order')),
                user: await User.ensureReference(_.get(data, 'user')),
                images: this.getImageField(data),
                status: _.get(data, 'status') || 'With Customer',
                notes: _.get(data, 'notes') || null,
                number: _.get(data, 'number') || null,
                customerLocked: _.get(data, 'customerLocked') || _.includes(['In Storage', 'Delivered'], _.get(data, 'status'))

            }
        } else {
            return null
        }
    }

    static getImageField(item, field = 'images') {
        const data = _.get(item, field)
        if (typeof data === 'undefined') {
            if (field === 'images') return this.getImageField(item, 'image')
            return []
        }
        else if (Array.isArray(data)) return data
        else if (typeof data === 'string') return [data]
        return []
    }

    static async init(data) {
        const [product, price] = await Promise.all([
            data?.product ? Product.get(data?.product) : null,
            data?.price ? (data?.price?.custom ? data?.price : Price.get(data?.price)) : null
        ])
        const item = new Item({
            ...data,
            product,
            price,
            order: data?.order,
            user: data?.user,
            images: data?.images || [],
            status: data?.status || 'With Customer',
            notes: data?.notes || null,
            number: data?.number || null,
            customerLocked: data?.customerLocked || null
        })
        if (!item?.price && item?.product?.default_price) {
            item.price = item?.product?.default_price
        }
        item.customerLocked = ['In Storage', 'Delivered']?.includes(item?.status)

        return item
    }

    static transformToFirestore(data) {
        const copy = {
            ...data,
            product: Product.setReferenceField(data?.product),
            price: data?.price?.custom ? data?.price : Price.setReferenceField(data?.price),
            order: Order.setReferenceField(data?.order),
            user: User.setReferenceField(data?.user),
            images: data?.images || [],
            number: data?.number || null
        }

        return copy
    }


    static async uploadImages(data) {
        if (data?.images?.length > 0) {
            const imgs = await Promise.all(data?.images?.map(async (image, idx) => await Item.uploadImage(image, `items/${data?.order?.id}/${data?.id}`)))
            return imgs
        }
        return []
    }



    static async getAdminItems() {
        const items = await Item.getCollection()
        return await Promise.all(items?.map(async (item) => {
            item.product = await Product.get(item?.product)
            item.order = await Order.get(item?.order)
            item.user = await User.get(item?.user)
            return item
        }))
    }
} 