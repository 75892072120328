import Address from "../../local/Address/Address"
import Model from "../../Model"
import Location from "../Location/Location"

export default class Dorm extends Model {
    constructor(data) {
        super(data)
        this.name = data.name
        this.location = data.location
        this.active = data.active
        this.address = data.address
    }

    static collectionName = 'dorms'

    static async init(data) {
        const d =
        {
            name: data?.name || null,
            location: data?.location,
            active: data?.active || false,
            address: Address.init(data?.address)
        }
        return new Dorm(d)
    }

    static transformToFirestore(data) {
        return {
            ...data,
            name: data?.name || null,
            location: Location.setReferenceField(data?.location),
            active: data?.active || false,
            address: Address?.toObject(data?.address)
        }
    }

    static otherDorm(data) {
        return new Dorm(
            {
                name: data?.name || 'Other',
                location: data?.location || null,
                active: data?.active || true,
                address: Address.init(data?.address)
            }
        )
    }




}