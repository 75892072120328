import _ from 'lodash'
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect, MDBTextArea } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import { cleanSelectData } from "../../../utils/formatting/formattingUtils"
import Product from "../../Product/Product"
import ItemImagesCarousel from "../Views/ItemImagesCarousel"

export default function ItemEditForm({ item, setItem, submit, products }) {

    return (
        <form onSubmit={async (e) => await submit(e, item)}>
            <h5 className="mb-4">Images</h5>
            <ItemImagesCarousel item={item} setItem={setItem} />
            <hr />
            <MDBSelect
                label="Item Type"
                className='my-3'
                preventFirstSelection
                search
                data={cleanSelectData([...products]?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((d, idx) => (
                    {
                        text: d?.name,
                        value: d,
                        defaultSelected: item?.product?.id === d?.id,
                        name: `product`
                    }
                )))}
                onValueChange={(e) => setItem(item.onChange(e.name, e.value))}
            />
            {item?.product && item?.product?.pricingType === 'Tiered' && item?.product?.prices &&
                <MDBSelect
                    label="Item Size"
                    className='my-3'
                    key={`productPrices${item?.product?.id}`}
                    preventFirstSelection
                    value={item?.price || ''}
                    data={cleanSelectData([...item?.product?.prices])?.sort((a, b) => a?.amount - b?.amount > 0 ? 1 : -1)?.map((d, idx) => (

                        {
                            text: `${d?.name} ${(d?.tierMaxSize ? `(<${d?.tierMaxSize} ${item?.product?.sizeUnit})` : '')}`,
                            value: d || '',
                            defaultSelected: (item?.product?.id === d?.product?.id) && item?.price?.id === d?.id,
                            name: 'price'
                        }
                    ))}
                    onValueChange={(e) => setItem(item.onChange(e.name, e.value))}
                />
            }

            <MDBTextArea
                name={'notes'}
                value={item?.notes || ''}
                label="Notes or Requests"
                className='mb-3'
                rows={4}
                onChange={(e) => setItem(item.onChange(e.target.name, e.target.value))}
            />
            <MDBRow className="mt-4">
                <MDBCol className="d-flex justify-content-start">
                    <MDBBtn type="submit">Save</MDBBtn>
                </MDBCol>
            </MDBRow>
        </form>
    )
}
