import { MDBContainer, MDBLoadingManagement } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Order from "../../../classes/firebase/Order/Order";
import OrderContactCustomerLayout from "../../../classes/firebase/Order/views/contact/OrderContactLayout";
import OrderCustomerDashboard from "../../../classes/firebase/Order/views/dashboard/OrderCustomerDashboard";
import OrderItemsLayout from "../../../classes/firebase/Order/views/items/OrderItemsLayout";
import OrderPickupDropoffLayout from "../../../classes/firebase/Order/views/pickupDropoff/OrderPickupDropoffLayout";
import OrderNavBar from "./OrderNavBar";

export default function OrderPage() {
    const params = useParams()
    const [order, setOrder] = useState()
    const [activeTab, setActiveTab] = useState('Dashboard')
    const navigate = useNavigate()

    const tabs = [
        {
            title: 'Dashboard',
            active: true,
            view: <OrderCustomerDashboard order={order} />
        },
        {
            title: 'Contact',
            active: true,
            view: <OrderContactCustomerLayout order={order} setOrder={setOrder} />
        },
        {
            title: 'Pickup',
            active: true,
            view: <OrderPickupDropoffLayout order={order} setOrder={setOrder} mode='pickup' />
        },
        {
            title: 'Dropoff',
            active: true,
            view: <OrderPickupDropoffLayout order={order} setOrder={setOrder} mode='dropoff' />
        },
        {
            title: 'Items',
            active: true,
            view: <OrderItemsLayout order={order} setOrder={setOrder} />
        }
    ]

    useEffect(() => {
        (async () => {
            const o = await Order.get(params.number)
            if (!o) {
                return navigate('/account')
            }
            await o?.user?.getStripeCustomer()
            return setOrder(o)
        })()
    }, [])
    
    return (
        <MDBContainer className="px-0 px-sm-2">
            <OrderNavBar order={order} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="shadow-5 bg-white h-100">
                {tabs && [...tabs]?.map((tab, idx) => {
                    const isActive = activeTab === tab?.title
                    const activeStyle = {
                        display: '',
                        width: '100%'

                    }
                    const inactiveStyle = {
                        display: 'none'
                    }

                    return (
                        <div style={isActive ? activeStyle : inactiveStyle}
                            key={`view${idx}`}>
                            {!order && <MDBLoadingManagement />}
                            {order && tab?.view}
                        </div>
                    )
                })}
            </div>
        </MDBContainer>
    )
}




export const OrderPageContainer = ({ order, setOrder }) => {

    const [activeTab, setActiveTab] = useState('Dashboard')
    const tabs = Order.tabs(order, setOrder, activeTab)
    return (
        <MDBContainer className="px-0 px-sm-2">
            <OrderNavBar order={order} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="shadow-5 bg-white h-100">
                {tabs && tabs?.map((tab, idx) => {
                    const isActive = activeTab === tab?.title
                    const activeStyle = {
                        display: '',
                        width: '100%'

                    }
                    const inactiveStyle = {
                        display: 'none'
                    }

                    return (
                        <div style={isActive ? activeStyle : inactiveStyle}
                            key={`view${idx}`}>
                            {tab?.view}
                        </div>
                    )
                })}
            </div>
        </MDBContainer>
    )
}