import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { DocumentSnapshot, collection, collectionGroup, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../../../firebase/firebaseConfig'
import Item from '../Item'
import Order from '../../Order/Order'
import User from '../../User/User'
import Product from '../../Product/Product'
import DualColumnLayout from '../../../../pages/Admin/Layouts/DualColumnLayout'
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBFile, MDBIcon, MDBInput, MDBLightbox, MDBLightboxItem, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBPopconfirm, MDBPopconfirmMessage, MDBRow, MDBSelect, MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import FormCard from '../../../../pages/Admin/Layouts/FormCard'
import { Link } from 'react-router-dom'

/**
 * Description placeholder
 *
 * @export
 * @param {{ itemRef: DocumentSnapshot; close: any; }} param0
 */

export default function AdminItemDetail({ itemRef, close }) {
    const [activeTab, setActiveTab] = useState(null)
    useEffect(() => setActiveTab(itemRef ? (activeTab ? activeTab : 'Details') : null), [itemRef])
    const [item, setItem] = useState(null)

    useEffect(() => {
        if (itemRef) {
            const unsub = onSnapshot(itemRef, async snap => {
                const i = await Item.populateSnapshot(snap)
                _.set(i, 'order', await Order.getReference(_.get(i, 'order')))
                _.set(i, 'user', await User.getReference(_.get(i, 'user')))
                _.set(i, 'product', await Product.getReference(_.get(i, 'product')))
                return setItem(i)
            })
            return unsub
        }
        return setItem(null)
    }, [itemRef])

    const [selectOptions, setSelectOptions] = useState({ products: [] })
    useEffect(() => {
        const loadOptions = async () => {
            const [products, users] = await Promise.all([
                Promise.all((await getDocs(collection(db, Product.collectionName))).docs.map(async doc => Product.populateSnapshot(doc))),
                Promise.all((await getDocs(collection(db, User.collectionName))).docs.map(async doc => User.populateSnapshot(doc)))
            ])
            return setSelectOptions({ products, users })

        }
        loadOptions()
    }, [])

    const [editor, setEditor] = useState(null)

    const updateField = async (update, closeEditor = true) => {
        await updateDoc(_.get(item, 'ref'), update)
        if (closeEditor) return setEditor(null)
        return
    }
    return (
        <DualColumnLayout
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            leftCard={{
                title: (
                    <div>
                        {_.get(item, 'order.id') ?
                            <Link
                                to={`/admin/orders/${_.get(item, 'order.id')}`}>
                                {_.get(item, 'order.number')}
                            </Link>
                            : 'No Order'}
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <MDBBadge light>{String(item?.number)}</MDBBadge>
                            <MDBDropdown>
                                <MDBDropdownToggle
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '.75em',
                                        paddingTop: '.35em',
                                        paddingRight: '.65em',
                                        paddingBottom: '.35em',
                                        paddingLeft: '.65em',
                                    }}
                                    size='sm'
                                    color={item?.status === 'Delivered' ? 'success' : item?.status === 'In Storage' ? 'light' : 'danger'} light>
                                    <span style={{ fontSize: '.75em' }}>{item?.status}</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={async (e) => await updateField({ status: 'In Storage' })}>In Storage</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    </div>
                ),
                body: (
                    <div>
                        <MDBListGroup light>
                            <MDBListGroupItem className='pt-0'>
                                <div className='d-flex justify-content-between'>
                                    {editor === 'Product' ?
                                        <MDBSelect
                                            className={'w-100 me-4'}
                                            preventFirstSelection
                                            search
                                            size={'sm'}
                                            data={_.map(_.get(selectOptions, 'products'), o => ({ text: _.get(o, 'name'), value: o, name: 'item.product.id', defaultSelected: _.get(item, 'product.id') === _.get(o, 'ref.id') }))}
                                            onValueChange={async (e) => await updateField({ product: _.get(e, 'value.ref') })}

                                        />
                                        :
                                        <div className='d-flex justify-content-start'>
                                            {item?.product?.images?.[0] &&
                                                <img src={item?.product?.images?.[0]}
                                                    style={{ width: '55px', height: '55px' }}
                                                />}
                                            <div className='ms-2'>
                                                <p className='mb-0'>{item?.product?.metadata?.category}</p>
                                                <p className='text-muted mb-0 fw-light'>{_.toUpper(item?.product?.name || item?.notes)}</p>
                                            </div>
                                        </div>
                                    }
                                    <div className='d-flex justify-content-end'>
                                        <MDBBtn color='none' tag='a' className=''
                                            onClick={(e) => editor === 'Product' ? setEditor(null) : setEditor('Product')}>
                                            <MDBIcon fas icon={editor === 'Product' ? 'times' : "edit"} size={'sm'} />
                                        </MDBBtn>
                                    </div>
                                </div>

                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <div className='d-flex justify-content-between'>
                                    {editor === 'User' ?
                                        <MDBSelect
                                            className={'w-100 me-4'}
                                            preventFirstSelection
                                            search
                                            size={'sm'}
                                            data={_.map(_.get(selectOptions, 'users'), o => ({ text: `${_.get(o, 'first')} ${_.get(o, 'last')}`, value: o, name: 'item.user.id', defaultSelected: _.get(item, 'user.id') === _.get(o, 'ref.id') }))}
                                            onValueChange={async (e) => await updateField({ user: _.get(e, 'value.ref') })}

                                        />
                                        :
                                        <p className='text-muted mb-0 fw-light'>CUSTOMER</p>
                                    }
                                    <div className='d-flex justify-content-end'>
                                        <MDBBtn color='none' tag='a' className=''
                                            onClick={(e) => editor === 'User' ? setEditor(null) : setEditor('User')}>
                                            <MDBIcon fas icon={editor === 'User' ? 'times' : "edit"} size={'sm'} />
                                        </MDBBtn>
                                    </div>
                                </div>
                                {editor !== 'User' &&
                                    <div>
                                        {_.get(item, 'user') ?
                                            <>
                                                <p className='mb-0'>
                                                    {_.get(item, 'user.first')} {_.get(item, 'user.last')}
                                                </p>
                                                <p className='mb-0'>
                                                    {_.get(item, 'user.phone')}
                                                </p>
                                                <p className='mb-0'>
                                                    {_.get(item, 'user.email')}
                                                </p>
                                            </>
                                            : <p className='mb-0'>
                                                No User
                                            </p>}
                                    </div>
                                }
                            </MDBListGroupItem>

                            <MDBListGroupItem className=''>
                                <MDBLightbox className='w-100'>
                                    {_.map(_.get(item, 'images'), (image, idx) => (
                                        <div key={`itemImages${idx}`} className="border w-100 mb-2">
                                            <div className="p-2 d-flex justify-content-center">
                                                <MDBLightboxItem
                                                    src={image}
                                                    fullscreenSrc={image}
                                                    style={{ height: '175px', width: '175px', overflow: 'cover' }}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-around mb-2">
                                                <ImageSelectButton
                                                    id={`changeImageButton${_.get(item, 'id')}-${image}`}
                                                    button={{ className: 'text-center rounded-0', text: 'Edit', color: 'secondary' }}
                                                    callback={async (file) => {
                                                        const img = await Item.uploadImage(file, `items/${_.get(item, 'order.id')}/${_.get(item, 'id')}`)
                                                        const imgsArr = [..._.get(item, 'images')]
                                                        _.set(imgsArr, idx, img)
                                                        return await updateField({ images: imgsArr })
                                                    }}
                                                />
                                                <MDBPopconfirm
                                                    modal
                                                    btnChildren='Remove'
                                                    btnClassName='text-center rounded-0 btn-danger'
                                                    cancelBtnClasses='rounded-0'
                                                    confirmBtnClasses='rounded-0 btn-danger'
                                                    onConfirm={async (e) => {
                                                        e.preventDefault()
                                                        const removed = _.without(_.get(item, 'images'), image)
                                                        return await updateField({ images: removed })
                                                    }}
                                                >
                                                    <MDBPopconfirmMessage>Confirm Image Delete?</MDBPopconfirmMessage>
                                                </MDBPopconfirm>
                                            </div>
                                        </div>
                                    ))}
                                </MDBLightbox>
                                <div className={`d-flex justify-content-center`}>
                                    <ImageSelectButton
                                        id={`addImageButton${_.get(item, 'id')}`}
                                        button={{ className: 'text-center rounded-0 border', text: 'Add Image', color: 'link' }}
                                        callback={async (file) => {
                                            const img = await Item.uploadImage(file, `items/${_.get(item, 'order.id')}/${_.get(item, 'id')}`)
                                            const imgsArr = [..._.get(item, 'images')]
                                            imgsArr.push(img)
                                            return await updateField({ images: imgsArr })
                                        }}
                                    />
                                </div>
                            </MDBListGroupItem>
                        </MDBListGroup>


                    </div>
                )
            }}
            tabs={
                [
                    {
                        title: 'Details',
                        component: <Details
                            item={item}
                        />
                    }
                ]}

        />
    )
}

const Details = ({ item }) => {
    const [logs, setLogs] = useState([])
    useEffect(() => {
        const load = async () => {
            if (!_.get(item, 'id')) return setLogs([])
            const logs = await getDocs(query(collection(db, 'changeLog'), where('document', '==', _.get(item, 'ref'))))
            const data = _.map(logs.docs, l => ({ ...l.data(), id: _.get(l, 'id'), ref: _.get(l, 'ref') }))
            return setLogs(data)
        }
        load()
    }, [_.get(item, 'id')])
    return (
        <div>
            <MDBCard className="rounded-0 mb-2 mb-lg-4">
                <MDBCardHeader><MDBCardTitle className="mb-0">Item History</MDBCardTitle></MDBCardHeader>
                <MDBCardBody>
                    <MDBListGroup>
                        {[...logs]?.sort((a, b) => b?.time - a?.time)?.map((log, idx) => (
                            <MDBListGroupItem key={`log-${idx}`} className="mt-3 bg-white rounded-0 border">
                                <div className=''>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            {[...log?.changes]?.map((update, j) => <p key={`change-${idx}-${j}`}>{update}</p>)}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0 text-muted">
                                            {log?.time?.toDate()?.toLocaleString('en-US')}
                                        </p>
                                    </div>
                                </div>

                            </MDBListGroupItem>
                        ))}
                    </MDBListGroup>
                </MDBCardBody>
            </MDBCard>
        </div>
    )
}

const ImageSelectButton = ({ id, button, callback }) => {
    const [preview, setPreview] = useState(null)
    const [modal, setModal] = useState(false)
    useEffect(() => preview ? setModal(true) : setModal(false))
    useEffect(() => setPreview(null), [id])
    return (
        <div>
            <div>
                <MDBFile accept="image/*"
                    style={{ display: 'none' }}
                    id={id}

                    onChange={(e) => {
                        const f = e.target.files[0]
                        if (f) {
                            setPreview({
                                file: f,
                                img: URL.createObjectURL(f)
                            })
                        }
                        e.target.value = null
                    }}
                />
                <label htmlFor={id}>
                    <MDBBtn tag='a' className={_.get(button, 'className')} color={_.get(button, 'color')}>
                        {_.get(button, 'text')}
                    </MDBBtn>
                </label>
            </div>
            <MDBModal staticBackdrop show={modal} onClose={() => setPreview(null)} tabIndex='-1'>
                <MDBModalDialog >
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Preview Image</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => setPreview(null)}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className='d-flex justify-content-center'>
                            <img
                                style={{ maxWidth: '300px' }}
                                className={`w-100 mx-auto`}
                                src={_.get(preview, 'img')}
                                alt='...'
                            />
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' className='rounded-0' onClick={() => setPreview(null)}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn className='rounded-0'
                                onClick={async (e) => {
                                    e.preventDefault()
                                    await callback(_.get(preview, 'file'))
                                    return setPreview(null)
                                }}>Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}
