import { MDBBtn, MDBCol, MDBRow, MDBSelect, MDBTextArea } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import ItemImagesCarousel from "../views/ItemImagesCarousel"
import { cleanSelectData } from "../../../../utils/formatting/formattingUtils"
import Product from "../../Product/Product"

export default function CustomerItemEditForm({ item, setItem, submit, remove }) {
    const [products, setProducts] = useState([])
    useEffect(() => {
        (async () => {
            return setProducts(await Product.getActiveProducts())
        })()

    }, [])
    if (products?.length) {
        return (
            <form onSubmit={async (e) => await submit(e, item)}>
                <h5 className="mb-4">Images</h5>
                <ItemImagesCarousel item={item} setItem={setItem} />
                <hr />
                <MDBSelect
                    label="Item Type"
                    className='my-3'
                    preventFirstSelection
                    search
                    disabled={item?.customerLocked}
                    data={cleanSelectData([...products]?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((d, idx) => (
                        {
                            text: d?.name,
                            value: d,
                            defaultSelected: item?.product?.id === d?.id,
                            name: `product`
                        }
                    )))}
                    onValueChange={(e) => setItem(item.onChange(e.name, e.value))}
                />


                <MDBTextArea
                    name={'notes'}
                    value={item?.notes || ''}
                    label="Notes or Requests"
                    className='mb-3'
                    rows={4}
                    disabled={item?.customerLocked}
                    onChange={(e) => setItem(item.onChange(e.target.name, e.target.value))}
                />
                <MDBRow className="mt-4">
                    <MDBCol className="d-flex justify-content-start">
                        <MDBBtn type="submit" disabled={item?.customerLocked}>Save</MDBBtn>
                    </MDBCol>
                    {!item?.customerLocked && <MDBCol className="d-flex justify-content-start">
                        <MDBBtn color="danger" onClick={async (e) => await remove(e, item)}>Delete</MDBBtn>
                    </MDBCol>}
                </MDBRow>
            </form>
        )
    }
}
