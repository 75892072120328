import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { objectsEqual } from '../../../../utils/formatting/formattingUtils'
import { handleGenericChange, handleGenericSubmit } from '../../../../wrappers/FormWrappers'
import { useNavigate } from 'react-router-dom'

export const AccountSettingsPane = ({ user }) => {

  return (
    <MDBRow className='mb-5'>
      {user && <AccountEditContactForm user={user} />}
      {user && <AccountChangePasswordForm user={user} />}
      {user && <AccountBillingPortalCard user={user} />}
    </MDBRow>
  )
}



const AccountEditContactForm = ({ user }) => {
  const [userForm, setUserForm] = useState(null)

  const [formEdited, setFormEdited] = useState(false)

  useEffect(() => {
    setFormEdited(!objectsEqual((({ first, last, email, phone, id }) => ({ first, last, email, phone, id }))(user), userForm))
  }, [userForm])

  const resetUser = () => {
    return setUserForm((({ first, last, email, phone, id }) => ({ first, last, email, phone, id }))(user))
  }

  useEffect(() => {
    resetUser()
  }, [user])

  const handleChange = (e) => handleGenericChange(e, userForm, setUserForm)

  return (
    <MDBCard className='mb-3'>
      <MDBCardBody>
        <MDBRow>
          <MDBCol md='4' className='my-2 d-flex align-items-center'>
            <h5>Account</h5>
          </MDBCol>
          <MDBCol md='6' className='my-2'>
            <p>{user?.first} {user?.last}</p>
            <p>{user?.email}</p>
            <p>{user?.phone}</p>
            {userForm && false &&
              <form onReset={() => resetUser()} onSubmit={async (e) => await handleGenericSubmit(e, userForm, 'users')}>
                <MDBInput
                  name='first'
                  label='First Name'
                  type='text'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.first || ''}
                  onChange={(e) => handleChange(e)}
                />
                <MDBInput
                  name='last'
                  label='Last Name'
                  type='text'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.last || ''}
                  onChange={(e) => handleChange(e)}
                />
                <MDBInput
                  name='email'
                  label='Email'
                  type='text'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.email || ''}
                  onChange={(e) => handleChange(e)}
                />
                <MDBInput
                  name='phone'
                  label='Phone'
                  type='tel'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.phone || ''}
                  onChange={(e) => handleChange(e)}
                />
                {formEdited &&
                  <>
                    <MDBRow className='px-2'>
                      <MDBBtn type='submit' className='mb-3'>Save</MDBBtn>
                    </MDBRow>
                    <MDBRow className='px-2'>
                      <MDBBtn type='reset' color='light' className='border mb-3'>Cancel</MDBBtn>
                    </MDBRow>
                  </>
                }
              </form>}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

const AccountChangePasswordForm = () => {
  const { reauthenticate, changePassword } = useAuth()
  const [userForm, setUserForm] = useState(null)

  const [editing, setEditing] = useState(false)
  const [alert, setAlert] = useState('')

  const resetUserForm = () => {
    return setUserForm({
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    })
  }

  useEffect(() => {
    resetUserForm()
    setAlert('')
  }, [editing])

  const handleChange = (e) => handleGenericChange(e, userForm, setUserForm)
  const handleSubmit = async (e) => {
    e.preventDefault()
    let form = { ...userForm }
    if (form?.newPassword !== form?.newPasswordConfirm || !form?.newPassword) {
      return setAlert('Passwords must match.')
    }

    try {
      await reauthenticate(form?.oldPassword)
      await changePassword(form.newPassword)
      setEditing(false)
    }
    catch (error) {
      setAlert(error)
    }
  }

  return (
    <MDBCard className='mb-3'>
      <MDBCardBody>
        <MDBRow>
          <MDBCol md='4' className='my-2 d-flex align-items-center'>
            <h5>Password</h5>
          </MDBCol>
          <MDBCol md='6' className='my-2'>
            {!editing &&
              <MDBRow className='px-2 d-flex align-items-center'>
                <MDBBtn color='light' className='border' onClick={() => setEditing(true)}>Update Password</MDBBtn>
              </MDBRow>
            }

            <MDBAlert color='danger' show={alert}>{alert}</MDBAlert>
            {editing && userForm &&

              <form onReset={() => setEditing(false)} onSubmit={async (e) => await handleSubmit(e)}>

                <MDBInput
                  name='oldPassword'
                  label='Current Password'
                  type='password'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.oldPassword || ''}
                  onChange={(e) => handleChange(e)}
                />
                <MDBInput
                  name='newPassword'
                  label='New Password'
                  type='password'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.newPassword || ''}
                  onChange={(e) => handleChange(e)}
                />
                <MDBInput
                  name='newPasswordConfirm'
                  label='Confirm New Password'
                  type='password'
                  className='w-100'
                  wrapperClass='mb-3'
                  value={userForm?.newPasswordConfirm || ''}
                  onChange={(e) => handleChange(e)}
                />
                {editing &&
                  <>
                    <MDBRow className='px-2'>
                      <MDBBtn type='submit' className='mb-3'>Save</MDBBtn>
                    </MDBRow>
                    <MDBRow className='px-2'>
                      <MDBBtn type='reset' color='light' className='border mb-3'>Cancel</MDBBtn>
                    </MDBRow>
                  </>
                }
              </form>}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

const AccountBillingPortalCard = ({ user }) => {
  const [portalUrl, setPortalUrl] = useState(null)

  useEffect(() => {
    (async () => {
      const newPortalUrl = await user.createCustomerPortalSession('https://valetstudentstorage.com/account')
      return setPortalUrl(newPortalUrl)
    })()
  }, [user])


  return (
    <MDBCard className='mb-3'>
      <MDBCardBody>
        <MDBRow>
          <MDBCol md='4' className='my-2 d-flex align-items-center'>
            <h5>Billing</h5>
          </MDBCol>
          <MDBCol md='6' className='my-2'>
            <MDBRow className='px-2 d-flex align-items-center'>
              <MDBBtn color='light' className='border' tag='a' href={portalUrl} disabled={!portalUrl}>Update Billing Info</MDBBtn>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>

  )
}