

import { MDBSelect } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { cleanSelectData } from '../../../../utils/formatting/formattingUtils'
import Location from '../Location'

export default function OrderLocationSelect({ order, setOrder, locations = null }) {
    const { user } = useAuth()
    const [options, setOptions] = useState([])
    useEffect(() => {
        (async () => {
            if (Array.isArray(locations)) {
                return setOptions(locations)
            }
            if (user.role === 'admin') {
                return setOptions(await Location.getCollection())
            }
            return setOptions(await Location.query('active', true))

        })()

    }, [])

    return (
        <MDBSelect
            preventFirstSelection
            label='Campus'
            search
            data={cleanSelectData(options && [...options]
                ?.sort((a, b) => `${a.name}`?.localeCompare(`${b.name}`))
                ?.map((i) => (
                    { text: `${i.name || ''}`, value: i, defaultSelected: order?.location?.id === i?.id }
                )))}
            onValueChange={(e) => setOrder(order.onChange('location', e.value))}
        />
    )
}
