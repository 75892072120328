import { httpsCallable } from 'firebase/functions';
import { MDBContainer, MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBInput } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import Product from '../../../classes/firebase/Product/Product';
import { functions } from '../../../firebase/firebaseConfig';
import _ from 'lodash'
export default function Pricing() {
    const [products, setProducts] = useState([])
    const [initialProducts, setInitialProducts] = useState(null)

    useEffect(() => {
        (async () => {
            const products = await Product.getActiveProducts()
            setInitialProducts(products)

        })()

    }, [])

    useEffect(() => {
        if (initialProducts) {
            setProducts(initialProducts)
        }
    }, [initialProducts])

    const update = async (e) => {
        e.preventDefault()
        const updater = httpsCallable(functions, 'firestore-product-update')
        for await (const product of [...products]) {
            const original = [...initialProducts]?.find(initialProduct => product?.id === initialProduct?.id)
            if (original?.metadata?.sortOrder !== product?.metadata?.sortOrder) {
                await updater({
                    id: product.id, metadata: {
                        ...product.metadata,
                        sortOrder: product.metadata.sortOrder
                    }
                })
            }



        }
    }

    return (
        <MDBContainer className="mt-3">
            <MDBBtn
                onClick={async (e) => await update(e)}
            >
                Save Ordering
            </MDBBtn>
            <MDBTable align='middle'>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Category</th>
                        <th scope='col'>Sort Order</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {products && [...products]?.sort((a, b) => a?.metadata?.sortOrder - b?.metadata?.sortOrder > 0 ? 1 : -1)
                        ?.map((product, idx) => (
                            <tr key={`product-Row-${idx}`}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <img
                                            src={(product?.images?.[0] ? product?.images[0] : Product.defaultProductImage)}
                                            alt=''
                                            style={{ width: '65px', height: '65px' }}
                                        />
                                        <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{product?.name}</p>
                                            <p className='text-muted mb-0'>{product?.default_price?.amount}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{product?.metadata?.category}</p>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <MDBInput
                                            type='number'
                                            min={0}
                                            max={products?.length}
                                            value={product?.metadata?.sortOrder || ''}
                                            onChange={(e) => setProducts(products => {
                                                const oldIndex = parseInt(idx)
                                                const newIndex = parseInt(e.target.value)
                                                const newProduct = product.onChange('metadata.sortOrder', newIndex)
                                                const copy = _.cloneDeep(products)?.sort((a, b) => a?.metadata?.sortOrder - b?.metadata?.sortOrder > 0 ? 1 : -1)
                                                copy.splice(oldIndex, 1)
                                                copy.splice(newIndex, 0, newProduct)
                                                return copy?.map((p, idx) => {
                                                    if (idx !== p?.metadata?.sortOrder) {
                                                        return p.onChange('metadata.sortOrder', idx)
                                                    }
                                                    return p
                                                })
                                            })}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}

                </MDBTableBody>
            </MDBTable>
        </MDBContainer>
    )
}



