import { MDBBtn, MDBCheckbox, MDBCol, MDBDatepicker, MDBInput, MDBPopconfirm, MDBPopconfirmMessage, MDBRow, MDBSwitch, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React from 'react'
import _ from 'lodash'
import AddressForm from '../../../local/Address/forms/AddressForm'
export default function AdminDormEditForm(props) {
  const { dorm, setDorm, save, cancel, remove } = props
  return (
    <div>
      <MDBTable align="middle" className='bg-white border'>
        <MDBTableBody>
          <tr className='bg-light'>
            <th scope='row'>
            </th>
            <th className='d-flex justify-content-end'>
              <MDBBtn size='sm' color='link' onClick={async (e) => await save(e, dorm)}>
                Save
              </MDBBtn>
              <MDBBtn size='sm' color='link' onClick={cancel}>
                Cancel
              </MDBBtn>
              {false && dorm?.id && <MDBPopconfirm
                btnChildren='Delete'
                btnClassName='btn-link btn-sm bg-light text-danger border'
                placement='bottom'
                confirmBtnText='Delete'
                confirmBtnClasses='btn-danger'
                cancelBtnText='Cancel'
                onConfirm={async (e) => await remove(e, dorm)}>
                <MDBPopconfirmMessage>Are you sure?</MDBPopconfirmMessage>
              </MDBPopconfirm>}
            </th>
          </tr>
          <tr>
            <td>
              Name
            </td>
            <td>
              <MDBInput
                value={dorm?.name || ''}
                name='name'
                onChange={(e) => setDorm(dorm.onChange(e.target.name, e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>
              Active
            </td>
            <td>
              <MDBCheckbox
                checked={dorm?.active || false}
                name='active'
                onChange={(e) => setDorm(dorm.onChange(e.target.name, e.target.checked))}
              />
            </td>
          </tr>
          <tr>
            <td>
              Address
            </td>
            <td>
              <AddressForm
                address={dorm?.address}
                setAddress={(address) => setDorm(dorm.onChange('address', address))}
              />
            </td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    </div>
  )
}