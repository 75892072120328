import { MDBBtn, MDBListGroupItem } from 'mdb-react-ui-kit'
import React from 'react'

export default function OrderPickupDropoffCard({ order, mode, setEditedObject }) {
    const data = order[mode]
    return (
        <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
                <div className='ms-3'>
                    {mode === 'pickup' &&
                        <p className='fw-bold mb-1'>{order?.boxes?.requested ?
                            <>
                                {order?.boxes?.numberRequested} Boxes Requested {order?.boxes?.delivered && '(Delivered)'}
                            </> : 'No Boxes Requested'}</p>
                    }
                    <p className='fw-bold mb-1'>Location: {data?.dorm?.name}</p>
                    {data?.dorm?.name !== 'Other' && <p className='text-muted mb-0'>Room {data?.room}</p>}
                    <p className='text-muted mb-0'>{data?.dorm?.address?.street}</p>
                    <p className='text-muted mb-0'>{data?.dorm?.address?.city} {data?.dorm?.address?.state} {data?.dorm?.address?.zip}</p>

                    <p className='fw-bold mt-3 mb-1'>Date: {data?.date?.date}</p>
                    <p className='text-muted mb-1'>Time: {data?.date?.time}</p>

                    <p className='fw-bold mb-1'>Notes</p>
                    <p className='text-muted mb-1'>{data?.notes || ''}</p>

                </div>
            </div>
            <MDBBtn size='lg' rounded color='link' onClick={() => setEditedObject(order)} disabled={order?.[mode]?.complete}>
                Edit
            </MDBBtn>
        </MDBListGroupItem>
    )
}