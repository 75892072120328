import _ from 'lodash'
import { MDBListGroup } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import ModalForm from '../../../../../oldComponents/Modal/ModalForm'
import OrderPickupDropoffForm from '../../forms/OrderPickupDropoffForm'
import OrderPickupDropoffCard from './OrderPickupDropoffCard'

export default function OrderPickupDropoffLayout({ order, setOrder, mode }) {
	const [modal, setModal] = useState(false)
	const [editedObject, setEditedObject] = useState(null)

	useEffect(() => {

		editedObject ? setModal(true) : setModal(false)
	}, [editedObject])

	const submit = async (e, object) => {
		e.preventDefault()
		const copy = _.cloneDeep(order)
		copy[mode] = object[mode]
		if (mode === 'pickup') {
			if (!_.isEqual(_.get(copy, 'boxes'), _.get(object, 'boxes'))) _.set(copy, 'boxes', _.get(object, 'boxes'))
		}
		_.get(object, 'boxes')
		_.get(copy, 'boxes')
		await copy.submit(e)
		setOrder(copy)
		return setEditedObject(null)
	}

	return (
		<div className="px-2 d-flex align-items-center" style={{ minHeight: '50vh' }}>
			<MDBListGroup light className="w-100">
				<OrderPickupDropoffCard order={order} mode={mode} setEditedObject={setEditedObject} />
			</MDBListGroup>
			<ModalForm visible={modal} setVisible={setEditedObject} form={<OrderPickupDropoffForm order={editedObject} setOrder={setEditedObject} submit={submit} mode={mode} />} />
		</div>
	)
}