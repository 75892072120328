import { createUserWithEmailAndPassword, EmailAuthProvider, onAuthStateChanged, reauthenticateWithCredential, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth, db } from "../firebase/firebaseConfig"
import { handleFirebaseError } from "../utils/requests/firebaseErrorCodes"
const AuthContext = React.createContext({
  isAuthenticated: false,
  currentUser: null,
  user: null
})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(() => {
    const token = JSON.parse(localStorage.getItem('currentUser'))
    return token
  })
  const [user, setUser] = useState(() => {
    const token = JSON.parse(localStorage.getItem('user'))
    return token
  })

  const [loading, setLoading] = useState(true)

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem('AuthToken')
    return token !== null
  })
  const navigate = useNavigate()

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  async function logout() {
    navigate('/')
    return await signOut(auth)
  }

  async function reauthenticate(password) {
    const credential = EmailAuthProvider.credential(currentUser?.email, password)
    return new Promise((resolve, reject) => {
      reauthenticateWithCredential(currentUser, credential).then(() => {
        resolve('Success')
      }).catch(error => {
        reject(handleFirebaseError(error))
      })
    })
  }

  function resetPassword(email) {
    const actionCodeSettings = {
      url: 'https://valetstudentstorage.com/account'
    }
    return sendPasswordResetEmail(auth, email, actionCodeSettings)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  async function changePassword(password) {
    return new Promise((resolve, reject) => {
      updatePassword(currentUser, password).then(() => {
        resolve('Success')
      }).catch(error => {
        reject(handleFirebaseError(error))
      })
    })
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (data) => {
      setLoading(true)
      setCurrentUser(data)
      if (data) {
        localStorage.setItem('AuthToken', data.accessToken)
        localStorage.setItem('currentUser', JSON.stringify(data))
        setIsAuthenticated(true)
      }
      else {
        setUser(null)
        setIsAuthenticated(false)
        localStorage.removeItem('AuthToken')
        localStorage.removeItem('currentUser')
        localStorage.removeItem('user')
        setLoading(false)

      }
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(doc(db, 'users', currentUser.uid || ''), (doc) => {
        const uData = doc.exists() ? doc.data() : null
        if (uData && !uData?.id) { uData.id = currentUser.uid }
        setUser(uData)
        localStorage.setItem('user', doc.exists() ? JSON.stringify(doc.data()) : null)
        setLoading(false)
      })
      return () => {
        unsub()
      }
    }

  }, [isAuthenticated, currentUser])


  const value = {
    isAuthenticated,
    currentUser,
    user,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    changePassword,
    reauthenticate,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
