import imageCompression from 'browser-image-compression';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from '../../firebase/firebaseConfig';

export default class image {
    static async compressImage(image) {
        const options = {
            maxSizeMB: 0.2,
        }
        try {
            return await imageCompression(image, options)
        }
        catch {
            return image
        }
    }
    static async uploadImage(image, savePath = null) {
        const basePath = savePath || `${this.collectionName}/${this.id}`
        const relativePath = `/${image?.lastModified}-${image?.name}`
        const fullPath = `${basePath}${relativePath}`?.replace('//', '/')

        if (typeof image === 'object') {
            const compressed = await this.compressImage(image)
            const storageRef = ref(storage, fullPath)
            const upload = await uploadBytes(storageRef, compressed)
            const url = await getDownloadURL(upload.ref)

            return url
        }
        else {
            return image
        }
    }
}