// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEACzUwx0iSMjZSlB-_yoQyBZE7ehTVF8",
  authDomain: "valet-student-storage-v2.firebaseapp.com",
  projectId: "valet-student-storage-v2",
  storageBucket: "valet-student-storage-v2.appspot.com",
  messagingSenderId: "1030958277654",
  appId: "1:1030958277654:web:3b31d8eaf11711ae2fc137",
  measurementId: "G-0D058WKGPD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
//connectFunctionsEmulator(functions, 'localhost', 5001)
//connectFirestoreEmulator(db, 'localhost', 8080)
export { app, analytics, auth, db, storage, functions }