import Model from "../../Model"
import User from "../User/User"

export default class Season extends Model {
    constructor(data) {
        super(data)

        this.name = data.name
        this.location = data.location
        this.active = data.active
        this.registrationOpen = data.registrationOpen
        this.months = data.months
        this.year = data.year
        this.pickup = data.pickup
        this.dropoff = data.dropoff
        this.billingStartDate = data.billingStartDate
        this.workers = data.workers
    }

    static collectionName = 'seasons'

    static populate(data) {
        return new Season(
            {
                name: data?.name || null,
                location: data?.location || null,
                active: data?.active || false,
                registrationOpen: data?.registrationOpen || false,
                months: data?.months || null,
                year: data?.year || null,
                pickup: {
                    open: data?.pickup?.open || false,
                    min: data?.pickup?.min || null,
                    max: data?.pickup?.max || null,
                    list: data?.pickup?.list || []
                },
                dropoff: {
                    open: data?.dropoff?.open || false,
                    min: data?.dropoff?.min || null,
                    max: data?.dropoff?.max || null,
                    list: data?.dropoff?.list || []
                }
            }
        )
    }

    static async init(data) {
        const d =
        {
            name: data?.name || null,
            location: data?.location || null,
            active: data?.active || false,
            registrationOpen: data?.registrationOpen || false,
            months: data?.months || null,
            year: data?.year || null,
            pickup: {
                open: data?.pickup?.open || false,
                min: data?.pickup?.min || null,
                max: data?.pickup?.max || null,
                list: data?.pickup?.list || []
            },
            dropoff: {
                open: data?.dropoff?.open || false,
                min: data?.dropoff?.min || null,
                max: data?.dropoff?.max || null,
                list: data?.dropoff?.list || []
            },
            billingStartDate: data?.billingStartDate || null,
            workers: Array.isArray(data?.workers) ? data?.workers?.map(async (worker) => await User.init(worker)) : []
        }
        return new Season(d)
    }

    static transformToFirestore(data) {
        const d = {
            ...data,
            location: data?.location instanceof Location ? Location.setReferenceField(data?.location) : data?.location,
            workers: data?.workers?.length ? data?.workers?.map(worker => User.setReferenceField(worker)) : []
        }
        return d
    }


}