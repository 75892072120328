import Price from "./Price";
import Stripe from "./Stripe";

export default class Product extends Stripe {
    constructor(data) {
        super()
        this.active = data.active
        this.attributes = data.attributes
        this.created = data.created
        this.default_price = data.default_price
        this.description = data.description
        this.id = data.id
        this.images = data.images
        this.livemode = data.livemode
        this.metadata = data.metadata
        this.name = data.name
        this.object = data.object
        this.package_dimensions = data.package_dimensions
        this.prices = data.prices
        this.shippable = data.shippable
        this.statement_descriptor = data.statement_descriptor
        this.tax_code = data.tax_code
        this.type = data.type
        this.unit_label = data.unit_label
        this.updated = data.updated
        this.url = data.url
    }

    static async init(data, { getPrices = true }) {
        const product = new Product(data)
        if (!product.prices && getPrices) {
            product.prices = await product.getPrices()
            product.default_price = [...product.prices]?.find(p => p?.id === product.default_price)
        }
        return product
    }

    static object = 'product'

    async getPrices() {
        const query = `active:\"true\" AND product:\"${this.id}\"`
        const prices = await Price.search(query)
        return prices
    }

    static async getAll() {
        return await Product.list()
    }
}