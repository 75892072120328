import { collection, getDocs, query, where } from "firebase/firestore";
import { MDBAccordion, MDBAccordionItem, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";

export default function FAQ() {
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    const faqRef = collection(db, "faqs")
    const q = query(faqRef, where("active", "==", true))
    getDocs(q).then((data) => {
      setFaqs(data.docs.map((doc) => doc.data()))
    })


  }, [])

  return (
    <MDBRow center className='my-3'>
      <MDBCol md='10' lg='9' xl='8'>
        <MDBCard className="mt-3">
          <MDBCardBody>
            <MDBCardTitle className='text-center'>
              <MDBTypography tag='div' className='display-5 pb-3 mb-3 border-bottom'>
                FAQs
              </MDBTypography>
            </MDBCardTitle>
            <MDBAccordion flush  >
              {faqs && faqs.map((faq, idx) => (
                <MDBAccordionItem collapseId={idx + 1} key={`k${idx}`} headerTitle={faq.question}>
                  <p>{faq.answer}</p>
                </MDBAccordionItem>
              ))}
            </MDBAccordion>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>

  )
}