import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import _ from 'lodash';
import { MDBBadge, MDBBtn, MDBIcon, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Coupon from "../../../classes/firebase/Coupon/Coupon";
import Location from "../../../classes/firebase/Location/Location";
import Order from "../../../classes/firebase/Order/Order";
import AdminOrdersTable from "../../../classes/firebase/Order/views/AdminOrdersTable";
import Product from "../../../classes/firebase/Product/Product";
import User from "../../../classes/firebase/User/User";
import { db } from "../../../firebase/firebaseConfig";
import DualColumnLayout from '../Layouts/DualColumnLayout';
import Billing from './tabs/Billing';
import Items from './tabs/Items';
import Notes from './tabs/Notes';
import PickupDropoff from './tabs/PickupDropoff';
import Summary from './tabs/Summary';
import Season from "../../../classes/firebase/Season/Season";
import History from "./tabs/History";
export default function AdminOrders() {
    const navigate = useNavigate()
    const params = useParams()
    const [orders, setOrders] = useState([])
    const [products, setProducts] = useState([])
    const [locations, setLocations] = useState([])
    const [users, setUsers] = useState([])
    const [coupons, setCoupons] = useState([])
    const [selectedOrder, setSelectedOrder] = useState(null)

    const [activeTab, setActiveTab] = useState(null)

    //this breaks on iphone
    useEffect(() => {
        (async () => Promise.all([Product.getCollection(), Location.getCollection(), User.getCollection()])
            ?.then(async ([products, locations, users, coupons]) => {
                setProducts(products)
                setLocations(locations)
                setUsers(users)
                //return setSelectedOrder(params?.id ? [...orders]?.find(o => o?.id === params?.id) : null)
            })
        )()
    }, [])

    useEffect(() => {
        const load = async () => {
            if (_.get(params, 'id')) {
                const o = await Order.get(_.get(params, 'id'))
                if (o) return setSelectedOrder(o)
                return navigate('/admin/orders')
            }
            if ((!_.get(params, 'id')) && selectedOrder) return setSelectedOrder(null)
        }
        load()
    }, [params.id])

    console.log(Date.now())

    useEffect(() => {
        const orders = onSnapshot(collection(db, 'orders'), async snap => {
            const orders = snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref, id: doc.id }))
            setOrders(await Promise.all(_.map(orders, async o => {
                const [season, location, user] = await Promise.all([
                    Season.getReference(_.get(o, 'season')),
                    Location.getReference(_.get(o, 'location')),
                    User.getReference(_.get(o, 'user')),
                ])
                _.set(o, 'location', location)
                _.set(o, 'season', season)
                _.set(o, 'user', user)
                _.set(o, 'billing.subscription', await getDoc(doc(db, 'subscriptions', _.get(o, 'id'))).then(doc => doc.exists() ? { ...doc.data(), ref: doc.ref } : null))

                return o
            })))
        })
        return orders
    }, [])

    useEffect(() => setActiveTab(selectedOrder ? (activeTab ? activeTab : 'Summary') : null), [selectedOrder])


    const updateOrder = async (e, newOrder) => {
        e.preventDefault()
        await newOrder.submit(e)
        setOrders(orders => {
            const copy = [...orders]
            const idx = [...copy]?.map(l => l?.id)?.indexOf(newOrder.id)
            copy[idx === -1 ? copy.length : idx] = newOrder
            return copy
        })
        return setSelectedOrder(newOrder)
    }

    const updateOrders = (newOrder) => {
        setOrders(orders => {
            const copy = [...orders]
            const idx = [...copy]?.map(l => l?.id)?.indexOf(newOrder.id)
            copy[idx === -1 ? copy.length : idx] = newOrder
            return copy
        })
        return setSelectedOrder(newOrder)
    }

    return (
        <div>
            <div className="px-md-3 px-lg-4 bg-white p-2 border mb-2 d-flex justify-content-between align-items-center">
                <MDBTypography className='display-6 mb-0'>
                    <span>Orders</span>
                    {selectedOrder &&
                        <span>: {selectedOrder?.number}</span>
                    }
                </MDBTypography>
                {selectedOrder &&
                    <MDBBtn className="btn-close" color="none" aria-label="Close" onClick={() => {
                        setSelectedOrder(null)
                        if (_.get(params, 'id')) {
                            return navigate('/admin/orders')
                        }
                    }} />

                }
            </div>
            <div className={selectedOrder ? 'd-none' : ''}>
                <AdminOrdersTable orders={orders} setSelectedOrder={async (ref) => {
                    return setSelectedOrder(await Order.get(ref))
                }} />
            </div>
            {selectedOrder &&
                <DualColumnLayout
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    leftCard={{
                        title: selectedOrder?.status,
                        subTitle: 'CUSTOMER',
                        text: selectedOrder?.user?.name
                    }}
                    tabs={[
                        {
                            title: 'Summary',
                            component: <Summary
                                order={selectedOrder}
                                updateOrder={updateOrder}
                                locations={locations}
                                users={users}
                            />
                        },
                        {
                            title: 'Billing',
                            badge: selectedOrder?.user?.customer?.default_source ? "" :
                                <MDBBadge light style={{ fontSize: '1em' }} className='rounded-5' color='secondary'>
                                    <MDBIcon fas icon={'exclamation'} />
                                </MDBBadge>
                            ,
                            component: <Billing
                                order={selectedOrder}
                                setOrder={setSelectedOrder}
                                updateOrders={updateOrders}
                                products={products}
                                coupons={coupons}
                            />
                        },
                        {
                            title: 'Items',
                            badge: <MDBBadge light pill color='secondary' style={{ fontSize: '1em' }}>{selectedOrder?.items?.length}</MDBBadge>,
                            component: <Items
                                order={selectedOrder}
                                setOrder={setSelectedOrder}
                                products={products}
                            />
                        },
                        {
                            title: 'Pickup',
                            badge:
                                <MDBBadge light color={selectedOrder?.pickup?.complete ? 'success' : 'danger'} style={{ fontSize: '1em' }} className='rounded-5'>
                                    <MDBIcon fas icon={selectedOrder?.pickup?.complete ? 'check' : 'times'} />
                                </MDBBadge>,
                            component: <PickupDropoff
                                order={selectedOrder}
                                updateOrder={updateOrder}
                                mode='Pickup'
                            />
                        },
                        {
                            title: 'Dropoff',
                            badge: <MDBBadge light color={selectedOrder?.dropoff?.complete ? 'success' : 'danger'} style={{ fontSize: '1em' }} className='rounded-5'>
                                <MDBIcon fas icon={selectedOrder?.dropoff?.complete ? 'check' : 'times'} />
                            </MDBBadge>,
                            component: <PickupDropoff
                                order={selectedOrder}
                                updateOrder={updateOrder}
                                mode='Dropoff'
                            />
                        },
                        {
                            title: 'Admin Notes',
                            component: <Notes
                                order={selectedOrder}
                                updateOrder={updateOrder}
                            />
                        },
                        {
                            title: 'History',
                            component: <History
                                order={selectedOrder}
                            />
                        }
                    ]}

                />}
        </div>
    )
}