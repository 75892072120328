import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function UnauthRoute({ children }) {
    const { user, isAuthenticated } = useAuth()
    return isAuthenticated ? <Navigate to="/account" /> : children;
}


