import { MDBCol, MDBInput, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import { formatPhoneNumber } from '../../../../utils/formatting/formattingUtils'
import AddressForm from '../../Address/forms/AddressForm'

export default function ContactForm({ contact, setContact }) {
   
    return (
        <div>
            <MDBRow className="d-flex flex-row align-items-center">
                <MDBCol md='6'>
                    <MDBInput
                        name={`first`}
                        label='First Name'
                        type='text'
                        className='w-100'
                        wrapperClass='mb-3'
                        value={contact?.first || ''}
                        onChange={(e) => setContact(contact.onChange(e.target.name, e.target.value))}
                    />
                </MDBCol>
                <MDBCol md='6'>
                    <MDBInput
                        name={`last`}
                        label='Last Name'
                        type='text'
                        className='w-100'
                        wrapperClass='mb-3'
                        value={contact?.last || ''}
                        onChange={(e) => setContact(contact.onChange(e.target.name, e.target.value))}
                    />
                </MDBCol>
            </MDBRow>
            <AddressForm address={contact?.address} setAddress={(address) => setContact(contact.onChange('address', address))} />
            <MDBInput
                name={`email`}
                label='Email'
                type='email'
                className='w-100'
                wrapperClass='mb-3'
                value={contact?.email || ''}
                onChange={(e) => setContact(contact.onChange(e.target.name, e.target.value))}
            />
            <MDBInput
                name={`phone`}
                label='Phone'
                type='tel'
                className='w-100'
                wrapperClass='mb-3'
                value={contact?.phone || ''}
                onChange={(e) => setContact(contact.onChange(e.target.name, formatPhoneNumber(e.target.value)))}
            />
        </div>
    )
}
