import { MDBAlert, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

export default function OrderCustomerDashboard({ order }) {
    const [portalUrl, setPortalUrl] = useState(null)

    useEffect(() => {
        (async () => {
            if (order?.user?.customer?.id) {
                const newPortalUrl = await order?.user?.createCustomerPortalSession(window.location.href)
                return setPortalUrl(newPortalUrl)
            }
            return
        })()
    }, [order?.user])
    return (
        <div className='p-2 p-sm-4'>
            <MDBAlert show={order?.user?.customer  && portalUrl} color='secondary'>
                <a href={portalUrl} className="alert-link">
                    View/Update Billing Information Here
                </a>
            </MDBAlert>
            <MDBRow className='mb-3'>
                <MDBCol lg="6">
                    <MDBTypography tag='h5' className='bg-light d-flex justify-content-between align-items-center py-2 px-2 mx-n2 px-md-3 border-bottom mb-0'>
                        Order #{order?.number}
                    </MDBTypography>
                    <MDBListGroup light className="rounded-3 mb-3">
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                            <p className='mb-0 pe-3'>School</p>
                            <p className='text-muted mb-0'>{order?.location?.name}</p>

                        </MDBListGroupItem>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                            <p className='mb-0 pe-3'>Season</p>
                            <p className='text-muted mb-0'>{order?.season?.name}</p>

                        </MDBListGroupItem>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                            <p className='mb-0 pe-3'>Balance</p>
                            <p className='text-muted mb-0'>${(order?.user?.customer?.balance / 100)?.toFixed(2) || '0.00'}</p>
                        </MDBListGroupItem>
                    </MDBListGroup>
                    <hr className='mx-n2' />
                </MDBCol>
                <MDBCol lg="6">
                    <MDBTypography tag='h5' className='bg-light d-flex justify-content-between align-items-center py-2 px-2 mx-n2 px-md-3 border-bottom mb-0'>Student Contact</MDBTypography>
                    <MDBListGroup light className="rounded-3 mb-3">
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                            <p className='mb-0 pe-3'>Name</p>
                            <p className='text-muted mb-0'>{order?.contact?.[0]?.first} {order?.contact?.[0]?.last}</p>

                        </MDBListGroupItem>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                            <p className='mb-0 pe-3'>Phone</p>
                            <p className='text-muted mb-0'>{order?.contact?.[0]?.phone}</p>

                        </MDBListGroupItem>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                            <p className='mb-0 pe-3'>Email</p>
                            <p className='text-muted mb-0'>{order?.contact?.[0]?.email}</p>
                        </MDBListGroupItem>
                    </MDBListGroup>
                    <hr className='mx-n2' />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                {['Pickup', 'Dropoff']?.map((mode, idx) => (
                    <MDBCol lg="6" key={`dashboard-${idx}`}>
                        <MDBTypography tag='h5' className='bg-light d-flex justify-content-between align-items-center py-2 px-2 mx-n2 px-md-3 border-bottom mb-0'>{mode}</MDBTypography>
                        <MDBListGroup light className="rounded-3 mb-3">
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                                <p className='mb-0 pe-3 pe-3'>Location</p>
                                <p className='text-muted mb-0'>{order?.[mode?.toLowerCase()]?.dorm?.name === 'Other' ?
                                    `${order?.[mode?.toLowerCase()]?.dorm?.street} ${order?.[mode?.toLowerCase()]?.dorm?.city} ${order?.[mode?.toLowerCase()]?.dorm?.state} ${order?.[mode?.toLowerCase()]?.dorm?.zip}` :
                                    order?.[mode?.toLowerCase()]?.dorm?.name}</p>
                            </MDBListGroupItem>
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                                <p className='mb-0 pe-3'>Room</p>
                                <p className='text-muted mb-0'>{order?.[mode?.toLowerCase()]?.room}</p>
                            </MDBListGroupItem>
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                                <p className='mb-0 pe-3'>Date</p>
                                <p className='text-muted mb-0'>{order?.[mode?.toLowerCase()]?.date?.date}</p>
                            </MDBListGroupItem>
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                                <p className='mb-0 pe-3'>Time</p>
                                <p className='text-muted mb-0'>{order?.[mode?.toLowerCase()]?.date?.time}</p>
                            </MDBListGroupItem>
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center px-1 px-md-3">
                                <p className='mb-0 pe-3'>Notes</p>
                                <p className='text-muted mb-0'>{order?.[mode?.toLowerCase()]?.notes}</p>
                            </MDBListGroupItem>
                        </MDBListGroup>
                        <hr className='mx-n2' />
                    </MDBCol>
                ))
                }

            </MDBRow>

        </div>
    )
}
