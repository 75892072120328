import { MDBBtn, MDBCheckbox, MDBDatepicker, MDBInput, MDBPopconfirm, MDBPopconfirmMessage, MDBSelect, MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import React from 'react'
import User from '../../User/User'
import _ from 'lodash'
export default function AdminSeasonEditForm(props) {
    const { season, setSeason, save, cancel, remove, users } = props
    return (
        <div>
            <MDBTable align="middle" className='bg-white border'>
                <MDBTableBody>
                    <tr className='bg-light'>
                        <th scope='row'>
                        </th>
                        <th className='d-flex justify-content-end'>
                            <MDBBtn size='sm' color='link' onClick={async (e) => await save(e, season)}>
                                Save
                            </MDBBtn>
                            <MDBBtn size='sm' color='link' onClick={cancel}>
                                Cancel
                            </MDBBtn>
                            {false && season?.id && <MDBPopconfirm
                                btnChildren='Delete'
                                btnClassName='btn-link btn-sm bg-light text-danger border'
                                placement='bottom'
                                confirmBtnText='Delete'
                                confirmBtnClasses='btn-danger'
                                cancelBtnText='Cancel'
                                onConfirm={async (e) => await remove(e, season)}>
                                <MDBPopconfirmMessage>Are you sure?</MDBPopconfirmMessage>
                            </MDBPopconfirm>}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            Name
                        </td>
                        <td>
                            <MDBInput
                                value={season?.name || ''}
                                name='name'
                                onChange={(e) => setSeason(season.onChange(e.target.name, e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Year
                        </td>
                        <td>
                            <MDBInput
                                value={season?.year || ''}
                                name='year'
                                onChange={(e) => setSeason(season.onChange(e.target.name, e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Number of Months
                        </td>
                        <td>
                            <MDBInput
                                value={season?.months || ''}
                                name='months'
                                onChange={(e) => setSeason(season.onChange(e.target.name, e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Billing Start Date
                        </td>
                        <td>
                            <MDBDatepicker
                                inputToggle
                                value={_.get(season, 'billingStartDate') || ''}
                                format={'mm/dd/yyyy'}
                                onChange={(e) => {
                                    if (e) {
                                        return setSeason(season.onChange('billingStartDate', e))
                                    }
                                    return
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Active
                        </td>
                        <td>
                            <MDBCheckbox
                                checked={season?.active || false}
                                name='active'
                                onChange={(e) => setSeason(season.onChange(e.target.name, e.target.checked))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Registration Open
                        </td>
                        <td>
                            <MDBCheckbox
                                checked={season?.registrationOpen || false}
                                name='registrationOpen'
                                onChange={(e) => setSeason(season.onChange(e.target.name, e.target.checked))}
                            />
                        </td>
                    </tr>
                    <PickupDropoffSection
                        season={season}
                        setSeason={setSeason}
                        mode='Pickup'
                    />
                    <PickupDropoffSection
                        season={season}
                        setSeason={setSeason}
                        mode='Dropoff'
                    />
                    <tr className='bg-light'>
                        <th scope='row'>
                            Workers
                        </th>
                        <th className='d-flex justify-content-end'>
                            <MDBBtn size='sm' color='link' onClick={() => setSeason(season => {
                                const copy = _.cloneDeep(season)
                                copy.workers.push(User.init().then(u => u))
                                return copy
                            })}>
                                Add
                            </MDBBtn>
                        </th>
                    </tr>
                    {season?.workers && season?.workers?.map((worker, jj) => (
                        <tr key={`season-Worker-${jj}`}>
                            <td>
                                <MDBBtn size='sm' color='link' className='text-danger' onClick={() => setSeason(season => {
                                    const copy = _.cloneDeep(season)
                                    copy.workers.splice(jj, 1)
                                    return copy
                                })}>
                                    Delete
                                </MDBBtn></td>
                            <td>
                                <MDBSelect
                                    preventFirstSelection
                                    label=''
                                    search
                                    data={[...users]?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((user, idx) => ({
                                        text: user?.name,
                                        secondaryText: user?.email,
                                        value: user,
                                        defaultSelected: user?.id === worker?.id,
                                        name: `workers[${jj}]`
                                    }))}
                                    onValueChange={(e) => setSeason(season.onChange(e.name, e.value))}
                                />
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </div>
    )

}

const PickupDropoffSection = ({ season, setSeason, mode }) => {
    const objectMode = mode?.toLowerCase()
    return (
        <>
            <tr className='bg-light'>
                <th scope='row' colSpan={2}>
                    {mode}
                </th>
            </tr>
            <tr>
                <td>
                    Open
                </td>
                <td>
                    <MDBCheckbox
                        checked={season?.[objectMode]?.open || false}
                        name={`${objectMode}.open`}
                        onChange={(e) => setSeason(season.onChange(e.target.name, e.target.checked))}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    Min Date
                </td>
                <td>
                    <MDBDatepicker
                        inputToggle
                        inputLabel='Min Date'
                        format={'mm/dd/yyyy'}
                        value={season?.[objectMode]?.min || ''}
                        onChange={(e) => setSeason(season?.onChange(`${objectMode}.min`, e))}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    Max Date
                </td>
                <td>
                    <MDBDatepicker
                        inputToggle
                        inputLabel='Max Date'
                        format={'mm/dd/yyyy'}
                        value={season?.[objectMode]?.max || ''}
                        onChange={(e) => setSeason(season?.onChange(`${objectMode}.max`, e))}
                    />
                </td>
            </tr>
        </>
    )
}