import { MDBCard, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import React from "react";

export default function TermsAndConditions() {

  return (
    <MDBCard>
      <MDBCardBody>
        <p>Last updated: April 13, 2022</p>
        <p>
          Please read these Terms and Conditions (“Terms”) carefully before using the www.valetstudentstorage.com website (the “Service”) operated by Valet Student Storage LLC (“VSS”, “we”, “us”, or “our”). Please read the below terms and conditions before registering for or purchasing our services. Your (“Customer”) access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
        </p>
        <MDBCardTitle>Service Description</MDBCardTitle>
        <p>
          Our Service involves delivery of empty moving boxes to Customer’s housing, followed by the pickup, storage, and delivery of Customer’s items at the conclusion of the Customer requested storage term. VSS catalogs and itemizes Customer boxes and items photographically and does not open or examine sealed boxes or items. VSS does not take ownership or possession of Customer items. To use our services, the Customer creates an account on our website and shares with VSS name, email address, and phone number. To create a reservation on our website, the Customer inputs additional information, such as: housing address, requested storage period, anticipated type and quantity of items for storage. By registering and creating a reservation on our website, the Customer agrees to the collection and use of personal data with VSS. We do not share personal information with any third parties. For more information, please visit our Privacy Policy.

          The Customer is responsible for packing, ensuring items are securely and carefully deposited in moving boxes. VSS is not responsible for damaged items as a result of poor packing. VSS reserves the right to reject items that are poorly packed, in damaged condition, or otherwise a travel risk. Customer agrees to not store dangerous items, food or drink, drugs, contraband, or any other hazardous items. VSS recommends not to store expensive, delicate, rare or otherwise valuable items.

        </p>
        <MDBCardTitle>Service Pricing</MDBCardTitle>
        <p>
          Pricing for storage services is located on our website. The “Storage Cost Estimator” tool on our website is not a quote for services and only serves as an estimate for cost. Prices are subject to change without notice. Payment for services is due in the full amount within 30 days of VSS picking up and storing Customer items. The Customer agrees to pay the cost to VSS, determined by the type, quantity, and length of storage.

          Pricing for hauling (one-way transportation between housing locations) is located on our website. The Customer agrees to pay the flat rate for hauling services within 30 days of the service being performed. Additional charges may apply for long-distance hauling.

        </p>
        <MDBCardTitle>Cancellation</MDBCardTitle>
        <p>
          There is no cancellation charge until items have been picked up by VSS. If the Customer has been distributed boxes by VSS, a five dollar fee applies per box unless returned. The Customer cannot cancel purchased services after VSS has picked up Customer items, but may change requested storage length upon agreement with VSS.
        </p>
        <MDBCardTitle>Item Storage</MDBCardTitle>
        <p>
          VSS uses third-party climate-controlled, air-conditioned space to store Customer items. All Customer items are stored in one space and are cataloged with identification tags. No third-party has access to the storage space.
        </p>
        <MDBCardTitle>Delivery</MDBCardTitle>
        <p>
          Upon expiration of the agreed upon storage term, VSS will deliver Customer items to the Customer’s housing. The Customer will use the website to request delivery address, date, and time of delivery. VSS cannot guarantee date and time of delivery, but will reasonably return items according to the request. Upon receiving items, the Customer should examine their items to ensure they have received all of which they have stored, as well to note any damages.
        </p>
        <MDBCardTitle>Damaged or Lost Items</MDBCardTitle>
        <p>
          VSS will be liable to the Customer for up to one hundred dollars per item stored with the Company for any excessive damages to items, excluding normal wear and tear of transportation. By using our services, Customer agrees that VSS's liability is limited to one hundred dollars per item. The Customer has twenty-four hours from the time of delivery to initiate a claim for damaged or lost items. Claims for damaged or lost items will be processed within a reasonable time.
        </p>
      </MDBCardBody>
    </MDBCard>
  )
}