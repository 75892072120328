import * as fb from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Address from "../Address/Address";
import Location from "../Location/Location";
import Model from "../Model";
import Order from "../Order/Order";

export default class Dorm extends Model {
    constructor(data) {
        super(data)
        this.name = data.name
        this.location = data.location
        this.active = data.active
        this.address = data.address
    }

    static collectionName = 'dorms'

    static async init(data) {
        const d =
        {
            name: data?.name || null,
            location: this?.location instanceof Location ? this?.location : await Location.get(data?.location) || (data?.location ? data?.location : null),
            active: data?.active || false,
            address: Address.init(data?.address)
        }
        return new Dorm(d)
    }

    static transformToFirestore(data) {
        return {
            name: data?.name || null,
            location: data?.location?.ref || null,
            active: data?.active || false,
            address: Address?.toObject(data?.address)
        }
    }

    static otherDorm(data) {
        return new Dorm(
            {
                name: data?.name || 'Other',
                location: data?.location || null,
                active: data?.active || true,
                address: Address.init(data?.address)
            }
        )


    }

}