import { collection, onSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane
} from 'mdb-react-ui-kit';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Dorm from '../../../classes/firebase/Dorm/Dorm';
import Location from '../../../classes/firebase/Location/Location';
import Order from '../../../classes/firebase/Order/Order';
import User from '../../../classes/firebase/User/User';
import { db } from '../../../firebase/firebaseConfig';
import Item from '../../../classes/firebase/Item/Item';
import Product from '../../../classes/firebase/Product/Product';
import Price from '../../../classes/firebase/Price/Price';
import Season from '../../../classes/firebase/Season/Season';

export default function Dashboard() {
    const [orders, setOrders] = useState([])
    const [items, setItems] = useState([])
    const [view, setView] = useState('Day')
    useEffect(() => {
        const orders = onSnapshot(collection(db, 'orders'), async snap => {
            const orders = snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref, id: doc.id }))
            setOrders(await Promise.all(_.map(orders, async o => {
                const [season, location, user, pickupDorm, dropoffDorm] = await Promise.all([
                    Season.getReference(_.get(o, 'season')),
                    Location.getReference(_.get(o, 'location')),
                    User.getReference(_.get(o, 'user')),
                    Dorm.getReference(_.get(o, 'pickup.dorm')),
                    Dorm.getReference(_.get(o, 'dropoff.dorm'))
                ])
                _.set(o, 'season', season)
                _.set(o, 'location', location)
                _.set(o, 'user', user)
                _.set(o, 'pickup.dorm', pickupDorm)
                _.set(o, 'dropoff.dorm', dropoffDorm)
                _.set(o, 'contact', Order.calculateContactField(_.get(o, 'contact')))
                return o
            })))
        })
        return orders
    }, [])

    useEffect(() => {
        const items = onSnapshot(collection(db, 'items'), async snap => {
            const items = await Promise.all(snap.docs.map(async doc => await Item.populateSnapshot(doc)))
            setItems(await Promise.all(_.map(items, async o => {
                _.set(o, 'order', await Order.getReference(_.get(o, 'order')))
                _.set(o, 'price', await Price.getReference(_.get(o, 'price')))
                _.set(o, 'product', await Product.getReference(_.get(o, 'product')))
                _.set(o, 'product.default_price', await Price.getReference(_.get(o, 'product.default_price')))
                if (!_.get(o, 'price')) {
                    if (_.get(o, 'product.default_price')) {
                        _.set(o, 'price', _.get(o, 'product.default_price'))
                    }
                }
                return o
            })))
        })
        return items
    }, [])

    const handleTab = (v) => {
        if (v === view) return
        return setView(v)
    }

    const getValue = (object, status) => {
        if (object === 'Item') {
            const filtered = _.filter([...items], i => {
                if (!_.isEqual(status, _.get(i, 'status'))) return false
                if (!_.get(i, 'price')) return false
                if (!_.get(i, 'price.unit_amount')) return false
                return true
            })
            const mapped = _.map(filtered, i => {
                const v = _.get(i, 'price.unit_amount')
                const order = _.find([...orders], { id: _.get(i, 'order.id') })
                const months = _.toNumber(_.get(order, 'season.months')) || 4
                return (v * months) / 100
            })
            return (_.sum(_.filter(mapped, _.isNumber)) || 0)?.toFixed(2)
        }
    }

    return (
        <MDBContainer fluid>
            <MDBRow className='justify-content-center mb-3'>
                <MDBCol md='10'>
                    <section>
                        <MDBRow center>
                            <MDBCol md='5' className='mb-md-0'>
                                <MDBCard className='rounded-0 text-center'>
                                    <MDBCardBody>
                                        <p className='text-muted mb-1'>Orders</p>
                                        <div className='d-flex justify-content-around'>
                                            <div>
                                                <h5 className='mb-0'>{_.size(_.filter([...orders], { status: 'In Progress' }))}</h5>
                                                <figcaption className='figure-caption'>In Progress</figcaption>
                                            </div>
                                            <div>
                                                <h5 className='mb-0'>{_.size(_.filter([...orders], { status: 'Completed' }))}</h5>
                                                <figcaption className='figure-caption'>Completed</figcaption>
                                            </div>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='5' className='mb-md-0'>
                                <MDBCard className='rounded-0 text-center'>
                                    <MDBCardBody>
                                        <p className='text-muted mb-1'>Items</p>
                                        <div className='d-flex justify-content-around'>
                                            <div>
                                                <h5 className='mb-0'>{_.size(_.filter([...items], { status: 'With Customer' }))}</h5>
                                                <figcaption className='figure-caption'>With Customer</figcaption>
                                                <h5 className='mb-0'>${getValue('Item', 'With Customer')}</h5>
                                                <figcaption className='figure-caption'>Value</figcaption>
                                            </div>
                                            <div>
                                                <h5 className='mb-0'>{_.size(_.filter([...items], { status: 'In Storage' }))}</h5>
                                                <figcaption className='figure-caption'>In Storage</figcaption>
                                                <h5 className='mb-0'>${getValue('Item', 'In Storage')}</h5>
                                                <figcaption className='figure-caption'>Value</figcaption>
                                            </div>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </section>
                </MDBCol>
            </MDBRow>
            <MDBRow className='justify-content-center'>
                <MDBCol xl='11'>
                    <MDBTabs className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTab('Day')} active={view === 'Day'}>
                                Day
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTab('Calendar')} active={view === 'Calendar'}>
                                Calendar
                            </MDBTabsLink>
                        </MDBTabsItem>

                    </MDBTabs>

                    <MDBTabsContent>
                        <MDBTabsPane show={view === 'Day'}>
                            <DashboardDayView orders={orders} />
                        </MDBTabsPane>
                        <MDBTabsPane show={view === 'Calendar'}>
                            <DashboardCalender orders={orders} />
                        </MDBTabsPane>
                    </MDBTabsContent>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

const DashboardCalender = ({ orders }) => {
    const [today, setToday] = useState(new Date())
    const [currentMonth, setCurrentMonth] = useState(null)

    useEffect(() => {
        return setCurrentMonth(updateMonth(today?.getMonth(), today?.getFullYear()))
    }, [])

    const updateMonth = (newMonth, newYear) => {

        const firstDay = new Date(newYear, newMonth, 1)
        const lastDay = new Date(newYear, newMonth + 1, 0)

        const numDays = lastDay?.getDate()
        return {
            title: firstDay?.toLocaleDateString('en-US', { month: 'long' }),
            monthNumber: firstDay?.getMonth(),
            yearNumber: firstDay?.getFullYear(),
            firstDay: firstDay?.getDay(),
            numDays: numDays,
            numRows: Math.ceil(numDays / 7)
        }

    }

    const iterateMonth = (change) => {
        if (change === 1 && currentMonth?.monthNumber === 11) {
            return setCurrentMonth(updateMonth(0, currentMonth?.yearNumber + 1))
        }
        else if (change === -1 && currentMonth?.monthNumber === 0) {
            return setCurrentMonth(updateMonth(11, currentMonth?.yearNumber - 1))
        }
        else {
            return setCurrentMonth(updateMonth(currentMonth?.monthNumber + change, currentMonth?.yearNumber))
        }
    }

    const createDateData = (date, mode) => {
        const data = [...orders]?.filter(o => {
            if (o?.[mode]?.date?.date === date?.toLocaleDateString('en-US')) {
                return true
            }
            if (new Date(o?.[mode]?.date?.date) - date === 0) {
                return true
            }
            return false
        })

        const dateData = _.uniq([...data]?.map(d => (d?.location?.name || 'Other Location')))?.map(location => {
            const locationFilteredData = [...data]?.filter(d => (d?.location?.name || 'Other Location') === location)
            const times = _.uniq(locationFilteredData
                ?.map(d => (d?.[mode]?.date?.time || 'Other Time')))?.map(time => {
                    const timeFilteredData = [...locationFilteredData]?.filter(d => (d?.[mode]?.date?.time || 'Other Time') === time)
                    const dorms = _.uniq(timeFilteredData
                        ?.map(d => (d?.[mode]?.dorm?.name || 'Other Dorm')))?.map(dorm => {
                            const dormFilteredData = [...timeFilteredData]?.filter(d => (true) && (d?.[mode]?.dorm?.name || 'Other Dorm') === dorm)
                            return {
                                dorm: dorm,
                                orders: dormFilteredData
                            }
                        })
                    return {
                        time: time,
                        dorms: dorms?.sort((a, b) => a?.dorm?.localeCompare(b?.dorm) || '')
                    }
                })
            return {
                location: location,
                times: times?.sort((a, b) => a?.time?.includes('Morning') ? -1 : 1)
            }
        })
        return dateData
    }

    const getDateData = (date) => {
        const data = {
            pickup: createDateData(date, 'pickup'),
            dropoff: createDateData(date, 'dropoff')
        }
        return data

    }

    return (
        <div className='bg-white'>
            <div className='border'>
                <div className="d-flex justify-content-start align-items-center">
                    <div className="d-flex align-items-center">
                        <MDBBtn color='link' className="text-dark" onClick={() => setCurrentMonth(updateMonth(today?.getMonth(), today?.getFullYear()))}>Today</MDBBtn>
                        <MDBBtn color='link' className="text-dark" onClick={() => iterateMonth(-1)}>
                            <MDBIcon className="fas fa-chevron-left" />
                        </MDBBtn>
                        <MDBBtn color='link' className="text-dark" onClick={() => iterateMonth(1)}>
                            <MDBIcon className="fas fa-chevron-right" />
                        </MDBBtn>
                    </div>
                    <div>
                        <h5 className='mb-0'>{currentMonth?.title} {currentMonth?.yearNumber}</h5>
                    </div>
                </div>
                <MDBTable small>
                    <MDBTableBody>
                        {currentMonth && _.range(0, currentMonth?.numDays)?.map((day, a) => {
                            const fullDate = new Date(currentMonth?.yearNumber, currentMonth?.monthNumber, day)
                            const dateOrders = getDateData(fullDate)
                            if (dateOrders?.pickup?.length || dateOrders?.dropoff?.length) {

                                return (
                                    <tr key={`day${day}`}>
                                        <td>
                                            <MDBRow start>
                                                {day}
                                            </MDBRow>
                                            <MDBRow start>
                                                {Object.keys(dateOrders)?.map((mode, j) => {
                                                    return dateOrders[mode]?.map((o, e) => (
                                                        <div key={`day${day}${j}${mode}`}>
                                                            <p className='fw-bold mb-0 text-start'>{o?.location}</p>
                                                            {o?.times?.map((time, k) => {
                                                                return (
                                                                    <MDBRow key={`day${day}${j}${k}${mode}`}>
                                                                        <p className='fw-light mb-0 text-start'>{time?.time}</p>
                                                                        {time?.dorms?.map((dorm, l) => {
                                                                            return (
                                                                                <MDBCol key={`day${day}${j}${k}${l}${mode}`} md='6' lg='3'>
                                                                                    <MDBCard className=' m-2'>
                                                                                        <MDBCardHeader>
                                                                                            {dorm?.dorm}
                                                                                        </MDBCardHeader>
                                                                                        <MDBCardBody>
                                                                                            {dorm?.orders?.map((o, m) => {
                                                                                                return (
                                                                                                    <div key={`day${day}${j}${k}${m}${mode}`}>
                                                                                                        <Link
                                                                                                            to={`/admin/orders/${o?.id}`}
                                                                                                            className={`ps-1 pe-0 ${o?.[mode]?.complete ? 'text-decoration-line-through' : ''}`}
                                                                                                            key={`${mode}${day}${j}`}>
                                                                                                            {o?.contact?.[0]?.fullName ?
                                                                                                                o?.contact?.[0]?.fullName :
                                                                                                                o?.user?.name}
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </MDBCardBody>
                                                                                    </MDBCard>
                                                                                </MDBCol>
                                                                            )
                                                                        })}
                                                                    </MDBRow>
                                                                )
                                                            })}
                                                        </div>
                                                    ))
                                                })}

                                            </MDBRow>
                                        </td>
                                    </tr>
                                )
                            }

                        })}

                    </MDBTableBody>
                </MDBTable>
            </div>
        </div>
    )
}

const DashboardDayView = ({ orders }) => {
    const today = new Date(new Date().setHours(0, 0, 0, 0))
    const [day, setDay] = useState(today)
    const [mode, setMode] = useState('pickup')
    const [todayOrders, setTodayOrders] = useState([])

    useEffect(() => {
        return setTodayOrders([...orders] && [...orders]?.filter(order => {
            const dayString = day?.toLocaleDateString('en-US')
            const date = order?.[mode]?.date?.date
            if (date) {
                if (date === dayString || new Date(date) - day === 0) return true
                if (new Date(new Date(date).setHours(0, 0, 0, 0)) - day === 0) return true
            }
            return false
        })?.sort((a, b) => a?.[mode]?.date?.time?.includes('Morning') ? -1 : 1))
    }, [day, orders])

    return (
        <div className='bg-white'>
            <div className='border'>
                <div className="d-flex justify-content-start align-items-center">
                    <div className="d-flex align-items-center">
                        <MDBBtn color='link' className="text-dark" onClick={() => setDay(today)}>Today</MDBBtn>
                        <MDBBtn color='link' className="text-dark" onClick={() => setDay(day => new Date(day.setDate(day.getDate() - 1)))}>
                            <MDBIcon className="fas fa-chevron-left" />
                        </MDBBtn>
                        <MDBBtn color='link' className="text-dark" onClick={() => setDay(day => new Date(day.setDate(day.getDate() + 1)))}>
                            <MDBIcon className="fas fa-chevron-right" />
                        </MDBBtn>
                    </div>
                    <div>
                        <h5 className='mb-0'>{day?.toLocaleDateString()} {todayOrders?.length > 0 && `(${todayOrders?.length})`}</h5>
                    </div>
                </div>
            </div>
            <MDBTable>
                <MDBTableBody>
                    {todayOrders && Object.entries(_.groupBy(todayOrders, (o) => o?.location?.name))?.map((l, a) => {
                        const location = l?.[0]
                        const locationOrders = l?.[1]
                        return (
                            <Fragment key={`${day.toString()}${a}`}>
                                <tr className='fw-bold text-center'><td><h4>{location}</h4></td></tr>
                                {locationOrders && Object.entries(_.groupBy(locationOrders, (o) => o?.[mode]?.date?.time))?.map((t, b) => {
                                    const timeSlot = t?.[0]
                                    const timeSlotOrders = t?.[1]
                                    return (
                                        <Fragment key={`${day.toString()}${a}${b}`}>
                                            <tr className='text-center'><td><h5>{timeSlot}</h5></td></tr>
                                            {timeSlotOrders && Object.entries(_.groupBy(timeSlotOrders, (o) => o?.[mode]?.dorm?.name))?.map((dormGroup, c) => {
                                                const dorm = dormGroup?.[0]
                                                const dormOrders = _.sortBy(dormGroup?.[1],
                                                    [`${mode}.dorm.name`, `${mode}.room`]
                                                )
                                                return (
                                                    <Fragment key={`${day.toString()}${a}${b}${c}`}>
                                                        <tr className='text-center'><td><h6>{dorm}</h6></td></tr>
                                                        {dormOrders && [...dormOrders]?.map((o, d) => {
                                                            return (
                                                                <tr
                                                                    style={{ "--mdb-bg-opacity": "0.5" }}
                                                                    className={o?.[mode]?.complete ? 'bg-success' : ''} key={`${day.toString()}${a}${b}${c}${d}`}>
                                                                    <td>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <div>
                                                                                <p className='mb-1'>{User.getName(_.get(o, 'user'))} ({o?.[mode]?.room})</p>
                                                                                <p className='mb-1'>{o?.contact?.[0]?.phone}</p>
                                                                                <p className='mb-1'> {o?.contact?.[0]?.email}</p>
                                                                            </div>
                                                                            <Link to={`/admin/orders/${o?.id}`}                                                                            >
                                                                                View
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}
                            </Fragment>
                        )
                    })}
                </MDBTableBody>
            </MDBTable>
        </div>
    )
}