import Model from "../../Model"

export default class Price extends Model {
    constructor(data) {
        super(data)
        this.active = data.active
        this.billing_scheme = data.billing_scheme
        this.created = data.created
        this.currency = data.currency
        this.custom_unit_amount = data.custom_unit_amount
        this.livemode = data.livemode
        this.lookup_key = data.lookup_key
        this.metadata = data.metadata
        this.nickname = data.nickname
        this.object = data.object
        this.product = data.product
        this.recurring = data.recurring
        this.tax_behavior = data.tax_behavior
        this.tiers_mode = data.tiers_mode
        this.transform_quantity = data.transform_quantity
        this.type = data.type
        this.unit_amount = data.unit_amount
        this.unit_amount_decimal = data.unit_amount_decimal
        this.custom = data.custom
    }

    static collectionName = 'prices'

    static async init(data) {
        return new Price(
            {
                active: data?.active || false,
                billing_scheme: data?.billing_scheme,
                created: data?.created,
                currency: data?.currency,
                custom_unit_amount: data?.currency,
                livemode: data?.livemode,
                lookup_key: data?.lookup_key,
                metadata: data?.metadata || {},
                nickname: data?.nickname || null,
                object: data?.object || 'price',
                product: data?.product || null,
                recurring: data?.recurring || true,
                tax_behavior: data?.tax_behavior,
                tiers_mode: data?.tiers_mode,
                transform_quantity: data?.transform_quantity,
                type: data?.type,
                unit_amount: data?.unit_amount,
                unit_amount_decimal: data?.unit_amount_decimal,
                custom: data?.custom || false
            }
        )


    }

    get amount() {
        return `$${(this.unit_amount / 100)?.toFixed(2)}${this?.recurring?.interval ? `/${this?.recurring?.interval}` : ''}`
    }

}