
export const formatPhoneNumber = (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}

export const unitedStatesArray = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' }
];

export const getObjectPropertyWithDotString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export const GenerateRandomNumber = (digits) => {
    return Math.random().toFixed(digits).split('.')[1]
}

export const dateIterate = (d, numDays) => {
    const date = new Date(d)
    date.setDate(date.getDate() + numDays)
    return date
}

export const getDateRange = (min, max, returnAsString = false) => {
    if (min === null || max === null) return []
    const minDate = dateIterate(min, 0)
    const maxDate = dateIterate(max, 0)
    const allDates = []
    let currentDate = dateIterate(minDate, -1)

    while (currentDate < maxDate || allDates?.length > 50) {
        currentDate = dateIterate(currentDate, 1)
        if (returnAsString) {
            allDates.push(currentDate.toLocaleDateString('en-US'))

        }
        else {
            allDates.push(currentDate)
        }
    }
    return allDates
}

export const itemCardSizeText = (product, price, fromDropdown = false) => {

    const sizeUnit = product?.sizeUnit

    const min = price?.sizeRange[0]
    const max = price?.sizeRange[1]
    const unitSwitch = () => {
        switch (sizeUnit) {
            case 'cu. ft.':
                return <>ft<sup>3</sup></>
            case 'in.':
                return <>"</>
            case 'ft.':
                return <>'</>
            default:
                return <></>
        }

    }
    const generateText = () => {
        if (min && max) {
            if (min === max) {
                return min
            }
            else if (parseInt(min) === 0) {
                return '<' + max
            }
            else {
                return min + '-' + max
            }
        }
        else if (min && !max) {
            return '>' + min
        }

        else if (!min && max) {
            return '<' + max
        }
    }
    if (fromDropdown) {
        const text = generateText()
        return `${price?.name} ${generateText()} ${product?.sizeUnit}`
    }

    else {
        if (product?.sizeVisibleOnCard) {
            return (
                <>
                    {generateText()}{unitSwitch()}
                </>
            )
        }
    }

}

export const cleanSelectData = (data) => {
    try {
        return data?.length === 0 ? [{ text: 'No Options', value: null, disabled: true }] : data
    }
    catch {
        return [{ text: 'No Options', value: null, disabled: true }]
    }

}

export const flattenObject = (inputObject) => {
    let obj = { ...inputObject }
    const iterateObj = (obj, flat, k = '') => {
        for (const key of Object.keys(obj)) {
            const value = obj[key]
            if (value !== null && value !== '' && value !== undefined) {
                if (typeof value === 'object') {
                    flat = iterateObj(value, flat, k + '.' + key)
                }
                else {
                    flat[k + '.' + key] = value
                }
            }
        }
        for (const k of Object.keys(flat)) {
            if (k.charAt(0) === '.') {
                flat[k.slice(1)] = flat[k]
                delete flat[k]
            }
        }
        return flat
    }
    const flat = iterateObj(obj, {})
    return flat
}

export const objectsEqual = (object1, object2) => {
    let o1 = flattenObject({ ...object1 })
    let o2 = flattenObject({ ...object2 })
    if (Object?.keys(o1).length !== Object?.keys(o2).length) {
        return false;
    }
    for (let key of Object?.keys(o1)) {
        if (o1[key] !== o2[key]) {
            return false;
        }
    }
    return true;

}