import { MDBSideNav, MDBSideNavItem, MDBSideNavLink, MDBSideNavMenu } from 'mdb-react-ui-kit'
import React from 'react'

export default function SideNav({ order, view, setView, contentRef }) {
    return (
        <MDBSideNav
            mode='side'
            constant
            absolute
            backdrop={false}
            contentRef={contentRef.current}
            style={{ top: '60px' }}

        >
            <div className='text-center'>
                <h3 className='pt-3'>{order?.number}</h3>
                <h6 className='pb-3'>{order?.user?.first} {order?.user?.last}</h6>
                <hr className='m-0' />
            </div>
            <MDBSideNavMenu>
                <MDBSideNavItem>
                    <MDBSideNavLink active={view === 'Summary'} onClick={() => setView('Summary')}>
                        Summary
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink active={view === 'Items'} onClick={() => setView('Items')}>
                        Items
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink active={view === 'Billing'} onClick={() => setView('Billing')}>
                        Billing
                    </MDBSideNavLink>
                </MDBSideNavItem>
            </MDBSideNavMenu>
        </MDBSideNav>
    )
}
