import * as fb from "firebase/firestore";
import Address from "../../local/Address/Address";
import Model from "../../Model";
import Dorm from "../Dorm/Dorm";
import Season from "../Season/Season";



export default class Location extends Model {
    constructor(data) {
        super(data)
        this.name = data.name
        this.active = data.active
        this.reservationPrefix = data.reservationPrefix
        this.address = data.address

        this.seasons = data.seasons
        this.dorms = data.dorms
    }

    static collectionName = 'locations'

    static populate(data, seasons = [], dorms = []) {
        return new Location(
            {
                name: data?.name || null,
                active: data?.active || false,
                reservationPrefix: data?.reservationPrefix || null,
                address: Address?.init(data?.address),
                seasons,
                dorms
            }
        )
    }

    static async init(data) {
        const [seasons, dorms] = await Promise.all([
            Season.query('location', data?.ref),
            Dorm.query('location', data?.ref)
        ]
        )
        const d =
        {
            name: data?.name || null,
            active: data?.active || false,
            reservationPrefix: data?.reservationPrefix || null,
            address: Address?.init(data?.address),
            seasons,
            dorms
        }
        return new Location(d)
    }

    static transformToFirestore(data) {
        const d = {
            ...data,
            name: data?.name || null,
            active: data?.active || false,
            reservationPrefix: data?.reservationPrefix || null,
            address: Address?.toObject(data?.address)
        }
        const { seasons, dorms, ...filtered } = d
        return filtered
    }

}