import { collection, doc, getDocs, updateDoc } from "firebase/firestore"
import _ from 'lodash'
import { MDBBadge, MDBBtn, MDBCheckbox, MDBCol, MDBContainer, MDBDatatable, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRow, MDBSelect } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import User from "../../User/User"
import { db } from "../../../../firebase/firebaseConfig"
import Location from "../../Location/Location"
import Season from "../../Season/Season"
export default function AdminItemsTable({ items = [], setSelectedItem = () => null }) {
    const [selectOptions, setSelectOptions] = useState({ locations: [], seasons: [] })
    const [selectedRows, setSelectedRows] = useState([])
    const [filter, setFilter] = useState({
        status: 'In Storage'
    })
    useEffect(() => {
        const loadOptions = async () => {
            const [locations, seasons] = await Promise.all([
                Promise.all((await getDocs(collection(db, Location.collectionName))).docs.map(async doc => await Location.populateSnapshot(doc))),
                Promise.all((await getDocs(collection(db, Season.collectionName))).docs.map(async doc => await Season.populateSnapshot(doc)))
            ])
            return setSelectOptions({ locations, seasons })

        }
        loadOptions()
    }, [])

    const filterData = () => {
        const filtered = _.filter([...items], o => {
            for (const [key, value] of _.entries(_.cloneDeep(filter))) {
                if (_.get(o, key) !== value) return false
            }
            return true
        })
        return filtered
    }

    const updateStatuses = async (e, items, update) => {
        e.preventDefault()
        await Promise.all(_.map([...items], async (o) => {
            return await updateDoc(_.get(o, 'ref'), update)
        }))
        return setSelectedRows([])
    }


    return (
        <MDBContainer className='border rounded-3 bg-white py-3' >
            <MDBRow className='mb-2'>
                <MDBCol size='6' md='3'>
                    <div>
                        <p className="border-bottom mb-1">Status</p>
                        <MDBCheckbox
                            name={`status`}
                            label='In Storage'
                            checked={_.get(filter, `status`) === 'In Storage'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'In Storage')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`status`}
                            label='With Customer'
                            checked={_.get(filter, `status`) === 'With Customer'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'With Customer')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`status`}
                            label='Delivered'
                            checked={_.get(filter, `status`) === 'Delivered'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'Delivered')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`status`}
                            label='Cancelled'
                            checked={_.get(filter, `status`) === 'Cancelled'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'Cancelled')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                    </div>
                </MDBCol>
                <MDBCol size='6' md='3'>
                    <div>
                        <p className="border-bottom mb-1">Order Status</p>
                        <MDBCheckbox
                            name={`order.status`}
                            label='In Progress'
                            checked={_.get(filter, `order.status`) === 'In Progress'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'In Progress')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`order.status`}
                            label='Completed'
                            checked={_.get(filter, `order.status`) === 'Completed'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'Completed')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`order.status`}
                            label='Cancelled'
                            checked={_.get(filter, `order.status`) === 'Cancelled'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'Cancelled')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                    </div>
                </MDBCol>
                <MDBCol md='4'>
                    <MDBSelect
                        preventFirstSelection
                        label={'Location'}
                        data={_.map(_.get(selectOptions, 'locations'), o => ({ text: o.name, value: o, name: 'order.location.id', selected: _.get(filter, 'order.location.id' === _.get(o, 'id')) }))}
                        onValueChange={(e) => setFilter(filter => {
                            if (e.value) {
                                return _.set({ ...filter }, [e.name], _.get(e, 'value.id'))
                            }
                            return _.omit({ ...filter }, ['order.location.id'])
                        })}
                        clearBtn
                        className={'my-3'}
                    />
                    <MDBSelect
                        preventFirstSelection
                        label={'Season'}
                        data={_.map(_.get(selectOptions, 'seasons'), o => ({ text: `${o.name} ${o.year}`, value: o, name: 'order.season.id', selected: _.get(filter, 'order.season.id' === _.get(o, 'id')) }))}
                        onValueChange={(e) => setFilter(filter => {
                            if (e.value) {
                                return _.set({ ...filter }, [e.name], _.get(e, 'value.id'))
                            }
                            return _.omit({ ...filter }, ['order.season.id'])
                        })}
                        clearBtn
                        className={'my-3'}
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
                <MDBCol className='d-flex justify-content-end' size='auto'>
                    <MDBBtn size="sm"
                        color="light"
                        className="border"
                        disabled
                        onClick={async (e) => {
                            e.preventDefault()
                        }}>New</MDBBtn>
                </MDBCol>
            </MDBRow>
            <MDBRow>
            </MDBRow>
            <MDBDatatable
                search
                entries={10}
                sm
                hover
                data={{
                    columns: [
                        {
                            label:
                                <div className="d-flex align-items-center justify-content-center">
                                    <MDBDropdown group>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <MDBCheckbox
                                                wrapperClass=''
                                                className='m-1 mx-2'
                                                onChange={(e) => {
                                                    if (filterData().length === selectedRows.length) return setSelectedRows([])
                                                    return setSelectedRows([...filterData()])
                                                }}
                                                checked={(selectedRows.length > 0 && (filterData().length === selectedRows.length)) || false}
                                            />
                                        </div>
                                        <MDBDropdownToggle split size='sm' color='secondary' />
                                        <div style={{ position: 'fixed' }}>
                                            <MDBDropdownMenu appendToBody style={{ width: '100px' }}>
                                                <MDBDropdownItem className="btn w-100 border-bottom shadow-0 px-1"
                                                    onClick={async (e) => await updateStatuses(e, [...selectedRows], { status: 'Delivered' })}>
                                                    Delivered
                                                </MDBDropdownItem>
                                                <MDBDropdownItem className="btn w-100 border-bottom shadow-0 px-1"
                                                    onClick={async (e) => await updateStatuses(e, [...selectedRows], { status: 'In Storage' })}>
                                                    In Storage
                                                </MDBDropdownItem>
                                                <MDBDropdownItem className="btn w-100 border-bottom shadow-0 px-1"
                                                    onClick={async (e) => await updateStatuses(e, [...selectedRows], { status: 'With Customer' })}>
                                                    With Customer
                                                </MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </div>
                                    </MDBDropdown>
                                </div>
                            , field: 'selected', sort: false
                        },
                        { field: 'image', sort: false },
                        { field: 'product', sort: false },
                        { field: 'customer', sort: false }
                    ],
                    rows: filterData()?.map((item, idx) => {
                        return {
                            selected:
                                <div className="d-flex align-items-center justify-content-center">
                                    <MDBCheckbox
                                        onChange={(e) => {
                                            const copy = [...selectedRows]
                                            if (e.target.checked) {
                                                copy.push(_.find([...items], { id: _.get(items, 'id') }))
                                                return setSelectedRows(copy)
                                            }
                                            return setSelectedRows(_.filter(copy, o => _.get(o, 'id') !== _.get(item, 'id')))

                                        }}
                                        checked={(_.find([...selectedRows], { id: _.get(item, 'id') })) || false}
                                    />
                                </div>
                            ,
                            image: (
                                <img
                                    src={item?.images?.[0] ? typeof item?.images?.[0] === 'object' ? URL.createObjectURL(item?.images?.[0]) : item?.images?.[0] : null}
                                    alt=''
                                    style={{ width: '55px', height: '55px' }}
                                />
                            ),
                            product: (
                                <>
                                    <p className='fw-bold mb-1'>{String(item?.number)}</p>
                                    <p className='text-muted mb-0'>{item?.product?.name || item?.notes}</p>
                                    <MDBBadge color={item?.status === 'Delivered' ? 'success' : item?.status === 'In Storage' ? 'light' : 'danger'} light>
                                        {item?.status}
                                    </MDBBadge>
                                </>
                            ),
                            customer: (
                                <>
                                    <p className='fw-bold mb-1'>{String(item?.order?.number || 'No Order')}</p>
                                    <p className='text-muted mb-0'>{User.getName(_.get(item, 'user')) || 'No Customer'}</p>
                                </>
                            ),
                            id: item?.id,
                            ref: item?.ref,
                            search: JSON.stringify(item)

                        }
                    })
                }}
                onRowClick={(row) => setSelectedItem(_.get(row, 'ref'))}
                isLoading={!_.size(items)}
            />
        </MDBContainer>
    )

}