import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import React from 'react';

export default function ModalForm({ visible, setVisible, form, title = null, size = 'md' }) {

    return (
        <MDBModal staticBackdrop tabIndex='-1' show={visible}>
            <MDBModalDialog size={size}>
                <MDBModalContent>
                    <MDBModalHeader>
                        {title && <MDBModalTitle>{title}</MDBModalTitle>}
                        <MDBBtn className='btn-close' color='none' type="button" onClick={() => setVisible(false)}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {visible && form}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}
