import { MDBBtn, MDBCol, MDBRow,  MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardSubTitle, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'


export const AccountOrdersPane = ({ orders, loading }) => {

  const navigate = useNavigate()
  if (loading) {
    return (
      ['']?.map((o, i) => (
        <OrderCard order={o} loading={loading} key={`orderCard${i}`} />
      ))
    )
  }
  else {
    return (
      <>
        <MDBRow className='mb-4'>
          <MDBCol className='d-flex justify-content-end' size='auto'>
            <MDBBtn size="sm"
              color="light"
              className="border"
              onClick={() => navigate('orders/new')}>New Order</MDBBtn>
          </MDBCol>
        </MDBRow>
        {[...orders]?.sort((a, b) => a?.season?.year - b?.season?.year > 0 ? 1 : -1)?.map((o, i) => (
          <OrderCard order={o} loading={loading} key={`orderCard${i}`} />
        ))}
      </>
    )
  }
}

const OrderCard = ({ order, loading }) => {
  const navigate = useNavigate()

  return (
    <MDBCard className="mb-4">
      <MDBCardHeader>
        <MDBCardTitle>{loading ? <span className='placeholder col-6'></span> : order?.location?.name || ''}</MDBCardTitle>
        <MDBCardSubTitle>{loading ? <span className='placeholder col-6'></span> : order?.season?.name || ''}</MDBCardSubTitle>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBCardText>{loading ? <span className='placeholder col-6'></span> : `Order #${order?.number || 'NA'}`}</MDBCardText>
        <MDBCardText>{loading ? <span className='placeholder col-6'></span> : `Balance: $${(order?.billing?.balance || 0).toFixed(2)}`}</MDBCardText>
        <MDBCardText>{loading ? <span className='placeholder col-6'></span> : `Student: ${order?.contact?.[0]?.first} ${order?.contact?.[0]?.last}`}</MDBCardText>
      </MDBCardBody>
      <MDBCardFooter>
        {loading ? <span className='placeholder col-6'></span> : <MDBBtn onClick={(e) => navigate(`/account/orders/${order.id}`)}>View</MDBBtn>}
      </MDBCardFooter>
    </MDBCard>

  )
}

