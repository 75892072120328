export const handleFirebaseError = (error) => {
    console.log(error.code)
    switch (error?.code) {
        case 'auth/wrong-password':
            return 'Wrong Password'
        case 'auth/too-many-requests':
            return 'Too many requests. Please try again later.'
        case 'auth/weak-password':
            return 'Password too weak.'
        default:
            return 'Internal Error'
    }
}