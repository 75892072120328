import _ from 'lodash'
import { MDBBadge, MDBBtn, MDBCollapse, MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { Fragment, useEffect, useState } from 'react'
import Season from '../../../../classes/firebase/Season/Season'
import AdminSeasonEditForm from '../../../../classes/firebase/Season/forms/AdminSeasonEditForm'
export default function Seasons(props) {
    const { location, setLocation, users, active } = props


    const [activeSeason, setActiveSeason] = useState(null)

    const [seasonGroups, setSeasonGroups] = useState([])


    useEffect(() => setSeasonGroups(Object.entries(_.groupBy([...location?.seasons], (i) => i?.year || null))
        ?.sort((a, b) => a?.[0]?.localeCompare(b?.[0]))?.reverse()?.map((group, idx) => {
            return {
                year: group?.[0] || 'Other',
                seasons: group?.[1],
                show: true
            }
        })), [location.seasons])

    useEffect(() => setActiveSeason(null), [active])

    const remove = async (e, season) => {
        e.preventDefault()
        await Season.delete(season)
        setLocation(location => {
            const newLocation = _.cloneDeep(location)
            const newSeasons = [...newLocation?.seasons]
            const idx = [...newLocation?.seasons]?.findIndex((i) => i?.id === season?.id)
            if (idx !== -1) {
                newSeasons.splice(idx, 1)
            }
            newLocation.seasons = newSeasons
            return newLocation
        })
        return setActiveSeason(null)
    }

    const save = async (e, season) => {
        e.preventDefault()
        await season.submit(e)
        setLocation(location => {
            const newLocation = _.cloneDeep(location)
            const newSeasons = [...newLocation?.seasons]
            const idx = [...newLocation?.seasons]?.findIndex((i) => i?.id === season?.id)
            idx === -1 ? newSeasons.push(season) : newSeasons[idx] = season
            newLocation.seasons = newSeasons
            return newLocation
        })
        return setActiveSeason(null)
    }

    const cancel = () => setActiveSeason(null)

    return (
        <div>
            <MDBCollapse show={!activeSeason}>
                <MDBTable align='middle' className="border">
                    <MDBTableHead>
                        <tr>
                            <th scope='col' className="text-start">
                                Seasons
                            </th>
                            <th scope='col' className="text-end">
                                <MDBBtn
                                    size="sm"
                                    color='link'
                                    onClick={async () => setActiveSeason(await Season.init({ location: location.ref }))}>
                                    New
                                </MDBBtn>
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody className="bg-white">
                        {[...seasonGroups]
                            ?.map((seasonGroup, idx) => {
                                return (
                                    <Fragment key={`seasonGroup-${idx}`}>
                                        <tr>
                                            <td colSpan={2} className="text-start">
                                                <div className='d-flex align-items-center'>
                                                    <MDBBtn
                                                        tag='a'
                                                        color='none'
                                                        className="me-2"
                                                        style={{ color: '#3b5998' }}
                                                        onClick={() => setSeasonGroups(seasonGroups => {
                                                            const copy = [...seasonGroups]
                                                            copy[idx].show = !copy[idx].show
                                                            return copy
                                                        })}>
                                                        <MDBIcon fas icon={`angle-${seasonGroup?.show ? 'up' : 'down'}`} size="lg" />
                                                    </MDBBtn>
                                                    <p className="mb-0">{seasonGroup?.year} {`(${seasonGroup?.seasons?.length})`}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        {[...seasonGroup?.seasons]?.map((season, j) => (
                                            <Fragment key={`order-Seasons-${j}-${idx}`} >
                                                <tr className={seasonGroup?.show ? '' : 'd-none'}>
                                                    <td className="text-start">
                                                        <div className='d-flex align-items-center ms-1'>
                                                            <div className='ms-3'>
                                                                <p className='fw-bold mb-1'>{season?.name}</p>
                                                                <MDBBadge color={season?.registrationOpen ? 'success' : 'danger'} light>
                                                                    {season?.registrationOpen ? 'Registration Open' : 'Registration Closed'}
                                                                </MDBBadge>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <MDBBtn
                                                            size="sm"
                                                            color='link'
                                                            onClick={() => setActiveSeason(season)}>
                                                            Edit
                                                        </MDBBtn>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                )
                            })}
                    </MDBTableBody>
                </MDBTable>
            </MDBCollapse>
            <MDBCollapse show={activeSeason} >
                <AdminSeasonEditForm
                    season={activeSeason}
                    setSeason={setActiveSeason}
                    users={users}
                    save={save}
                    cancel={cancel}
                    remove={remove}
                />
            </MDBCollapse>
        </div>
    )
}
