import { dehydrateDorm } from "../oldComponents/Dorm/DormAPI"
import { dehydrateItem } from "../oldComponents/Item/ItemAPI"
import { dehydrateOrder } from "../oldComponents/Order/OrderAPI"
import { dehydratePrice } from "../oldComponents/Price/PriceAPI"
import { dehydrateProduct } from "../oldComponents/Product/ProductAPI"
import { formatPhoneNumber, getObjectPropertyWithDotString } from "../utils/formatting/formattingUtils"
import { createDocument, updateDocument } from "../utils/requests/firebaseRequests"



export const handleGenericChange = (e, object, setObject, idx = null) => {
    let name = null
    let value = null
    let obj = {}

    if (typeof e === 'object' && !Object.hasOwn(e, 'name') && (!e?.target && (typeof e?.target === 'object' && !Object.hasOwn(e?.target, 'name')))) {
        for (const key of Object.keys(e)) {
            obj = e[key]
            name = obj.name
            value = obj.value
            if (Object.hasOwn(obj, 'name')) {
                setObject({ ...object, [key]: { ...object[key], [name]: value } })
            }
            else {
                for (const key1 of Object.keys(obj)) {
                    let obj1 = obj[key1]
                    name = obj1.name
                    value = obj1.value
                    if (Object.hasOwn(obj1, 'name')) {
                        setObject({ ...object, [key]: { ...object[key], [key1]: { ...object[key][key1], [name]: value } } })
                    }
                }
            }
        }
    }
    else {
        if (e.target) {
            if (e.target.type === 'checkbox') {
                name = e.target.name
                value = e.target.checked
            }
            else {
                if (e.target.name) { name = e.target.name }
                if (e.target.value) { value = e.target.value }
                if (e.target.type === 'number') { value = parseFloat(value) }
                if (e.target.type === 'tel') { value = formatPhoneNumber(value) }

            }
        }

        else if (e.name && (e?.value || e?.value === null)) {
            name = e.name
            value = e.value
        }
        setObject({ ...object, [name]: value })

    }

}

export const handleGenericSubmit = async (e, object, collec, callback = null) => {
    e.preventDefault()
    let uploadObject = { ...object }
    if (!uploadObject?.id) {
        const req = await createDocument(collec, uploadObject)
        uploadObject.id = req.id
    }
    if (collec === 'prices') {
        uploadObject = await dehydratePrice(uploadObject)
    }
    else if (collec === 'pricing') {
        uploadObject = await dehydrateProduct(uploadObject)
    }
    else if (collec === 'orders') {
        uploadObject = await dehydrateOrder(uploadObject)
    }
    else if (collec === 'dorms') {
        uploadObject = await dehydrateDorm(uploadObject)
    }
    else if (collec === 'items') {
        uploadObject = await dehydrateItem(uploadObject)
    }
    await updateDocument(collec, uploadObject.id, uploadObject)
    callback && callback()
}