import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from 'react';
export default function FormCard({ title, formView, displayView, submit, reset, active }) {
    const [editMode, setEditMode] = useState(false)
    useEffect(() => {
        return setEditMode(false)
    }, [active])

    const handleEditMode = async (e) => {
        if (editMode) await submit(e)
        return setEditMode(!editMode)
    }

    const cancel = () => {
        reset()
        return setEditMode(false)
    }

    return (
        <MDBCard className="rounded-0 mb-2 mb-lg-4">
            <MDBCardHeader>
                <MDBCardTitle className="mb-0">{title}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow between>
                    <MDBCol size={editMode ? 12 : 10}>
                        {editMode ? formView : displayView}
                    </MDBCol>
                    <MDBCol size={editMode ? 12 : 2}
                        className='d-flex align-items-center justify-content-center mb-2'>
                        <div className='d-flex align-items-center my-2'>
                            <MDBBtn size='sm' color='link' onClick={async (e) => await handleEditMode(e)}>
                                {editMode ? 'Save' : 'Edit'}
                            </MDBBtn>
                            {editMode &&
                                <MDBBtn size='sm' color='link' onClick={() => cancel()}>
                                    Cancel
                                </MDBBtn>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}
