import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase/firebaseConfig"
import StripeModel from "./stripeModel"
import StripePrice from "./stripePrice"

export default class StripeProduct extends StripeModel {
    constructor(data) {
        super(data)
        this.active = data.active
        this.attributes = data.attributes
        this.created = data.created
        this.default_price = data.default_price
        this.description = data.description
        this.id = data.id
        this.images = data.images
        this.livemode = data.livemode
        this.metadata = data.metadata
        this.name = data.name
        this.object = data.object
        this.package_dimensions = data.package_dimensions
        this.prices = data.prices
        this.shippable = data.shippable
        this.statement_descriptor = data.statement_descriptor
        this.tax_code = data.tax_code
        this.type = data.type
        this.unit_label = data.unit_label
        this.updated = data.updated
        this.url = data.url
    }

    static object = 'product'

    static async init(data) {
        return new StripeProduct({
            ...data,
            prices: [...data?.prices]?.map(p => StripePrice.init(p)),
            default_price: data?.default_price ? StripePrice.init(data?.default_price) : null
        })
    }

    static async getAll() {
        const req = httpsCallable(functions, `stripe-${this.object}-getAll`)
        const results = await req({ limit: 1000, expand: ['data.product'] })
        const objects = await Promise.all(results?.data?.map(async (object) => await this.init(object)))
        return objects
    }

    static async retrieveAll() {
        const req = httpsCallable(functions, `stripe-${this.object}-retrieveAll`)
        const results = await req({ limit: 1000, expand: ['data.product'] })
        const objects = await Promise.all(results?.data?.map(async (object) => await this.init(object)))
        return objects
    }


}