import StripeModel from "./stripeModel";

export default class StripePrice extends StripeModel {
    constructor(data) {
        super()
        this.active = data.active
        this.billing_scheme = data.billing_scheme
        this.created = data.created
        this.currency = data.currency
        this.custom_unit_amount = data.custom_unit_amount
        this.id = data.id
        this.livemode = data.livemode
        this.lookup_key = data.lookup_key
        this.metadata = data.metadata
        this.nickname = data.nickname
        this.object = data.object
        this.product = data.product
        this.recurring = data.recurring
        this.tax_behavior = data.tax_behavior
        this.tiers_mode = data.tiers_mode
        this.transform_quantity = data.transform_quantity
        this.type = data.type
        this.unit_amount = data.unit_amount
        this.unit_amount_decimal = data.unit_amount_decimal
    }

    static object = 'price'

    static init(data) {
        return new StripePrice(data)
    }

    get amount() {
        return `$${(this.unit_amount / 100)?.toFixed(2)}${this?.recurring?.interval ? `/${this?.recurring?.interval}` : ''}`
    }
}