import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import React from "react";

export default function PrivacyPolicy() {
    return (
        <MDBCard>
            <MDBCardHeader>
                Privacy Policy
            </MDBCardHeader>
            <MDBCardBody>
                <p>Last updated: April 13, 2022</p>
                <p>
                    Please read the Privacy Policy (“Privacy Policy”) carefully before using the www.valetstudentstorage.com website (the “Service”) operated by Valet Student Storage LLC (“VSS”, “we”, “us”, or “our”). Please read the below Privacy Policy  before registering for or purchasing our services. Your (“Customer”) access to and use of the Service is conditioned upon your acceptance of and compliance with this Privacy Policy. This Privacy Policy applies to all visitors, users, and others who access or use the Service.
                </p>
                <p>
                    While using our service, we may ask you to provide us with certain personally identifiable that can be used to contact or identify you. Personal identifiable information may include, but is not limited to email addresses, phone numbers, first and last names, addresses, and usage data. Usage data is automatically collected when using our Service and is not given to any third parties.
                </p>
                <p>
                    Under certain circumstances, VSS may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or government agency).
                </p>
                <p>
                    Security and access to your personal data is important to VSS. While no method of transmission over the Internet is completely secure, we use commercially acceptable means to protect your information, but cannot guarantee its absolute security.
                </p>
                <p>
                    We may use personal data to contact the Customer with announcements, marketing, newsletters or promotional materials that may be of interest. The Customer may opt-out of receiving any or all of these communications at any time.
                </p>
                <p>
                    We do not store or collect payment card information. That information is provided directly to third-party payment processing services whose use of personal information is governed by their Privacy Policy.

                </p>
            </MDBCardBody>
        </MDBCard>
    )
}