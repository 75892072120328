import Model from "../Model";
import Product from "../Product/Product";

export default class Price extends Model {
    constructor(data) {
        super(data)
        this.name = data.name
        this.description = data.description
        this.amount = data.amount
        this.tierMaxSize = data.tierMaxSize
        this.tieredMode = data.tieredMode
        this.stripeId = data.stripeId
        this.product = data.product
    }

    static collectionName = 'prices'

    static async init(data) {
        return new Price(
            {
                name: data?.name || null,
                description: data?.description || null,
                amount: data?.amount || null,
                tierMaxSize: data?.tierMaxSize || null,
                tieredMode: data?.tieredMode || null,
                stripeId: data?.stripeId || null,
                product: this?.product instanceof Product ? this?.product : await Product.get(data?.product) || null,
            }
        )


    }
}