

import { MDBSelect } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { cleanSelectData } from '../../../../utils/formatting/formattingUtils'

export default function OrderSeasonSelect({ order, setOrder }) {
    const { user } = useAuth()
    const [options, setOptions] = useState([])
    const [refreshing, setRefreshing] = useState(false)

    useEffect(() => {
        if (!order?.location?.seasons) {
            return setOptions([])
        }
        return setOptions([...order?.location?.seasons]?.filter(s => (s?.active && s.registrationOpen) || user?.role === 'admin'))
    }, [order?.location])


    useEffect(() => {
        if (!order?.location || (order?.location?.id !== order?.season?.location?.id)) {
            return setRefreshing(true)
        }
        return
    }, [order?.season])

    useEffect(() => {
        return setRefreshing(false)
    }, [refreshing])

    return (
        <>
            {refreshing ?
                <MDBSelect
                    preventFirstSelection
                    label='Storage Season'
                    data={cleanSelectData([])}
                    key={1}
                    disabled
                />
                :
                <MDBSelect
                    preventFirstSelection
                    search
                    label='Season'
                    key={2}
                    data={cleanSelectData(options && [...options]
                        ?.sort((a, b) => `${a.name}`?.localeCompare(`${b.name}`))
                        ?.map((i) => (
                            { text: `${i?.name} ${i?.year}`, value: i, defaultSelected: order?.season?.id === i?.id }
                        )))}
                    onValueChange={(e) => setOrder(order.onChange('season', e.value))}
                />
            }
        </>
    )
}
