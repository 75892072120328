import _ from 'lodash'
import { MDBBtn, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import ModalForm from '../../../../../oldComponents/Modal/ModalForm'
import CustomerItemEditForm from '../../../Item/forms/CustomerItemEditForm'
import Item from '../../../Item/Item'
import ItemListCard from '../../../Item/views/ItemListCard'

export default function OrderItemsLayout({ order, setOrder }) {
    const [modal, setModal] = useState(false)
    const [editedItem, setEditedItem] = useState(null)

    useEffect(() => {
        editedItem ? setModal(true) : setModal(false)
    }, [editedItem])

    const remove = async (e, item) => {
        e && e.preventDefault()
        await Item.delete(item)
        setOrder(order => {
            const newOrder = _.cloneDeep(order)
            const newItems = [...newOrder?.items]
            const idx = [...order?.items]?.findIndex((i) => i?.id === item?.id)
            if (idx !== -1) {
                newItems.splice(idx, 1)
            }
            newOrder.items = newItems
            return newOrder
        })
        return setEditedItem(null)
    }

    const submit = async (e, item) => {
        e && e.preventDefault()
        await item.submit(e)
        setOrder(order => {
            const newOrder = _.cloneDeep(order)
            const newItems = [...newOrder?.items]
            const idx = [...order?.items]?.findIndex((i) => i?.id === item?.id)
            idx === -1 ? newItems.push(item) : newItems[idx] = item
            newOrder.items = newItems
            return newOrder
        })
        return setEditedItem(null)
    }

    return (
        <div>
            <MDBListGroup light>
                <MDBListGroupItem className='d-flex justify-content-center align-items-center' style={{ height: '50px' }}>
                    <div className='d-flex align-items-center'>
                        <MDBBtn
                            size="lg"
                            rounded
                            color='link'
                            onClick={async () => setEditedItem(await Item.init({ order: order, user: order.user, status: 'With Customer' }))}
                        >
                            Add Item
                        </MDBBtn>
                    </div>
                </MDBListGroupItem>
                <MDBListGroupItem className='d-flex align-items-center m-0 p-0 bg-light' >
                    <div className='d-flex align-items-center'>
                        <p className='m-0 ms-3 text-muted'>For box requests (we provide large boxes) please email us at contact@valetstudentstorage.com. This form is to pre-populate your order to streamline the pickup process.</p>
                    </div>
                </MDBListGroupItem>
                {order?.items && [...new Set([...order?.items]?.map((i) => i?.product?.metadata?.category))]?.sort((a, b) => a?.localeCompare(b))?.map((cat, i) => (
                    <div key={`cat${i}`}>
                        <h6 className={`bg-light p-2 border-top border-bottom`}>{cat || 'Other Items'}</h6>
                        {[...order?.items]?.filter((itm) => itm?.product?.metadata?.category === cat)?.map((item, index) => (
                            <ItemListCard item={item} setEditedItem={setEditedItem} key={`item${index}`} />
                        ))}
                    </div>
                ))}
            </MDBListGroup>
            <ModalForm visible={modal} setVisible={setEditedItem} form={
                <CustomerItemEditForm
                    item={editedItem}
                    setItem={setEditedItem}
                    submit={submit}
                    remove={remove}
                />}
                 />
        </div>
    )
}
