import { ErrorMessage, Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import * as fb from 'firebase/firestore'
import * as Yup from 'yup';
import styles from '../Form.module.css'
import SelectAsync from '../Fields/Select';
import { db } from '../../../firebase/firebaseConfig';
import { useOrderContext } from '../../../contexts/OrderContext';
import { useAdminContext } from '../../../contexts/AdminContext';
import FormInput from '../../form/FormInput';
import FormSelect from '../../form/FormSelect';

export default function AdminOrderSummaryForm() {
    const { order } = useOrderContext()
    const { users, locations, seasons } = useAdminContext()
    const [editMode, setEditMode] = useState(true)
    const [form, setForm] = useState({
        number: '',
        user: '',
        status: '',
        location: '',
        season: ''

    })
    useEffect(() => {
        const deepCopy = _.cloneDeep(order)
        setForm({
            number: deepCopy?.number || '',
            user: deepCopy?.user?.ref || '',
            status: deepCopy?.status || '',
            location: deepCopy?.location?.ref || '',
            season: deepCopy?.season?.ref || ''
        })
    }, [order, editMode])

    return (
        <>
            <form className={styles.form}>
                <FormInput
                    disabled={!editMode}
                    label='Number'
                    name='number'
                    value={form?.number}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                />
                <FormSelect
                    disabled={!editMode}
                    label='User'
                    name='user'
                    value={form?.user?.id}
                    onChange={(e) => setForm({ ...form, [e.target.name]: fb.doc(db, 'users', e.target.value) })}
                    options={_.sortBy([...users], ['first'], ['asc'])?.map(u => ({ value: u?.ref?.id, text: `${u?.first} ${u?.last}` }))}
                />
                <FormSelect
                    disabled={!editMode}
                    label='Status'
                    name='status'
                    value={form?.status}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    options={['In Progress', 'Completed', 'Cancelled']?.map(u => ({ value: u, text: u }))}
                />
                <FormSelect
                    disabled={!editMode}
                    label='Location'
                    name='location'
                    value={form?.location?.id}
                    onChange={(e) => setForm({ ...form, [e.target.name]: fb.doc(db, 'locations', e.target.value) })}
                    options={_.sortBy([...locations], ['name'], ['asc'])?.map(u => ({ value: u?.ref?.id, text: `${u?.name}` }))}
                />
                <FormSelect
                    disabled={!editMode}
                    label='Season'
                    name='season'
                    value={form?.season?.id}
                    onChange={(e) => setForm({ ...form, [e.target.name]: fb.doc(db, 'seasons', e.target.value) })}
                    options={_.sortBy([...seasons]?.filter(s => s?.location?.id === form?.location?.id), ['name'], ['asc'])?.map(u => ({ value: u?.ref?.id, text: `${u?.name}` }))}
                />

                <label htmlFor="number">Number</label>
                <input
                    value={form?.number}
                    name='number'
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    disabled={!editMode}
                />

                <label htmlFor="user">User</label>
                <select
                    name="user"
                    value={form?.user?.id}
                    onChange={(e) => setForm({ ...form, [e.target.name]: fb.doc(db, 'users', e.target.value) })}
                    disabled={!editMode}>
                    {[...users]?.map((u, i) => (
                        <option key={`userList${i}`} value={u?.ref?.id}>{u?.first} {u?.last}</option>
                    ))}
                </select>

                <label htmlFor="status">Status</label>
                <select
                    name="status"
                    value={form?.status}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    disabled={!editMode}>
                    {['In Progress', 'Completed', 'Cancelled']?.map((o, i) => (
                        <option key={`statusList${i}`} value={o}>{o}</option>
                    ))}
                </select>

                <label htmlFor="location">Location</label>
                <select
                    name="location"
                    value={form?.location?.id}
                    onChange={(e) => setForm({ ...form, [e.target.name]: fb.doc(db, 'locations', e.target.value) })}
                    disabled={!editMode}>
                    {[...locations]?.map((u, i) => (
                        <option key={`locationsList${i}`} value={u?.ref?.id}>{u?.name}</option>
                    ))}
                </select>

                <label htmlFor="user">Season</label>
                <select
                    name="season"
                    value={form?.season?.id}
                    onChange={(e) => setForm({ ...form, [e.target.name]: fb.doc(db, 'seasons', e.target.value) })}
                    disabled={!editMode}>
                    {[...seasons]?.map((u, i) => (
                        <option key={`seasonsList${i}`} value={u?.ref?.id}>{u?.name}</option>
                    ))}
                </select>

            </form>
        </>

    );
};

