import { MDBBtn, MDBContainer, MDBDatatable, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Location from "../../../classes/firebase/Location/Location";
import DualColumnLayout from "../Layouts/DualColumnLayout";
import Dorms from "./tabs/Dorms";
import Seasons from "./tabs/Seasons";
import Summary from "./tabs/Summary";
import User from "../../../classes/firebase/User/User";

export default function AdminLocations() {
    const [locations, setLocations] = useState([])
    const [users, setUsers] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null)

    const [activeTab, setActiveTab] = useState(null)

    useEffect(() => {
        (async () => Promise.all([Location.getCollection(), User.getCollection()])
            ?.then(([locations, users]) => {
                setLocations(locations)
                return setUsers(users)
            })
        )()
    }, [])

    useEffect(() => setActiveTab(selectedLocation ? (activeTab ? activeTab : 'Summary') : null), [selectedLocation])


    const updateLocation = async (e, newLocation) => {
        e.preventDefault()
        await newLocation.submit(e)
        setLocations(locations => {
            const copy = [...locations]
            const idx = [...copy]?.map(l => l?.id)?.indexOf(newLocation.id)
            copy[idx === -1 ? copy.length : idx] = newLocation
            return copy
        })
        return setSelectedLocation(newLocation)
    }

    return (
        <div>
            <div className="px-md-3 px-lg-4 bg-white p-2 border mb-2 d-flex justify-content-between align-items-center">
                <MDBTypography className='display-6 mb-0'>
                    <span>Locations</span>
                    {selectedLocation &&
                        <span>: {selectedLocation?.name}</span>
                    }
                </MDBTypography>
                {selectedLocation &&
                    <MDBBtn className="btn-close" color="none" aria-label="Close" onClick={() => setSelectedLocation(null)} />

                }
            </div>
            <div className={selectedLocation ? 'd-none' : ''}>
                <MDBContainer className='border rounded-3 bg-white py-3' >
                    <MDBDatatable
                        search
                        entries={10}
                        sm
                        hover
                        data={{
                            columns: [
                                { label: 'Name', field: 'name' }
                            ],
                            rows: [...locations]?.map((location, idx) => {
                                return {
                                    name: location?.name,
                                    id: location?.id,
                                }
                            })
                        }}
                        onRowClick={(row) => {
                            const idx = [...locations]?.map(d => d?.id)?.indexOf(row?.id)
                            setSelectedLocation(locations[idx])
                        }}
                    />
                </MDBContainer>
            </div>
            {selectedLocation &&
                <DualColumnLayout
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    leftCard={null}
                    tabs={[
                        {
                            title: 'Summary',
                            component: <Summary
                                location={selectedLocation}
                                updateLocation={updateLocation}
                            />
                        },
                        {
                            title: 'Seasons',
                            component: <Seasons
                                location={selectedLocation}
                                setLocation={setSelectedLocation}
                                users={users}
                            />
                        },
                        {
                            title: 'Dorms',
                            component: <Dorms
                                location={selectedLocation}
                                setLocation={setSelectedLocation}
                            />
                        }
                    ]}

                />}
        </div>
    )


}

