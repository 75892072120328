import _ from 'lodash'
import { MDBBadge, MDBBtn, MDBCollapse, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import Dorm from '../../../../classes/firebase/Dorm/Dorm'
import AdminDormEditForm from '../../../../classes/firebase/Dorm/forms/AdminDormEditForm'
export default function Dorms(props) {
    const { location, setLocation, active } = props


    const [activeDorm, setActiveDorm] = useState(null)

    useEffect(() => setActiveDorm(null), [active])

    const remove = async (e, dorm) => {
        e.preventDefault()
        await Dorm.delete(dorm)
        setLocation(location => {
            const newLocation = _.cloneDeep(location)
            const newDorms = [...newLocation?.dorms]
            const idx = [...newLocation?.dorms]?.findIndex((i) => i?.id === dorm?.id)
            if (idx !== -1) {
                newDorms.splice(idx, 1)
            }
            newLocation.dorms = newDorms
            return newLocation
        })
        return setActiveDorm(null)
    }

    const save = async (e, dorm) => {
        e.preventDefault()
        await dorm.submit(e)
        setLocation(location => {
            const newLocation = _.cloneDeep(location)
            const newDorms = [...newLocation?.dorms]
            const idx = [...newLocation?.dorms]?.findIndex((i) => i?.id === dorm?.id)
            idx === -1 ? newDorms.push(dorm) : newDorms[idx] = dorm
            newLocation.dorms = newDorms
            return newLocation
        })
        return setActiveDorm(null)
    }

    const cancel = () => setActiveDorm(null)

    return (
        <div>
            <MDBCollapse show={!activeDorm}>
                <MDBTable align='middle' className="border">
                    <MDBTableHead>
                        <tr>
                            <th scope='col' className="text-start">
                                Dorms
                            </th>
                            <th scope='col' className="text-end">
                                <MDBBtn
                                    size="sm"
                                    color='link'
                                    onClick={async () => setActiveDorm(await Dorm.init({ location: location }))}>
                                    New
                                </MDBBtn>
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody className="bg-white">
                        {[...location?.dorms]?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((dorm, idx) => (
                            <tr key={`location-Dorms-${idx}`}>
                                <td className="text-start">
                                    <div className='d-flex align-items-center ms-1'>
                                        <div className=''>
                                            <p className=' mb-1'>{dorm?.name}</p>
                                            <MDBBadge color={dorm?.active ? 'success' : 'danger'} light>
                                                {dorm?.active ? 'Active' : 'Inactive'}
                                            </MDBBadge>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-end">
                                    <MDBBtn
                                        size="sm"
                                        color='link'
                                        onClick={() => setActiveDorm(dorm)}>
                                        Edit
                                    </MDBBtn>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCollapse>
            <MDBCollapse show={activeDorm} >
                <AdminDormEditForm
                    dorm={activeDorm}
                    setDorm={setActiveDorm}
                    save={save}
                    cancel={cancel}
                    remove={remove}
                />
            </MDBCollapse>
        </div>
    )
}
