import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit'
import ContactForm from '../../../local/Contact/forms/ContactForm'
export default function OrderContactForm({ initialState, submit }) {

    const [contact, setContact] = useState(null)

    useEffect(() => {
        if (initialState) {
            return setContact(_.cloneDeep(initialState))
        }
        return setContact(null)
    }, [initialState])


    return (
        <form onSubmit={async (e) => await submit(e, contact)}>
            <p className='text-muted mb-3'>{contact?.index === 0 ? 'Student' : 'Parent or Relative'} Contact Info</p>
            <ContactForm contact={contact} setContact={setContact} />
            <MDBRow>
                <MDBCol className="d-flex justify-content-start">
                    <MDBBtn type="submit">Save</MDBBtn>
                </MDBCol>
                {contact?.index > 1 &&
                    <MDBCol className="d-flex justify-content-end">
                        <MDBBtn type="submit" color='danger' id='removeButton'>Remove</MDBBtn>
                    </MDBCol>
                }
            </MDBRow>
        </form>
    )
}
