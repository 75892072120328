import { MDBCheckbox, MDBInput, MDBSelect, MDBTable, MDBTableBody } from "mdb-react-ui-kit";
import { useEffect, useState } from 'react';
import OrderLocationSelect from "../../../../classes/firebase/Location/forms/OrderLocationSelect";
import OrderSeasonSelect from "../../../../classes/firebase/Season/forms/OrderSeasonSelect";
import ContactForm from "../../../../classes/local/Contact/forms/ContactForm";
import FormCard from "../../Layouts/FormCard";

export default function Summary(props) {
    const { order, updateOrder, active, locations, users } = props
    useEffect(() => setEditedOrder(order), [order])

    const [editedOrder, setEditedOrder] = useState(null)


    const reset = () => setEditedOrder(order)

    const submit = async (e) => {
        e.preventDefault()
        return await updateOrder(e, editedOrder)
    }

    return (
        <div>
            <FormCard
                title='General'
                formView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        ID
                                    </td>
                                    <td>
                                        {editedOrder?.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Number
                                    </td>
                                    <td>
                                        <MDBInput
                                            value={editedOrder?.number || ''}
                                            onChange={(e) => setEditedOrder(editedOrder.onChange('number', e.target.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        User
                                    </td>
                                    <td>
                                        <MDBSelect
                                            preventFirstSelection
                                            label='User'
                                            search
                                            data={users && [...users]?.sort((a, b) => (a?.name || '')?.localeCompare(b?.name || ''))
                                                ?.map(user => (
                                                    { text: user?.name || '', value: user, defaultSelected: order?.user?.id === user?.id }
                                                ))}
                                            onValueChange={(e) => setEditedOrder(editedOrder.onChange('user', e.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Status
                                    </td>
                                    <td>
                                        <MDBSelect
                                            preventFirstSelection
                                            label=''
                                            data={['In Progress', 'Completed', 'Cancelled']
                                                ?.map(status => (
                                                    { text: status, value: status, defaultSelected: editedOrder?.status === status }
                                                ))}
                                            onValueChange={(e) => setEditedOrder(editedOrder.onChange('status', e.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Campus
                                    </td>
                                    <td>
                                        <OrderLocationSelect order={editedOrder} setOrder={setEditedOrder} locations={locations} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Season
                                    </td>
                                    <td>
                                        <OrderSeasonSelect order={editedOrder} setOrder={setEditedOrder} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Boxes
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <MDBCheckbox
                                                checked={editedOrder?.boxes?.requested}
                                                label='Requested'
                                                onChange={(e) => setEditedOrder(editedOrder.onChange('boxes.requested', e.target.checked))}
                                            />
                                            <MDBInput
                                                value={editedOrder?.boxes?.numberRequested || ''}
                                                onChange={(e) => setEditedOrder(editedOrder.onChange('boxes.numberRequested', parseInt(e.target.value)))}
                                                type='number'
                                                label='Number Requested'
                                                size='sm'
                                            />
                                        </div>
                                        <MDBCheckbox
                                            checked={editedOrder?.boxes?.delivered}
                                            label='Delivered'
                                            onChange={(e) => setEditedOrder(editedOrder.onChange('boxes.delivered', e.target.checked))}
                                        />
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                displayView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        ID
                                    </td>
                                    <td>
                                        {editedOrder?.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Number
                                    </td>
                                    <td>
                                        {order?.number}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        User
                                    </td>
                                    <td>
                                        <p className="mb-0">{order?.user?.name}</p>
                                        <p className="mb-0">{order?.user?.email}</p>
                                        <p className="mb-0">{order?.user?.phone}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Status
                                    </td>
                                    <td>
                                        {order?.status}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Campus
                                    </td>
                                    <td>
                                        {order?.location?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Season
                                    </td>
                                    <td>
                                        {order?.season?.name} {order?.season?.year}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Boxes
                                    </td>
                                    <td>
                                        {order?.boxes?.requested ?
                                            <>
                                                <p className="m-0">Requested: {order?.boxes?.numberRequested}</p>
                                                <p className="m-0">Delivered: {order?.boxes?.delivered ? 'Yes' : 'No'}</p>
                                            </>
                                            :
                                            'None Requested'}
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                submit={submit}
                reset={reset}
                active={active}
            />
            <FormCard
                title='Contact'
                formView={
                    <>
                        {editedOrder?.contact?.map((contact, idx) => (
                            <div
                                className={`d-flex align-items-center mb-2 pb-2 border-bottom border-2 ${idx > 0 ? 'pt-2' : ''}`}
                                key={`orderContact${idx}- Form`}>
                                <ContactForm contact={contact} setContact={(contact) => setEditedOrder(editedOrder.onChange(`contact[${idx}]`, contact))} />
                            </div>
                        ))}
                    </>
                }
                displayView={
                    <>
                        {order?.contact?.map((contact, idx) => (
                            <div
                                className='d-flex align-items-center mb-2 pb-2 border-bottom border-2'
                                key={`orderContact${idx}-Display`}>
                                <div>
                                    <p className='fw-bold mb-1'>{contact?.fullName} ({contact?.type})</p>
                                    <p className="mb-0">{contact?.address?.toString()}</p>
                                    <p className='text-muted mb-0'>{contact?.email}</p>
                                    <p className='text-muted mb-0'>{contact?.phone}</p>
                                </div>
                            </div>
                        ))}
                    </>
                }
                submit={submit}
                reset={reset}
                active={active}
            />


        </div>
    )
}
