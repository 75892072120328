import { httpsCallable } from 'firebase/functions'
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit'
import React from 'react'
import { functions } from '../../../firebase/firebaseConfig'

export default function Settings() {
    return (
        <MDBContainer>
            <div className='p-3'>
                <div className=''>
                    <h6 className='border-bottom'>STRIPE</h6>
                    {[
                        {
                            title: 'Invoices',
                            key: 'invoices'
                        },
                        {
                            title: 'Invoice Items',
                            key: 'invoiceItems'
                        },
                        {
                            title: 'Subscription Items',
                            key: 'subscriptionItems'
                        }
                    ].map((row, idx) => (
                        <div key={`stripe-${idx}`}
                            className='d-flex justify-content-between align-items-center mb-3'
                            style={{ maxWidth: '40rem' }}>
                            <p className='mb-0'>{row.title}</p>
                            <MDBBtn
                                onClick={async (e) => {
                                    e.preventDefault()
                                    const func = httpsCallable(functions, `api-http-stripe-requests-sync-${row.key}`)
                                    return await func()
                                }}>
                                Sync
                            </MDBBtn>
                        </div>
                    ))}

                </div>
            </div>
        </MDBContainer>
    )
}
