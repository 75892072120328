import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'

export default function Home() {
  return (
    <MDBContainer>
      <HomeSplashImage />
      <HomePageHowItWorks />
    </MDBContainer>
  )
}

const HomeSplashImage = () => {
  return (
    <div className='p-5 text-center bg-image'

      style={{ top: 0, left: 0, backgroundImage: "url('https://cdn.shopify.com/s/files/1/0257/1475/3620/files/Main-Street-in-Downtown-Lexington_ChrisWeisler-1280x640.jpg?v=1620959987')", height: 500 }}
    >
      <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <div className='d-flex justify-content-center align-items-center pb-5 h-100'>
          <div className='text-white text-center'>
            <h1 className='mb-3'>Full Service Storage For Students</h1>
            <a className='btn btn-outline-light btn-lg' href='/authenticate' role='button' >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const HomePageHowItWorks = () => {
  return (
    <>
      <MDBRow center className='my-3'>
        <MDBTypography className='display-6 justify-content-center d-flex align-items-center'>
          How We Make Storage Easy
        </MDBTypography>
      </MDBRow>
      <MDBRow center>
        <MDBCol xs='7' sm='8' md='5' xl='3' className='my-2'>
          <MDBCard className='h-100'>
            <MDBCardImage position='top' src='https://cdn.shopify.com/s/files/1/0257/1475/3620/files/best-laptops-for-web-browsing_540x.jpg?v=1616194383' alt='...' />
            <MDBCardBody>
              <MDBCardTitle>
                You Pick the Items You Want to Store
              </MDBCardTitle>
              <MDBCardText>
                Use our website to pick the boxes or items you want, or reach out to us for unwieldy items, and set up a pickup date.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm={8} md={5} xl={3} className='my-2'>
          <MDBCard className='h-100'>
            <MDBCardImage position='top' src='https://cdn.shopify.com/s/files/1/0257/1475/3620/files/using-a-tape-dispenser-to-close-up-a-box_540x.jpg?v=1567463054' alt='...' />
            <MDBCardBody>
              <MDBCardTitle>
                We Dropoff Boxes
              </MDBCardTitle>
              <MDBCardText>
                We provide free large cardboard boxes and deliver them to your doorstep!
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm={8} md={5} xl={3} className='my-2'>
          <MDBCard className='h-100'>
            <MDBCardImage position='top' src='https://cdn.shopify.com/s/files/1/0257/1475/3620/files/shipping-boxes-on-trolly-close-up_540x.jpg?v=1567463160' alt='...' />
            <MDBCardBody>
              <MDBCardTitle>
                We Pickup & Store Your Items
              </MDBCardTitle>
              <MDBCardText>
                We pickup your belongings directly from your residence and store them in our air conditioned facility.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm={8} md={5} xl={3} className='my-2'>
          <MDBCard className='h-100'>
            <MDBCardImage position='top' src='https://cdn.shopify.com/s/files/1/0257/1475/3620/files/receiving-shipping-box-on-ipad_540x.jpg?v=1567463302' alt='...' />
            <MDBCardBody>
              <MDBCardTitle>
                We Return Your Items When You Return to Campus
              </MDBCardTitle>
              <MDBCardText>
                We return your belongings directly to your new housing.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  )
}