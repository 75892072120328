import { MDBCheckbox, MDBInput, MDBInputGroup, MDBSelect, MDBTable, MDBTableBody } from "mdb-react-ui-kit";
import { useEffect, useState } from 'react';
import OrderLocationSelect from "../../../../classes/firebase/Location/forms/OrderLocationSelect";
import OrderSeasonSelect from "../../../../classes/firebase/Season/forms/OrderSeasonSelect";
import FormCard from "../../Layouts/FormCard";
import ContactForm from "../../../../classes/local/Contact/forms/ContactForm";
import { formatPhoneNumber } from "../../../../utils/formatting/formattingUtils";

export default function Details(props) {
    const { user, updateUser, active } = props

    useEffect(() => setEditedUser(user), [user])

    const [editedUser, setEditedUser] = useState(null)


    const reset = () => setEditedUser(user)

    const submit = async (e) => {
        e.preventDefault()
        return await updateUser(e, editedUser)
    }


    return (
        <div>
            <FormCard
                title='General'
                formView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        <MDBInputGroup>
                                            <input
                                                className='form-control'
                                                type='text'
                                                name='first'
                                                value={editedUser?.first || ''}
                                                onChange={(e) => setEditedUser(editedUser?.onChange(e.target.name, e.target.value))}
                                            />
                                            <input
                                                className='form-control'
                                                type='text'
                                                name='last'
                                                value={editedUser?.last || ''}
                                                onChange={(e) => setEditedUser(editedUser?.onChange(e.target.name, e.target.value))}
                                            />
                                        </MDBInputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Email
                                    </td>
                                    <td>
                                        <MDBInput
                                            name='email'
                                            value={editedUser?.email || ''}
                                            onChange={(e) => setEditedUser(editedUser?.onChange(e.target.name, e.target.value))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Phone
                                    </td>
                                    <td>
                                        <MDBInput
                                            name='phone'
                                            value={editedUser?.phone || ''}
                                            onChange={(e) => setEditedUser(editedUser?.onChange(e.target.name, formatPhoneNumber(e.target.value)))}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Role
                                    </td>
                                    <td>
                                        <MDBSelect
                                            preventFirstSelection
                                            label=''
                                            data={['customer', 'admin']
                                                ?.map(role => (
                                                    { text: role, value: role, defaultSelected: editedUser?.role === role }
                                                ))}
                                            onValueChange={(e) => setEditedUser(editedUser.onChange('role', e.value))}
                                        />
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                displayView={
                    <>
                        <MDBTable align="middle">
                            <MDBTableBody>
                                <tr>
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        {user?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Email
                                    </td>
                                    <td>
                                        {user?.email}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Phone
                                    </td>
                                    <td>
                                        {user?.phone}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Role
                                    </td>
                                    <td>
                                        {user?.role}
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </>
                }
                submit={submit}
                reset={reset}
                active={active}
            />

        </div>
    )
}
