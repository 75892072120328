import * as fb from 'firebase/firestore';
import { db } from '../../../firebase/firebaseConfig';


export default class Coupon {
    static collectionName = 'coupons'
    static get collection() {
        return fb.collection(db, this.collectionName)
    }
    static async getAll() {
        return await fb.getDocs(this.collection).then((snapshot) => snapshot.docs.map(doc => doc.data()))
    }
}