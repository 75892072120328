import _ from 'lodash'
import Contact from '../../local/Contact/Contact'
import Model from '../../Model'
import Dorm from '../Dorm/Dorm'
import Item from '../Item/Item'
import Season from '../Season/Season'
import Location from '../Location/Location'
import * as fb from "firebase/firestore";
import { MDBSelect } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { cleanSelectData, GenerateRandomNumber } from '../../../utils/formatting/formattingUtils'
import User from '../User/User'
import { db } from '../../../firebase/firebaseConfig'


export default class Order extends Model {
    constructor(data) {
        super(data)
        this.number = data.number
        this.cancelled = data.cancelled
        this.season = data.season
        this.user = data.user
        this.location = data.location
        this.contact = data.contact
        this.pickup = data.pickup
        this.dropoff = data.dropoff
        this.boxes = data.boxes
        this.billing = data.billing
        this.complete = data.complete
        this.status = data.status
        this.adminComments = data.adminComments

        this.items = data.items
    }
    static collectionName = 'orders'



    static async init(data) {
        const [location, user, items] = await Promise.all(
            [data?.location ? Location.get(data?.location) : null,
            data?.user ? User.get(data?.user) : null,
            data?.ref ? Item.query('order', data?.ref) : []
            ])
        //const location = data?.location ? await Location.get(data?.location) : null
        const season = location ? [...location?.seasons]?.find(s => s?.id === data?.season?.id) : null
        //const user = data?.user ? await User.get(data?.user) : null
        const pickupDorm = data?.pickup?.dorm ? data?.pickup?.dorm?.id ? location ? [...location?.dorms]?.find(s => s?.id === data?.pickup?.dorm?.id) : null : data?.pickup?.dorm : null
        const dropoffDorm = data?.dropoff?.dorm ? data?.dropoff?.dorm?.id ? location ? [...location?.dorms]?.find(s => s?.id === data?.dropoff?.dorm?.id) : null : data?.dropoff?.dorm : null
        //const items = data?.ref ? await Item.query('order', data?.ref) : []
        const d =
        {
            number: data?.number || `${data?.location?.reservationPrefix || 'XXX'}-${GenerateRandomNumber(6)}`,
            cancelled: data?.cancelled || false,
            location: location || null,
            season: season || null,
            user: user || null,
            items: items,
            contact: this.calculateContactField(data?.contact),
            pickup: {
                date: {
                    date: data?.pickup?.date?.date || null,
                    time: data?.pickup?.date?.time || null,
                    isCustom: data?.pickup?.date?.isCustom || false
                },
                dorm: pickupDorm,
                buildingNumber: data?.pickup?.buildingNumber || null,
                room: data?.pickup?.room || null,
                complete: data?.pickup?.complete || false,
                notes: data?.pickup?.notes || null
            },
            dropoff: {
                date: {
                    date: data?.dropoff?.date?.date || null,
                    time: data?.dropoff?.date?.time || null,
                    isCustom: data?.dropoff?.date?.isCustom || false
                },
                dorm: dropoffDorm,
                buildingNumber: data?.dropoff?.buildingNumber || null,
                room: data?.dropoff?.room || null,
                complete: data?.dropoff?.complete || false,
                notes: data?.dropoff?.notes || null
            },
            boxes: {
                requested: data?.boxes?.requested || false,
                numberRequested: data?.boxes?.numberRequested || 0,
                delivered: data?.boxes?.delivered || false,
                trackingNumber: data?.boxes?.trackingNumber || null
            },
            billing: {
                subscription: null,
                hasPaymentMethod: data?.billing?.hasPaymentMethod || false,
                discount: data?.billing?.discount || null
            },
            complete: data?.complete || false,
            status: data?.status || 'In Progress',
            adminComments: Array.isArray(data?.adminComments) ? data?.adminComments : []
        }
        return new Order(d)
    }

    static async getByNumber(number) {
        const req = await Order.query('number', number)
        const o = [...req]?.[0]
        return o
    }

    async getSubscription() {
        const doc = await fb.getDoc(fb.doc(db, 'subscriptions', this.id))
        if (doc.exists()) {
            return this.billing.subscription = doc.data()
        }
        return this.billing.subscription = null
    }

    static transformToFirestore(data) {
        const d = {
            ...data,
            location: Location.setReferenceField(data?.location) || null,
            season: Season.setReferenceField(data?.season) || null,
            user: User.setReferenceField(data?.user) || null,
            pickup: {
                ...data.pickup,
                dorm: Dorm.setReferenceField(data?.pickup?.dorm)
            },
            dropoff: {
                ...data.dropoff,
                dorm: Dorm.setReferenceField(data?.dropoff?.dorm)
            },
            contact: data.contact?.map((c) => Contact?.toObject(c)),
            number: data?.number || `${data?.location?.reservationPrefix || 'XXX'}-${GenerateRandomNumber(6)}`,
            billing: { hasPaymentMethod: data?.billing?.hasPaymentMethod }
        }
        const { items, ...filtered } = d
        return filtered
    }

    static classOnChange(object) {
        return object
        //Check number to ensure match with location
        if (object?.location?.reservationPrefix) {
            if (object?.number) {
                if (object?.number?.slice(0, object?.location?.reservationPrefix?.length) !==
                    object?.location?.reservationPrefix) {
                    object.number = `${object?.location?.reservationPrefix}${object?.number?.slice(object?.location?.reservationPrefix?.length)}`
                }
            } else {
                object.number = `${object?.location?.reservationPrefix || 'XXX'}-${GenerateRandomNumber(6)}`
            }
        }
        //check to make sure order location/season match
        if (object?.season && (!object?.location || (object?.location?.id !== object?.season?.location?.id))) {
            object.season = null
        }
        return object
        if (object?.items?.length) {
            const items = [...object?.items]?.map((item, idx) => {
                if (!item?.number || typeof item?.number !== 'string') {
                    let isValid = false
                    let i = 0
                    while (!isValid && i < 10) {
                        const num = `${object?.number}-${GenerateRandomNumber(3)}`
                        if (![...object?.items]?.map(itm => itm?.number)?.includes(num)) {
                            item.number = num
                            isValid = true
                        }
                        i++
                    }
                }
                return item
            })
            object.items = items
        }

        return object
    }


    static calculateContactField(data) {
        const arr = []
        if (data?.length === 0 || !Array.isArray(data)) {
            arr.push(Contact.init({ type: 'Student', isDefault: true, index: 0 }))
            arr.push(Contact.init({ type: 'Parent', index: 1 }))
        }
        else {
            data.forEach((c, idx) => {
                arr.push(Contact.init({ ...c, index: c?.index ? c?.index : idx }))
            })

        }
        return arr
    }

    static dormSelect = (order, setOrder, mode, dorms = null) => {
        const [options, setOptions] = useState([])
        useEffect(() => {
            (async () => {
                setOptions([...order?.location?.dorms])
            }
            )()
        }, [])

        return (
            <MDBSelect
                preventFirstSelection
                label='Dorm'
                data={cleanSelectData(options && [...options].concat([Dorm.otherDorm({ location: order?.location || null })]))?.map((d, idx) => (
                    {
                        text: d?.name,
                        value: d,
                        defaultSelected: order?.[mode]?.dorm?.name === 'Other' ?
                            order?.[mode]?.dorm?.name === d?.name
                            :
                            (d?.id && order?.[mode]?.dorm?.id === d?.id),
                        name: `${mode}.dorm`
                    }
                ))}
                className='mb-3'
                onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
            />
        )
    }

    static locationSelect = (order, setOrder, locations) => {
        const [options, setOptions] = useState([])
        useEffect(() => {
            if (locations) {
                setOptions(locations)
            }
            else {
                (async () => {
                    const q = fb.query(Location.collection, fb.where('active', '==', true))
                    const req = await fb.getDocs(q)
                    const locations = await Promise.all(req?.docs?.map(async (doc) => {
                        return doc.data()
                    }))
                    setOptions(locations)
                })()
            }
        }, [])

        return (
            <MDBSelect
                preventFirstSelection
                style={{ width: '300px' }}
                label='Campus'
                data={cleanSelectData(options && [...options]
                    ?.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
                    ?.map((i) => (
                        { text: `${i.name || ''}`, value: i, defaultSelected: order?.location?.id === i?.id }
                    )))}
                onValueChange={(e) => setOrder(new Order({ ...order, location: e.value }))}
            />
        )
    }

    static seasonSelect = (order, setOrder, seasons) => {
        const [options, setOptions] = useState([])
        const [refreshing, setRefreshing] = useState(false)

        useEffect(() => {
            if (seasons) {
                setOptions(seasons)
            }
            else {
                (async () => {
                    const q = fb.query(Season.collection, fb.where('active', '==', true))
                    const req = await fb.getDocs(q)
                    const seasons = await Promise.all(req?.docs?.map(async (doc) => await doc.data()))
                    setOptions(seasons)
                })()
            }
        }, [])
        useEffect(() => {
            if (!order?.location || (order?.location?.id !== order?.season?.location?.id)) {
                setOrder(order.onChange('season', null))
                setRefreshing(true)
            }
        }, [order?.location])

        useEffect(() => {
            setRefreshing(false)
        }, [refreshing])

        return (
            <>
                {refreshing ?
                    <MDBSelect
                        style={{ width: '300px' }}
                        label='Storage Season'
                        data={cleanSelectData([])}
                        key={1}
                        disabled
                    />
                    :
                    <MDBSelect
                        style={{ width: '300px' }}
                        preventFirstSelection
                        label='Storage Season'
                        data={cleanSelectData(options && [...options]
                            ?.filter((s) => s?.location?.id === order?.location?.id)?.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
                            ?.map((i) => (
                                { text: `${i.name || ''}`, value: i, defaultSelected: order?.season?.id === i?.id, name: 'season' }
                            )))}
                        disabled={!order?.location?.id}
                        onValueChange={(e) => setOrder(order.onChange(e.name, e.value))}
                        key={2}
                    />
                }
            </>
        )
    }

    static createSubscription = async (e, order) => {
        e.preventDefault()
        const ref = fb.doc('subscriptions', order.id)
        const stripeCustomer = await fb.getDoc(fb.doc('stripeCustomers', order?.user?.id)).then((doc) => doc.data())
        return
        return await ref.set({
            customer: stripeCustomer.id,
            phases: [
                {

                    items: []
                }
            ]
        })
    }


}