import * as fb from 'firebase/firestore';

export default class requests {

    static async get(id, collection = this.collection, options = {}) {
        if (options) {
            Object?.keys(options)?.forEach((key) => this[key] = options[key])
        }
        const ref = fb.doc(collection, id?.id ? id?.id : id)

        try {
            const doc = await fb.getDocFromCache(ref)
            if (doc.exists) {
                return doc.data()
            }
        }
        catch { }

        try {
            const doc = await fb.getDoc(ref)
            if (doc.exists) {
                return doc.data()
            }
            else {
                return null
            }
        }
        catch (e) {
            return null
        }
    }
    static async query(field, value, collection = this.collection, options = {}) {
        const q = fb.query(collection, fb.where(field, '==', value))
        const req = await fb.getDocs(q)
        return await Promise.all(req?.docs?.map(async (doc) => {
            const data = await doc.data()
            if (options) {
                Object?.keys(options)?.forEach((key) => data[key] = options[key])
            }
            return data
        }))
    }

    static async getReferenceField(instance, field, options = {}) {
        if (!field || !instance) {
            return null
        }
        else if (field instanceof instance) {
            return field
        }
        else {
            return await this.get.call(instance, field, this.collection, options)

        }
    }

    static async referenceFieldQuery(instance, field, ref, options = {}) {
        if (!field || !ref || !instance) {
            return null
        }
        else {
            const a1 = await this.query.call(instance, field, ref, this.collection, options)
            const a2 = await this.query.call(instance, `${field}.id`, ref?.id, this.collection, options)
            return a1.concat(a2)

        }
    }

    static async set(e, instance) {
        e.preventDefault()
        if (!instance) {
            return null
        }
        const object = await instance.transformToFirestoreAsync(instance)
        const { ref, ...data } = object
        return await fb.setDoc(ref, data)
    }


}