import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import React from 'react'
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom"
import { AdminProvider } from './contexts/AdminContext'
import { AuthProvider } from './contexts/AuthContext'
import Footer from './navigation/Footer'
import NavigationBar from './navigation/NavigationBar'
import AdminRoute from './navigation/routes/AdminRoute'
import PrivateRoute from './navigation/routes/AuthRoute'
import UnauthRoute from './navigation/routes/UnauthRoute'

import AdminCalendar from './pages/Admin/AdminCalendar/AdminCalendar'
import AdminItems from './pages/Admin/AdminItems/AdminItems'
import AdminLocations from './pages/Admin/AdminLocations/AdminLocations'
import AdminOrders from './pages/Admin/AdminOrders/AdminOrders'
import AdminPricing from './pages/Admin/AdminPricing/AdminPricing'


import { OrderWrapper } from './contexts/OrderContext'
import AdminNavigationBar from './navigation/AdminNavigationBar'
import AdminUsers from './pages/Admin/AdminUsers/AdminUsers'
import Billing from './pages/Admin/Billing/Billing'
import ChangeLog from './pages/Admin/ChangeLog/ChangeLog'
import AdminDashboard from './pages/Admin/Dashboard/Dashboard'
import Orders from './pages/Admin/Orders'
import Sandbox from './pages/Admin/Sandbox'
import Settings from './pages/Admin/Settings/Settings'
import Account from './pages/Auth/Account/Account'
import CreateOrder from './pages/Auth/CreateOrder/CreateOrder'
import OrderPage from './pages/Auth/Order/OrderPage'
import About from './pages/Unauth/About'
import Authenticate from './pages/Unauth/Authenticate'
import Contact from './pages/Unauth/Contact'
import FAQ from './pages/Unauth/FAQ'
import Home from './pages/Unauth/Home'
import PasswordReset from './pages/Unauth/PasswordReset'
import Pricing from './pages/Unauth/Pricing'
import PrivacyPolicy from './pages/Unauth/PrivacyPolicy'
import TermsAndConditions from './pages/Unauth/TermsAndConditions'



export default function App() {


    return (
        <Router>
            <AppRoutes />
        </Router>
    )
}

function AppRoutes() {
    const location = useLocation()
    return (
        <AuthProvider>
            <NavigationBar />
            <div
                style={{
                    paddingTop: location.pathname.toLowerCase().includes('/admin') ? '0px' : `95px`,
                    minHeight: window.innerHeight
                }}
            >
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='pricing' element={<Pricing />} />
                    <Route path='privacypolicy' element={<PrivacyPolicy />} />
                    <Route path='terms' element={<TermsAndConditions />} />
                    <Route path='about' element={<About />} />
                    <Route path='faq' element={<FAQ />} />
                    <Route path='contact' element={<Contact />} />
                    <Route path='authenticate/*' element={
                        <UnauthRoute>
                            <Routes>
                                <Route path='/' element={<Authenticate />} />
                                <Route path='passwordReset' element={<PasswordReset />} />
                            </Routes>
                        </UnauthRoute>

                    } />
                    <Route path='account/*' element={
                        <PrivateRoute>
                            <Routes>
                                <Route path='/' element={<Account />} />
                                <Route path='/orders/new' element={<CreateOrder />} />
                                <Route path='/orders/:number' element={<OrderPage />} />
                            </Routes>
                        </PrivateRoute>
                    } />
                    <Route path='admin/*' element={
                        <AdminRoute>
                            <AdminProvider>
                                <AdminNavigationBar />
                                <Routes>
                                    <Route path='orders'>
                                        <Route path='' element={<AdminOrders />} />
                                        <Route path='v2' element={<Orders />} />
                                        <Route
                                            path='v2/:id'
                                            element={<OrderWrapper />} />
                                        <Route path=':id' element={<AdminOrders />} />
                                    </Route>
                                    <Route path='items'>
                                        <Route path='' element={<AdminItems />} />
                                        <Route path=':id' element={<AdminItems />} />
                                    </Route>
                                    <Route path='locations' element={<AdminLocations />} />
                                    <Route path='pricing' element={<AdminPricing />} />
                                    <Route path='calendar' element={<AdminCalendar />} />
                                    <Route path='/' element={<AdminDashboard />} />
                                    <Route path='changeLog' element={<ChangeLog />} />
                                    <Route path='users'>
                                        <Route path='' element={<AdminUsers />} />
                                        <Route path=':id' element={<AdminUsers />} />
                                    </Route>
                                    <Route path='billing' element={<Billing />} />
                                    <Route path='sandbox' element={<Sandbox />} />
                                    <Route path='settings' element={<Settings />} />
                                </Routes>
                            </AdminProvider>
                        </AdminRoute>
                    } />
                </Routes>
            </div>
            <Footer />
        </AuthProvider>
    )
}