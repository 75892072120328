import {
    MDBCollapse,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarNav,
    MDBNavbarToggler
} from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function AdminNavigationBar() {
    const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
    return (
        <>
            <MDBNavbar expand='lg' dark style={{ backgroundColor: '#2E81EC' }}>
                <MDBContainer fluid>
                    <MDBNavbarBrand className='ps-2'>VSS Admin</MDBNavbarBrand>
                    <MDBNavbarToggler
                        type='button'
                        data-target='#navbarTogglerDemo02'
                        aria-controls='navbarTogglerDemo02'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>
                    <MDBCollapse navbar show={showNavNoTogglerSecond} className='justify-content-center'>
                        <MDBNavbarNav className='mb-2 mb-lg-0' fullWidth={false} >
                            <MDBNavbarItem>
                                <NavLink to='/admin/'
                                    className={`nav-link`}>
                                    Dashboard
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/orders'
                                    className={`nav-link`}>
                                    Orders
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/items'
                                    className={`nav-link`}>
                                    Items
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/locations'
                                    className={`nav-link`}>
                                    Locations
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/pricing'
                                    className={`nav-link`}>
                                    Pricing
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/users'
                                    className={`nav-link`}>
                                    Users
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/changeLog'
                                    className={`nav-link`}>
                                    Change Log
                                </NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink to='/admin/settings'
                                    className={`nav-link`}>
                                    Settings
                                </NavLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>

                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </>
    );
}