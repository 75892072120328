import Model from "../../Model"
import StripeProduct from "../../stripe/stripeProduct"
import Price from "../Price/Price"

export default class Product extends Model {
    constructor(data) {
        super(data)
        this.active = data.active
        this.attributes = data.attributes
        this.created = data.created
        this.default_price = data.default_price
        this.description = data.description
        this.images = data.images
        this.livemode = data.livemode
        this.metadata = data.metadata
        this.name = data.name
        this.object = data.object
        this.package_dimensions = data.package_dimensions
        this.prices = data.prices
        this.shippable = data.shippable
        this.statement_descriptor = data.statement_descriptor
        this.tax_code = data.tax_code
        this.type = data.type
        this.unit_label = data.unit_label
        this.updated = data.updated
        this.url = data.url
    }

    static collectionName = 'pricing'

    static async init(data) {
        return new Product({
            active: data?.active || false,
            attributes: data?.attributes || [],
            created: data?.created || null,
            default_price: data?.default_price ? await Price.get(data?.default_price) : null,
            description: data?.description || null,
            images: data?.images || [],
            livemode: data?.livemode || false,
            metadata: {
                ...data?.metadata,
                sortOrder: parseInt(data?.metadata?.sortOrder) || data?.metadata?.sortOrder || null,
                category: data?.metadata?.category || ''
            },
            name: data?.name || null,
            object: data?.object || null,
            package_dimensions: data?.package_dimensions || null,
            prices: await Price.query('product', data.ref),
            shippable: data?.shippable || null,
            statement_descriptor: data?.statement_descriptor || null,
            tax_code: data?.tax_code || null,
            type: data?.type || null,
            unit_label: data?.unit_label || null,
            updated: data?.updated || null,
            url: data?.url || null
        })
    }

    static async getActiveProducts() {
        return await Product.query('active', true)
    }

    static transformToFirestore(data) {
        const d = {
            ...data,
            default_price: data?.default_price?.ref ? data?.default_price?.ref : null
        }
        const { prices, ...filtered } = d
        return filtered
    }

    static get defaultProductImage() {
        return require('../../../assets/siteImages/itemPlaceHolder.png')
    }

}