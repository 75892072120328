import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import _ from 'lodash'
export default function DualColumnLayout(props) {
    const { leftCard, tabs, activeTab, setActiveTab } = props

    const handleTab = (v) => {
        if (v === activeTab) {
            return
        }
        return setActiveTab(v)
    }

    useEffect(() => {
        if (!activeTab && tabs?.length) {
            return setActiveTab(tabs[0]?.title)
        }

    }, [activeTab, tabs])

    return (
        <MDBContainer fluid className="pt-2">
            <MDBRow>
                <MDBCol md="5" lg="3" xl="3" className="px-0 px-md-2">
                    {leftCard &&
                        <MDBCard className="rounded-0 mb-2 mb-lg-4">
                            <MDBCardHeader>
                                {leftCard?.title}
                            </MDBCardHeader>
                            <MDBCardBody>
                                {_.get(leftCard, 'subTitle') && <p className="mb-1">{leftCard?.subTitle}</p>}
                                {_.get(leftCard, 'text') && <p className="mb-0">{leftCard?.text}</p>}
                                {_.get(leftCard, 'body') && leftCard?.body}
                            </MDBCardBody>
                        </MDBCard>
                    }
                    {tabs && _.size(tabs) > 1 &&
                        <MDBTabs pills className='flex-column text-center mb-3 mx-auto'>
                            {tabs && [...tabs]?.map((tab, idx) => (
                                <MDBTabsItem key={`leftTabNavigation-${idx}`}>
                                    <MDBTabsLink onClick={() => handleTab(tab?.title)} active={activeTab === tab?.title} className={`my-0 rounded-0 border ${activeTab !== tab?.title && 'bg-white'}`}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            {tab?.title} {tab?.badge && tab?.badge}
                                        </div>
                                    </MDBTabsLink>
                                </MDBTabsItem>
                            ))}
                        </MDBTabs>
                    }
                </MDBCol>
                <MDBCol md="7" lg="8" xl="8" className="mb-4 mb-md-0 px-0 px-md-2">
                    <MDBTabsContent>
                        {tabs && [...tabs]?.map((tab, idx) => (
                            <MDBTabsPane show={activeTab === tab?.title} key={`tabsPane-${idx}`}>
                                {React.cloneElement(tab?.component, { active: activeTab === tab?.title })}
                            </MDBTabsPane>
                        ))}
                    </MDBTabsContent>
                </MDBCol>

            </MDBRow>
        </MDBContainer>
    )
}


