import _ from 'lodash'
import { MDBBtn, MDBCard, MDBCardBody, MDBInput, MDBListGroupItem } from "mdb-react-ui-kit"
import Product from "../../Product/Product"
export default function ProductListCard({ product }) {
    return (
        <MDBCard>
            <MDBCardBody>
                <div className='d-flex align-items-center'>
                    <img
                        src={(product?.images?.[0] ? product?.images[0] : Product.defaultProductImage)}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                    />
                    <div className='d-flex align-items-center'>
                        <div className='ms-5'>
                            <p className='fw-bold mb-1'>{product?.name}</p>
                            <p className='text-muted mb-1'>{product?.description}</p>
                            <p className='text-muted mb-0'>{product?.default_price?.amount}</p>
                        </div>
                    </div>
                </div>

            </MDBCardBody>
        </MDBCard>
    )
}
