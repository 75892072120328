import { MDBBtn, MDBFile, MDBIcon } from 'mdb-react-ui-kit'
import React from 'react'

export default function AddImageButton({ callback }) {
    return (
        <>
            <MDBFile accept="image/*"
                style={{ display: 'none' }}
                id="imageAdder"

                onChange={(e) => {
                    const f = e.target.files[0]
                    if (f) {
                        callback(f)
                    }
                    e.target.value = null
                }}
            />
            <label htmlFor="imageAdder"  >
                <MDBBtn tag='a' color='none' size='xxl'
                    style={{
                        color: 'grey',
                        width: '78px',
                        height: '78px',
                        marginRight: '3px',
                        marginLeft: '3px'
                    }}
                    type='button'>

                    <div className="d-flex align-items-center justify-content-center h-100">
                        <MDBIcon fas icon="plus" size='lg' />
                    </div>
                </MDBBtn>
            </label>
        </>
    )
}
