import { collection, onSnapshot } from "firebase/firestore";
import _ from 'lodash';
import { MDBBtn, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Item from "../../../classes/firebase/Item/Item";
import AdminItemDetail from "../../../classes/firebase/Item/views/AdminItemDetail";
import AdminItemsTable from "../../../classes/firebase/Item/views/AdminItemsTable";
import Order from "../../../classes/firebase/Order/Order";
import Product from "../../../classes/firebase/Product/Product";
import User from "../../../classes/firebase/User/User";
import { db } from "../../../firebase/firebaseConfig";
export default function AdminItems() {
    const [items, setItems] = useState([])
    const [products, setProducts] = useState([])
    const [itemGroups, setItemGroups] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [activeTab, setActiveTab] = useState(null)

    useEffect(() => {
        (async () => Promise.all([Product.getCollection()])
            ?.then(([products]) => {
                return setProducts(products)
            })
        )()
    }, [])
    useEffect(() => {
        const items = onSnapshot(collection(db, 'items'), async snap => {
            const items = await Promise.all(snap.docs.map(async doc => await Item.populateSnapshot(doc)))
            setItems(await Promise.all(_.map(items, async o => {
                _.set(o, 'order', await Order.getReference(_.get(o, 'order')))
                _.set(o, 'user', await User.getReference(_.get(o, 'user')))
                _.set(o, 'product', await Product.getReference(_.get(o, 'product')))
                return o
            })))
        })
        return items
    }, [])
    useEffect(() => setActiveTab(selectedItem ? (activeTab ? activeTab : 'Details') : null), [selectedItem])

    const updateItem = async (e, newItem) => {
        e.preventDefault()
        await newItem.submit(e)
        setItems(items => {
            const copy = [...items]
            const idx = [...copy]?.map(l => l?.id)?.indexOf(newItem.id)
            copy[idx === -1 ? copy.length : idx] = newItem
            return copy
        })
        return setSelectedItem(newItem)
    }


    return (
        <div>
            <div className="px-md-3 px-lg-4 bg-white p-2 border mb-2 d-flex justify-content-between align-items-center">
                <MDBTypography className='display-6 mb-0'>
                    Edit Item
                </MDBTypography>
                {selectedItem &&
                    <MDBBtn className="btn-close" color="none" aria-label="Close" onClick={() => setSelectedItem(null)} />

                }
            </div>
            <div className={selectedItem ? 'd-none' : ''}>
                <AdminItemsTable items={items} setSelectedItem={setSelectedItem} />
            </div>
            {selectedItem &&
                <AdminItemDetail itemRef={selectedItem} close={() => setSelectedItem(null)} />
            }
        </div>
    )
}

