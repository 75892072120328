import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase/firebaseConfig';

export default function Sandbox() {
    const [items, setItems] = useState([])
    useEffect(() => {
        const q = query(collection(db, "items"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => setItems(querySnapshot.docs.map(doc => doc.data())));
        return unsubscribe
    }, [])

    return (
        <div className='w-25'>
        </div>
    )
}


