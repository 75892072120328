import { MDBBtn, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import ItemEditForm from "../../Item/Forms/ItemEditForm"
import Item from "../../Item/Item"
import ItemListCard from "../../Item/Views/ItemListCard"

export default function OrderItems({ order }) {
    const [modalObject, setModalObject] = useState(null)
    const [items, setItems] = useState(null)

    useEffect(() => {
        if (!items) {
            (async () => {
                return setItems(await order.getItems())
            })()
        }
    }, [])

    const submit = async (e, item) => {
        e.preventDefault()
        await item.submit(e)
        const copy = Item.clone(item)
        setItems(items => {
            const newItems = [...items]
            const idx = items?.findIndex((i) => i?.id === copy?.id)
            if (idx === -1) newItems.push(copy)
            else newItems[idx] = copy
            return newItems
        })
        return setModalObject(null)
    }

    return (
        <div>
            <MDBListGroup light>
                <MDBListGroupItem className='d-flex justify-content-center align-items-center' style={{ height: '50px' }}>
                    <div className='d-flex align-items-center'>
                        <MDBBtn
                            size="lg"
                            rounded
                            color='link'
                            onClick={async () => setModalObject(await Item.init({ order: order, user: order.user }))}
                        >
                            Add Item
                        </MDBBtn>
                    </div>
                </MDBListGroupItem>
                {items && [...new Set([...items]?.map((i) => i?.product?.category))]?.sort((a, b) => a?.localeCompare(b))?.map((cat, i) => (
                    <div key={`cat${i}`}>
                        <h6 className={`bg-light p-2 border-top border-bottom`}>{cat || 'Other Items'}</h6>
                        {[...items]?.filter((itm) => itm?.product?.category === cat)?.map((item, index) => (
                            <ItemListCard item={item} setModalObject={setModalObject} key={`item${index}`} />
                        ))}
                    </div>
                ))}

            </MDBListGroup>
            <ItemEditForm modalObject={modalObject} setModalObject={setModalObject} submit={submit} order={order} />
        </div>
    )
}






