import { MDBBtn, MDBContainer, MDBDatatable, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import User from "../../../classes/firebase/User/User";
import DualColumnLayout from '../Layouts/DualColumnLayout';
import Details from "./tabs/Details";
import _ from 'lodash'
export default function AdminUsers() {
  const params = useParams()
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const [activeTab, setActiveTab] = useState(null)

  useEffect(() => {
    (async () => {
      return Promise.all([User.getCollection()])
        ?.then(([users]) => {
          setUsers(users)
          return setSelectedUser(params?.id ? [...users]?.find(o => o?.id === params?.id) : null)
        })
    })()
  }, [])

  useEffect(() => setActiveTab(selectedUser ? (activeTab ? activeTab : 'Details') : null), [selectedUser])


  const updateUser = async (e, newUser) => {
    e.preventDefault()
    await newUser.submit()
    setUsers(users => {
      const copy = [...users]
      const idx = [...copy]?.map(l => l?.id)?.indexOf(newUser.id)
      copy[idx === -1 ? copy.length : idx] = newUser
      return copy
    })
    return setSelectedUser(newUser)
  }
  return (
    <div>
      <div className="px-md-3 px-lg-4 bg-white p-2 border mb-2 d-flex justify-content-between align-items-center">
        <MDBTypography className='display-6 mb-0'>
          <span>Users</span>
          {selectedUser &&
            <span>: {selectedUser?.name}</span>
          }
        </MDBTypography>
        {selectedUser &&
          <MDBBtn className="btn-close" color="none" aria-label="Close" onClick={() => setSelectedUser(null)} />

        }
      </div>
      <div className={selectedUser ? 'd-none' : ''}>
        <MDBContainer className='border rounded-3 bg-white py-3' >
          <MDBDatatable
            search
            entries={50}
            sm
            hover
            data={{
              columns: [
                { label: 'Name', field: 'name' },
                { label: 'Email', field: 'email' },
                { label: 'Phone', field: 'phone' },
                { label: 'Role', field: 'role' },
              ],
              rows: [...users]?.map((user, idx) => {
                return {
                  name: _.get(user, 'name') || '',
                  email: _.get(user, 'email') || '',
                  phone: _.get(user, 'phone') || '',
                  role: _.get(user, 'role') || '',
                  id: _.get(user, 'id')
                }
              })
            }}
            onRowClick={(row) => {
              const idx = [...users]?.map(d => d?.id)?.indexOf(row?.id)
              setSelectedUser(users[idx])
            }}
          />
        </MDBContainer>
      </div>
      {selectedUser &&
        <DualColumnLayout
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          leftCard={null}
          tabs={[
            {
              title: 'Details',
              component: <Details
                user={selectedUser}
                updateUser={updateUser}
              />
            }
          ]}

        />}
    </div>
  )
}