import _ from 'lodash'
import { MDBBtn, MDBListGroupItem } from "mdb-react-ui-kit"
import Product from "../../Product/Product"
export default function ItemListCard({ item, setEditedItem }) {
    return (
        <MDBListGroupItem className='d-flex justify-content-between align-items-center border-0 px-2'>
            <div className="d-flex justify-content-start">
                <img
                    src={typeof item?.images[0] === 'object' ? URL.createObjectURL(item?.images[0]) : item?.images[0] ||
                        (item?.product?.images[0] ? item?.product?.images[0] : Product.defaultProductImage)}
                    alt=''
                    style={{ width: '100px', height: '100px' }}
                />
                <div className='d-flex align-items-center'>
                    <div className='ms-3'>
                        <p className='fw-bold mb-1'>{item?.product?.name}</p>
                        {item?.customerLocked && <p className='text-muted mb-0'>{item?.status}</p>}
                    </div>
                </div>
            </div>
            <MDBBtn size='lg' rounded color='link' onClick={() => setEditedItem(_.cloneDeep(item))}>
                Edit
            </MDBBtn>
        </MDBListGroupItem>
    )
}
