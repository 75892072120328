import { MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit'
import React from 'react'

export default function OrderNavBar({ order, tabs, activeTab, setActiveTab }) {
    return (
        <div className='mt-3 mb-md-4'>
            <MDBTabs className='border-bottom' justify>
                {tabs && tabs?.map((tab, idx) => (
                    <MDBTabsItem key={`tab-${idx}`}>
                        <MDBTabsLink
                            className={`bg-light mb-0`}
                            onClick={() => setActiveTab(tab?.title)} active={activeTab === tab?.title}>
                            {tab?.title}
                        </MDBTabsLink>
                    </MDBTabsItem>
                ))}

            </MDBTabs>
        </div>
    )
}
