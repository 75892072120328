import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase/firebaseConfig"


export default class StripeModel {


    static init(data) {
        return data
    }

    static async get(id, options) {
        const req = httpsCallable(functions, `stripe-${this.object}-get`)
        try {
            const results = await req({ id: id })
            return await this.init(results.data, options)
        } catch (e) {
            return null
        }
    }


    static async list(expand = [], options = {}) {
        const req = httpsCallable(functions, `stripe-${this.object}-list`)
        const results = await req({ limit: 1000, expand })
        const objects = await Promise.all(results?.data?.map(async (object, idx) => await this.init(object, options)))
        return objects
    }

    static async search(query, options) {
        const req = httpsCallable(functions, `stripe-${this.object}-search`)
        const results = await req({ query })
        const objects = await Promise.all(results?.data?.map(async (object, idx) => await this.init(object, options)))
        return objects
    }


    static async listAllProducts(query = null) {
        const req = httpsCallable(functions, 'stripe-product-list')
        const results = await req({ limit: 1000 })
        return results.data.data
    }

    static async listAllPrices(query = null) {
        const req = httpsCallable(functions, 'stripe-price-list')
        const results = await req({ limit: 1000 })
        return results.data.data
    }

    static async getPrice(id) {
        const req = httpsCallable(functions, 'stripe-price-get')
        const result = await req({ id: id })
        return result.data
    }
}