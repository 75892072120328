import React, { useContext, useEffect, useState } from "react"
import * as fb from 'firebase/firestore'
import { useParams } from "react-router-dom"
import { db } from "../firebase/firebaseConfig"
import Order from "../pages/Admin/Order"
const OrderContext = React.createContext()

export function useOrderContext() {
  return useContext(OrderContext)
}

export function OrderProvider({ id, children }) {
  const [order, setOrder] = useState(null)
  const [user, setUser] = useState(null)
  const [items, setItems] = useState([])
  const [customer, setCustomer] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [subscriptionItems, setSubscriptionItems] = useState([])
  const [invoices, setInvoices] = useState([])



  useEffect(() => {
    if (id) {
      const req = fb.onSnapshot(fb.doc(db, 'orders', id), snap => snap.exists && setOrder({ ...snap.data(), ref: snap.ref }))
      return () => req()
    }
  }, [])

  useEffect(() => {
    if (id) {
      const req = fb.onSnapshot(fb.doc(db, 'subscriptions', id), snap => snap.exists && setSubscription({ ...snap.data(), ref: snap.ref }))
      return () => req()
    }
  }, [])

  useEffect(() => {
    if (order?.ref) {
      const req = fb.onSnapshot(fb.query(fb.collection(db, 'items'), fb.where('order', '==', order?.ref)), snap => setItems(snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref }))))
      return () => req()
    }
  }, [order?.ref])

  useEffect(() => {
    if (subscription?.id) {
      const invoicesReq = fb.onSnapshot(fb.query(fb.collection(db, 'invoices'), fb.where('subscription', '==', subscription?.id)), (snap) => setInvoices(snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref }))))
      const subscriptionItemsReq = fb.onSnapshot(fb.query(fb.collection(db, 'subscriptionItems'), fb.where('subscription', '==', subscription?.id)), (snap) => setSubscriptionItems(snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref }))))
      return () => {
        invoicesReq()
        subscriptionItemsReq()
      }
    }

  }, [subscription?.id])

  useEffect(() => {
    if (order?.user && order.user instanceof fb.DocumentReference) {
      const req = fb.onSnapshot(order?.user, snap => setOrder({ ...order, user: { ...snap.data(), ref: snap.ref } }))
      return () => req()
    }
  }, [order])

  useEffect(() => {
    if (order?.user
      && !customer) {
      const req = fb.onSnapshot(fb.doc(db, 'customers', order?.user?.id), snap => snap.exists && setCustomer({ ...snap.data(), ref: snap.ref }))
      return () => req()
    }
  }, [order])

  useEffect(() => {
    if (order?.location && order.location instanceof fb.DocumentReference) {
      const req = fb.onSnapshot(order?.location, snap => setOrder({ ...order, location: { ...snap.data(), ref: snap.ref } }))
      return () => req()
    }
  }, [order])

  useEffect(() => {
    if (order?.season && order.season instanceof fb.DocumentReference) {
      const req = fb.onSnapshot(order?.season, snap => setOrder({ ...order, season: { ...snap.data(), ref: snap.ref } }))
      return () => req()
    }
  }, [order])

  const value = {
    order,
    items,
    customer,
    subscription,
    subscriptionItems,
    invoices
  }

  return (
    <OrderContext.Provider value={value}>
      {order && children}
    </OrderContext.Provider>
  )
}

export function OrderWrapper() {
  const params = useParams()
  return (
    <OrderProvider id={params.id}>
      <Order />
    </OrderProvider>
  )
}
