import _ from 'lodash';
import { MDBBtn, MDBListGroup, MDBListGroupItem, MDBPopconfirm, MDBPopconfirmMessage, MDBTextArea, MDBTypography } from "mdb-react-ui-kit";
import { useEffect, useState } from 'react';
import { useAuth } from "../../../../contexts/AuthContext";
export default function Notes(props) {
    const { order, updateOrder, active } = props

    const { user } = useAuth()
    const defaultComment = () => {
        return {
            author: {
                name: `${user?.first} ${user?.last}`,
                id: user?.id
            },
            text: '',
            time: new Date()
        }
    }
    const [newComment, setNewComment] = useState(defaultComment())

    useEffect(() => {
        return setNewComment(defaultComment())
    }, [order.adminComments, active])

    return (
        <div>
            <div>
                <MDBTextArea
                    className="bg-white"
                    value={newComment?.text}
                    label="Add Comment"
                    rows={4}
                    onChange={(e) => setNewComment({ ...newComment, text: e.target.value })}
                />
                <div className="mt-2">
                    <MDBBtn
                        color="success"
                        className="rounded-0 shadow-0"
                        disabled={!newComment?.text}
                        onClick={async (e) => {
                            const newOrder = _.cloneDeep(order)
                            newOrder.adminComments?.push(newComment)
                            return await updateOrder(e, newOrder)
                        }}
                    >Comment</MDBBtn>
                    {newComment?.text &&
                        <MDBBtn
                            color="secondary"
                            className="ms-2 rounded-0 shadow-0"
                            onClick={() => setNewComment(defaultComment())}
                        >Cancel
                        </MDBBtn>
                    }
                </div>
            </div>
            <div className="mt-3">
                <MDBListGroup>
                    {[...order?.adminComments]?.sort((a, b) => b?.time - a?.time)?.map((comment, idx) => (
                        <MDBListGroupItem key={`order-Notes-${idx}`} className="mt-3 bg-white rounded-0 border">
                            <div className=''>
                                <MDBTypography tag="h6" className="fw-bold mb-2">
                                    {comment?.author?.name}
                                </MDBTypography>
                                <p className="mb-2">
                                    {comment?.text}
                                </p>
                                <div className="d-flex align-items-center border-bottom">
                                    <p className="mb-0 text-muted">
                                        {(comment?.time instanceof Date ? comment?.time : comment?.time?.toDate())?.toLocaleString('en-US')}
                                    </p>
                                </div>
                                <MDBPopconfirm
                                    btnChildren='Delete'
                                    btnClassName='btn-link bg-transparent text-danger ps-0'
                                    placement='bottom'
                                    confirmBtnText='Delete'
                                    confirmBtnClasses='btn-danger'
                                    cancelBtnText='Cancel'
                                    onConfirm={async (e) => {
                                        const newOrder = _.cloneDeep(order)
                                        _.pullAt(newOrder.adminComments, [...order?.adminComments]?.findIndex(c => _.isEqual(c, comment)))
                                        return await updateOrder(e, newOrder)
                                    }}>
                                    <MDBPopconfirmMessage>Are you sure?</MDBPopconfirmMessage>
                                </MDBPopconfirm>
                            </div>

                        </MDBListGroupItem>
                    ))}
                </MDBListGroup>
            </div>
        </div>
    )
}
