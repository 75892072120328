import Location from "../Location/Location";
import Model from "../Model";

export default class Season extends Model {
    constructor(data) {
        super(data)

        this.name=data.name
        this.location = data.location
        this.active = data.active
        this.registrationOpen = data.registrationOpen
        this.months = data.months
        this.year = data.year
        this.pickup = data.pickup
        this.dropoff = data.dropoff
    }

    static collectionName = 'seasons'


    static async init(data) {
        const d =
        {
            location: this?.location instanceof Location ? this?.location : await Location.getByRef(data?.location) || null,
            active: data?.active || false,
            registrationOpen: data?.registrationOpen || false,
            months: (parseFloat(data?.months) || data?.months) || null,
            year: (parseFloat(data?.year) || data?.year) || null,
            name: data?.name || null,
            pickup: {
                open: data?.pickup?.open || false,
                min: data?.pickup?.min || null,
                max: data?.pickup?.max || null,
                list: data?.pickup?.list || []
            },
            dropoff: {
                open: data?.dropoff?.open || false,
                min: data?.dropoff?.min || null,
                max: data?.dropoff?.max || null,
                list: data?.dropoff?.list || []
            },
        }
        return new Season(d)
    }

}
