import * as fb from 'firebase/firestore'
import React, { createContext, useContext, useEffect, useState } from "react"
import { db } from "../firebase/firebaseConfig"
import _ from 'lodash'
import User from '../classes/firebase/User/User'

const AdminContext = createContext()


export const useAdminContext = () => {
    return useContext(AdminContext)
}

export const AdminProvider = ({ children }) => {
    const [orders, setOrders] = useState([])
    const [locations, setLocations] = useState([])
    const [users, setUsers] = useState([])
    const [customers, setCustomers] = useState([])
    const [products, setProducts] = useState([])
    const [prices, setPrices] = useState([])
    const [items, setItems] = useState([])
    const [dorms, setDorms] = useState([])
    const [seasons, setSeasons] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [loading, setLoading] = useState(true)



    const getCollectionRealtime = (collectionName, callback) => {
        const unsubscribe = fb.onSnapshot(fb.query(fb.collection(db, collectionName)), snap => callback(snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref, id: doc.id }))))
        return unsubscribe
    }

    /* useEffect(() => {
        const orders = getCollectionRealtime('orders', setOrders)
        const customers = getCollectionRealtime('customers', setCustomers)
        const users = getCollectionRealtime('users', setUsers)
        const seasons = getCollectionRealtime('seasons', setSeasons)
        const locations = getCollectionRealtime('locations', setLocations)
        const subscriptions = getCollectionRealtime('subscriptions', setSubscriptions)
        const products = getCollectionRealtime('pricing', setProducts)
        const prices = getCollectionRealtime('prices', setPrices)
        return () => {
            orders()
            customers()
            users()
            seasons()
            locations()
            subscriptions()
            products()
            prices()
        }
    }, [])

    useEffect(() => setOrders([...orders]?.map(order => {
        order.user = _.find([...users], { ref: order?.user }) || order.user
        if (_.get(order, 'user.id')) {
            order.user.customer = _.find([...customers], { id: order?.user?.id }) || null
        }
        order.season = _.find([...seasons], { ref: order?.season }) || order.season
        order.location = _.find([...locations], { ref: order?.location }) || order.location
        order.billing.subscription = _.find([...subscriptions], ['metadata.order', order.ref.id]) || null
        order.pickup.dorm = _.find([...dorms], { ref: order?.pickup.dorm }) || order?.pickup?.dorm
        order.dropoff.dorm = _.find([...dorms], { ref: order?.dropoff.dorm }) || order?.dropoff?.dorm
        return order
    })), [users, seasons, locations, subscriptions, customers]) */

    const value = {
        orders,
        locations,
        users,
        products,
        prices,
        items,
        dorms,
        seasons,
        loading
    }
    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    )
}