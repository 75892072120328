
import _ from 'lodash'
import { MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBRow, MDBSelect, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Table(props) {
    const navigate = useNavigate()
    const { orders } = props
    const [totalPages, setTotalPages] = useState(5)
    const [page, setPage] = useState(1)
    const [entries, setEntries] = useState(10)

    const [data, setData] = useState([])

    const [filters, setFilters] = useState({
        text: '',
        fields: [],
        sortField: {
            key: 'pickup.date.date',
            direc: 'asc'
        }
    })

    useEffect(() => {
        setTotalPages(Math.ceil([...data]?.length / entries))
    }, [data, entries])

    useEffect(() => {
        return setData(sortOrders([...orders]?.filter(o => orderFilter({ ...o }))))
    }, [filters, orders])

    const orderFilter = (o) => {
        if (filters?.text) {
            if (!JSON.stringify(o).includes(filters?.text)) return false
        }
        for (const filter of [...filters?.fields]) {
            if (_.get(o, filter?.field) !== filter?.value) return false
        }
        return true
    }

    const sortOrders = (data) => {
        return _.sortBy(data, [filters?.sortField?.key || 'number'], [filters?.sortField?.direc || 'asc'])


    }

    const calculateRange = (data, page, entries) => {
        return [...data]?.slice(Math.max((page - 1), 0) * entries, Math.max(page, 1) * entries)
    }

    const getChecked = (key, value) => {
        const search = [...filters?.fields]?.find(f => f?.field === key)
        if (search) {
            if (search?.value === value) return true
        }
        return false
    }


    return (
        <div className='pt-3'>
            <MDBRow>
                <MDBCol md='3' lg='2' className='border-end'>
                    {['pickup', 'dropoff'].map((mode, idx) => (
                        <div key={`pickupFiltersMode${idx}`}>
                            <p className="border-bottom mb-1">{mode.charAt(0).toUpperCase() + mode.slice(1)}</p>
                            <MDBCheckbox
                                name={`${mode}.complete`}
                                label='Complete'
                                checked={getChecked(`${mode}.complete`, true)}
                                onChange={(e) => setFilters(filter => {
                                    const filterArray = [...filter.fields].filter(f => f.field !== e.target.name)
                                    if (e.target.checked) filterArray.push({ field: e.target.name, value: e.target.checked })
                                    filter.fields = filterArray
                                    return { ...filter, fields: filterArray }
                                })}
                            />
                            <MDBCheckbox
                                name={`${mode}.complete`}
                                label='Incomplete'
                                checked={getChecked(`${mode}.complete`, false)}
                                onChange={(e) => setFilters(filter => {
                                    const filterArray = [...filter.fields].filter(f => f.field !== e.target.name)
                                    if (e.target.checked) filterArray.push({ field: e.target.name, value: !e.target.checked })
                                    filter.fields = filterArray
                                    return { ...filter, fields: filterArray }
                                })}
                            />

                        </div>
                    ))}
                </MDBCol>
                <MDBCol md='9' lg='10'>
                    <div>
                        <MDBInput
                            label="Search"
                            onChange={(e) => setFilters({ ...filters, text: e.target.value })}
                        />
                        <MDBTable align='middle' small responsive hover>
                            <MDBTableHead>
                                <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>School</th>
                                    <th scope='col' className='text-center'>Pickup</th>
                                    <th scope='col' className='text-center'>Dropoff</th>
                                    <th scope='col' className='text-center'>Billed</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {calculateRange(data, page, entries)?.map((order, i) => (
                                    <tr key={`order-table-row-${i}`} onClick={() => navigate(`${order.ref.id}`)}
                                    >
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <p className='fw-bold mb-1'>{order?.number}</p>
                                                    <p className='text-muted mb-0'>{order?.user?.first} {order?.user?.last}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <p className='fw-bold mb-1'>{order?.location?.name}</p>
                                                    <p className='text-muted mb-0'>{order?.season?.name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <div>
                                                    <MDBIcon fas icon={order?.pickup?.complete ? 'check' : 'times'} />
                                                    <p className='text-muted mb-0'>{order?.pickup?.date?.date || ''}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <div>
                                                    <MDBIcon fas icon={order?.dropoff?.complete ? 'check' : 'times'} />
                                                    <p className='text-muted mb-0'>{order?.dropoff?.date?.date || ''}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <MDBIcon fas icon={order?.subscription ? 'check' : 'times'} />
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <div className='d-flex justify-content-start align-items-center'>
                                <p className='mb-0 fw-light'>Rows per page: </p>
                                <MDBSelect
                                    preventFirstSelection
                                    data={[10, 25, 50, 100].map(n => ({ text: String(n), value: n, defaultSelected: n === entries }))}
                                    onValueChange={(e) => setEntries(e.value)}
                                    className='ms-2'
                                    style={{ width: '70px' }}
                                />
                            </div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <p className='mb-0 fw-light'>{(page * entries) - (entries - 1)}-{Math.min([...data]?.length, page * entries)} of {[...data]?.length}</p>

                            </div>
                            <div className='d-flex justify-content-end'>
                                <MDBPagination className='mb-0'>
                                    <MDBPaginationItem>
                                        <MDBPaginationLink
                                            tag='button'
                                            onClick={() => setPage(1)}
                                        >
                                            <MDBIcon fas size='lg' icon="angle-double-left" />
                                        </MDBPaginationLink>
                                    </MDBPaginationItem>
                                    <MDBPaginationItem>
                                        <MDBPaginationLink
                                            tag='button'
                                            onClick={() => setPage(page => Math.max(1, page - 1))}
                                        >
                                            <MDBIcon fas size='lg' icon="angle-left" />
                                        </MDBPaginationLink>
                                    </MDBPaginationItem>

                                    <MDBPaginationItem>
                                        <MDBPaginationLink
                                            tag='button'
                                            onClick={() => setPage(page => Math.min(page + 1, totalPages))}
                                        >
                                            <MDBIcon fas size='lg' icon="angle-right" />
                                        </MDBPaginationLink>
                                    </MDBPaginationItem>
                                    <MDBPaginationItem>
                                        <MDBPaginationLink
                                            tag='button'
                                            onClick={() => setPage(totalPages)}
                                        >
                                            <MDBIcon fas size='lg' icon="angle-double-right" />
                                        </MDBPaginationLink>
                                    </MDBPaginationItem>
                                </MDBPagination>
                            </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
        </div>
    )
}
