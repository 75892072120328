import { MDBCol, MDBInput, MDBRow, MDBSelect } from 'mdb-react-ui-kit'
import React from 'react'
import { unitedStatesArray } from '../../../../utils/formatting/formattingUtils'

export default function AddressForm({ address, setAddress }) {
    return (
        <div>
            <MDBInput
                name={`street`}
                label='Street'
                type='text'
                className='w-100'
                wrapperClass='mb-3'
                value={address?.street || ''}
                onChange={(e) => setAddress(address.onChange(e.target.name, e.target.value))}
            />
            <MDBInput
                name={`street2`}
                label='Street Line Two'
                type='text'
                className='w-100'
                wrapperClass='mb-3'
                value={address?.street2 || ''}
                onChange={(e) => setAddress(address.onChange(e.target.name, e.target.value))}
            />
            <MDBRow className="d-flex flex-row align-items-center">
                <MDBCol md='6'>
                    <MDBInput
                        name={`city`}
                        label='City'
                        type='text'
                        className='w-100'
                        wrapperClass='mb-3'
                        value={address?.city || ''}
                        onChange={(e) => setAddress(address.onChange(e.target.name, e.target.value))}
                    />
                </MDBCol>
                <MDBCol size='5' md='3'>
                    <MDBSelect
                        preventFirstSelection
                        className="mb-3"
                        label='State'
                        data={unitedStatesArray?.map((state, idx) => ({
                            text: state?.abbreviation,
                            value: state?.abbreviation,
                            defaultSelected: address?.state === state?.abbreviation,
                            name: `state`
                        }))}
                        onValueChange={(e) => setAddress(address.onChange(e.name, e.value))}
                    />

                </MDBCol>
                <MDBCol size='7' md='3'>
                    <MDBInput
                        name={`zip`}
                        label='Zip'
                        className='w-100'
                        wrapperClass='mb-3'
                        value={address?.zip || ''}
                        onChange={(e) => setAddress(address.onChange(e.target.name, e.target.value))}
                    />
                </MDBCol>
            </MDBRow>

        </div>
    )
}
