import { httpsCallable } from 'firebase/functions';
import _ from 'lodash';
import { MDBAlert, MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBSelect, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import { Fragment, useEffect, useState } from 'react';
import { functions } from '../../../../firebase/firebaseConfig';


export default function Billing(props) {
    const { order, setOrder, updateOrders, products, coupons } = props
    return order?.billing?.subscription ?
        <BillingActive order={order} products={products} coupons={coupons} /> :
        <BillingPending order={order} setOrder={setOrder} products={products} coupons={coupons} updateOrders={updateOrders} />

}

const BillingPending = ({ order, setOrder, updateOrders, products, coupons }) => {
    const [itemGroups, setItemGroups] = useState([])
    const [discount, setDiscount] = useState(null)
    const [error, setError] = useState(null)




    useEffect(() => setItemGroups(Object.entries(_.groupBy([...order?.items], (i) => i?.product?.name || null))
        ?.sort((a, b) => a?.[0]?.localeCompare(b?.[0]))?.map((group, idx) => {
            return {
                product: [...products]?.find(p => p?.name === group?.[0]) || { name: 'Other Items' },
                items: group?.[1],
                sum: _.sum([...group?.[1]]?.map(i => i?.price?.unit_amount / 100)),
            }
        })), [order.items])

    const createSubscription = async (e) => {
        e.preventDefault()
        const create = httpsCallable(functions, 'api-http-stripe-requests-subscription-create')
        try {
            const sub = await create({ id: order.id, coupon: discount })
            const res = sub.data
            if (res?.object === 'subscription') {
                const newOrder = order.onChange('billing.subscription', res)
                updateOrders(newOrder)
                return setOrder(newOrder)
            }
            const newOrder = order.onChange('billing.subscription', { schedule: sub.data })
            updateOrders(newOrder)
            return setOrder(newOrder)
        }
        catch (e) {
            return setError(e)
        }
    }

    const validateBilling = () => {
        if (!order?.items?.length) return false
        if ([...order?.items]?.filter(i => {
            if (!['In Storage', 'Delivered']?.includes === i?.status) return true
            if (!i?.price) return true
            if (i?.price) {
                if (i?.price?.custom) {
                    if (!i?.price?.unit_amount) return true
                    if (!i?.price?.product) return true
                }
                else if (i?.price?.id) {
                    if (!i?.price?.id) return true
                } else return true
            }
        }).length > 0) return false
        if (order?.billing?.subscription) return false
        if (!order?.pickup?.complete) return false
        return true

    }
    return (
        <>
            <MDBAlert show={!order?.user?.customer?.default_source} color='danger'>No Payment Method</MDBAlert>
            <MDBTable align='middle' className="border" small>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>
                            Item
                        </th>
                        <th scope='col'>
                            Monthly
                        </th>
                        <th scope='col'>
                            Season
                        </th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody className="bg-white">
                    {[...itemGroups]
                        ?.map((itemGroup, i) => {
                            return (
                                <Fragment key={`itemGroup-${i}`}>
                                    <tr className='bg-light'>
                                        <td className='fw-bold' colSpan={3}>
                                            {itemGroup?.product?.name}
                                        </td>
                                    </tr>
                                    {[...itemGroup?.items]
                                        ?.map((item, j) => (
                                            <tr key={`itemGroup-${i}-item-${j}`}>
                                                <td className=''>
                                                    {item?.number} <MDBBadge color={item?.status === 'Delivered' ? 'success' : item?.status === 'In Storage' ? 'light' : 'danger'} light>
                                                        {item?.status}
                                                    </MDBBadge>
                                                </td>
                                                <td className=''>
                                                    {(item?.price?.unit_amount / 100).toFixed(2)}
                                                </td>
                                                <td className=''>
                                                    {(item?.price?.unit_amount / 100 * parseInt(order?.season?.months)).toFixed(2)}
                                                </td>
                                            </tr>
                                        ))}
                                </Fragment>
                            )
                        })}
                    <tr className='bg-light fw-bold'>
                        <td>
                            Subtotal
                        </td>
                        <td>
                            ${_.sum([...itemGroups]?.map(g => g?.sum))?.toFixed(2)}
                        </td>
                        <td>
                            ${(_.sum([...itemGroups]?.map(g => g?.sum)) * parseInt(order?.season?.months))?.toFixed(2)}
                        </td>
                    </tr>
                    <tr className='fw-bold'>
                        <td>
                            <MDBSelect
                                label={'Discount'}
                                preventFirstSelection
                                data={coupons && _.concat([{ text: 'N/A', value: null }], [...coupons]?.filter(coupon => coupon.valid && coupon.percent_off)
                                    ?.sort((a, b) => a?.name?.localeCompare(b?.name)).map((coupon, idx) => ({
                                        text: `${coupon.name} 
                                    ${coupon.percent_off ?
                                                `(${coupon.percent_off}%)`
                                                : ''}
                                        ${coupon.amount_off ?
                                                `($${coupon.amount_off / 100})`
                                                : ''}
                                        `,
                                        value: coupon
                                    })))}
                                onValueChange={(e) => setDiscount(e.value)}
                            />
                        </td>
                        <td>
                            ${discount ? (discount?.percent_off / 100 * _.sum([...itemGroups]?.map(g => g?.sum))).toFixed(2) : 0.00}
                        </td>
                        <td>
                            ${discount ? (parseInt(order?.season?.months) * discount?.percent_off / 100 * _.sum([...itemGroups]?.map(g => g?.sum))).toFixed(2) : 0.00}
                        </td>
                    </tr>
                    <tr className='bg-light fw-bold'>
                        <td>
                            Total
                        </td>
                        <td>
                            ${(_.sum([...itemGroups]?.map(g => g?.sum)) * (1 - ((discount?.percent_off || 0) / 100)))?.toFixed(2)}
                        </td>
                        <td>
                            ${(_.sum([...itemGroups]?.map(g => g?.sum)) * parseInt(order?.season?.months) * (1 - ((discount?.percent_off || 0) / 100)))?.toFixed(2)}
                        </td>
                    </tr>
                </MDBTableBody>
            </MDBTable>
            {validateBilling() &&
                <div className='text-center'>
                    <MDBAlert show={error} color='danger'>{error?.message}</MDBAlert>
                    <MDBBtn color='success' className='rounded-0 w-75'
                        onClick={async (e) => await createSubscription(e)}>
                        Create Invoice
                    </MDBBtn>
                </div>}
        </>
    )
}

const BillingActive = ({ order, products, coupons }) => {
    const [invoiceItems, setInvoiceItems] = useState([])
    useEffect(() => {
        if (Array.isArray(order?.billing?.subscription?.items)) {
            return setInvoiceItems([...order?.billing?.subscription?.items]?.map(item => {
                const product = [...products]?.find(product => product?.id === item?.price?.product)
                return {
                    quantity: item?.quantity,
                    price: item?.price,
                    product,
                    monthlyTotal: (item?.quantity * item?.price?.unit_amount / 100),
                    seasonTotal: parseInt(order?.season?.months) * (item?.quantity * item?.price?.unit_amount / 100)
                }
            }))
        }
        return setInvoiceItems(order?.billing?.subscription?.schedule?.phases?.[0]?.items?.map((item, idx) => {
            const product = [...products]?.find(product => product?.prices?.map(price => price?.id)?.includes(item?.price))
            const price = product?.prices ? [...product?.prices]?.find(price => price?.id === item?.price) : { unit_amount: 5 }
            return {
                quantity: item?.quantity,
                price,
                product,
                monthlyTotal: (item?.quantity * price?.unit_amount / 100),
                seasonTotal: parseInt(order?.season?.months) * (item?.quantity * price?.unit_amount / 100)
            }
        }) || [])


    }, [order?.billing?.subscription, products])

    const subscription = order?.billing?.subscription
    const schedule = subscription?.schedule

    const getDiscount = () => {
        const coupon = subscription?.discount?.coupon
        if (!coupon) {
            return 0
        }
        if (coupon?.amount_off) {
            return coupon?.amount_off / 100

        }
        else if (coupon?.percent_off) {
            return _.sum([...invoiceItems]?.map(i => i?.monthlyTotal)) * coupon?.percent_off / 100

        }

    }

    const getOutstandingBalance = (order) => {
        const unpaidSubscriptions = _.map(_.filter(_.get(order, 'billing.subscription.invoices'), { paid: false }), 'amount_remaining') || []
        if(!unpaidSubscriptions.length) return `$${order?.user?.customer?.balance?.toFixed(2)}`
        return `$${(_.sum(unpaidSubscriptions)/100)?.toFixed(2)} (${_.size(unpaidSubscriptions)} Invoices)`
    }

    const bal = getOutstandingBalance(order)
    return (
        <>

            <div className='bg-white p-2 mb-3'>
                <MDBTable align="middle">
                    <MDBTableBody>
                        <tr>
                            <td className='fw-bold'>
                                Stripe Customer
                            </td>
                            <td>
                                <p className='mb-0'>{order?.user?.customer?.name}</p>
                                <p className='mb-0'>{order?.user?.customer?.email}</p>
                                <p className='mb-0'>{order?.user?.customer?.phone}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-bold'>
                                Customer Outstanding Balance
                            </td>
                            <td>
                                <p className='mb-0'>{getOutstandingBalance(order)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-bold'>
                                Billing Status
                            </td>
                            <td>
                                {subscription?.status !== 'past_due' && (schedule?.status === 'not_started' ? `Begins ${new Date(schedule?.phases?.[0]?.start_date * 1000).toLocaleDateString('en-US')}` :
                                    subscription.status==='canceled'?'Closed':subscription.status||'Active')}
                                <MDBAlert color='danger' show={subscription?.status === 'past_due'}>Past Due</MDBAlert>
                            </td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </div>
            <div className='bg-white py-2'>
                <h5 className='ms-3 my-2'>Pricing</h5>
                <hr className='mb-0' />
                <MDBTable align='middle' small>
                    <MDBTableHead>
                        <tr style={{ fontSize: '12px' }}>
                            <th scope='col'>
                                ITEM
                            </th>
                            <th scope='col'>
                                QTY
                            </th>
                            <th scope='col'>
                                AMOUNT
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody className="bg-white">
                        {[...invoiceItems]?.map((item, idx) => {
                            return (
                                <tr style={{ fontSize: '12px' }} key={`activeInvoiceRow${idx}`}>
                                    <td>
                                        {item?.product?.name}
                                    </td>
                                    <td>
                                        {item?.quantity}
                                    </td>
                                    <td>
                                        ${item?.monthlyTotal?.toFixed(2)} / month
                                    </td>
                                </tr>
                            )
                        })}
                        <tr style={{ fontSize: '12px' }}>
                            <td colSpan={2} className='fw-bold text-end'>
                                Subtotal
                            </td>
                            <td>
                                ${_.sum([...invoiceItems]?.map(i => i?.monthlyTotal))?.toFixed(2)} / month
                            </td>
                        </tr>
                        <tr style={{ fontSize: '12px' }}>
                            <td colSpan={2} className='fw-bold text-end'>
                                Discount
                            </td>
                            <td>
                                -${getDiscount()?.toFixed(2)} / month
                            </td>
                        </tr>
                        <tr style={{ fontSize: '12px' }}>
                            <td colSpan={2} className='fw-bold text-end'>
                                Total
                            </td>
                            <td>
                                <p className='mb-0'>
                                    ${(_.sum([...invoiceItems]?.map(i => i?.monthlyTotal)) - getDiscount())?.toFixed(2)} / month
                                </p>
                                <p className='mb-0'>
                                    ${(parseInt(order?.season?.months) * (_.sum([...invoiceItems]?.map(i => i?.monthlyTotal)) - getDiscount()))?.toFixed(2)} / season
                                </p>
                            </td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </div>
        </>
    )
}
