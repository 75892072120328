
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCol, MDBContainer, MDBInput, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";

export default function PasswordReset() {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [error, setError] = useState()
    const [valid, setValid] = useState(false)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(true)

    const [urlParams, setUrlParams] = useState({
        mode: '',
        actionCode: '',
        apiKey: '',
        continueUrl: '',
        lang: ''
    })

    const verifyCode = () => {
        const mode = searchParams.get('mode') || ''
        const oobCode = searchParams.get('oobCode') || ''
        const apiKey = searchParams.get('apiKey') || ''
        const continueUrl = searchParams.get('continueUrl') || ''
        const lang = searchParams.get('lang') || ''
        verifyPasswordResetCode(auth, oobCode).then((email) => {
            setEmail(email)
            setValid(true)
            setUrlParams({
                mode: mode,
                actionCode: oobCode,
                apiKey: apiKey,
                continueUrl: continueUrl,
                lang: lang
            })
        }).catch(() => {
            setError("Invalid Code")
        })

        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        confirmPasswordReset(auth, urlParams.actionCode, password).then((respon) => {
            navigate('/authenticate')
        }).catch(() => {
            setError("Error Changing Password")
        })
    }

    useEffect(() => {
        verifyCode()
    }, [])

    if (loading) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '500px' }}>
                <MDBSpinner style={{ position: 'absolute' }} />
            </div>
        )
    }
    else if (!valid) {
        return (
            <MDBRow className="my-5">
                <MDBCol sm={9} md={7} lg={6} xl={4} className='mx-auto'>
                    <MDBCard>
                        <MDBCardHeader>
                            {error && <MDBAlert show color="danger">{error}</MDBAlert>}
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBBtn rounded onClick={(e) => navigate('/auth/forgot-password')}>Try Again</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        )
    }
    else {
        return (
            <MDBRow className="my-5">
                <MDBCol sm={9} md={7} lg={6} xl={4} className='mx-auto'>
                    <MDBCard>
                        <MDBCardHeader>
                            {error && <MDBAlert show color="danger">{error}</MDBAlert>}
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow center>
                                Email: {email && email}
                            </MDBRow>

                            <MDBInput
                                className='mb-4'
                                value={password}
                                name='password'
                                type='password'
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                label='New Password'
                            />
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn rounded color="success" onClick={(e) => handleSubmit(e)}>Submit</MDBBtn>
                        </MDBCardFooter>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        )
    }
}