import { doc, updateDoc } from "firebase/firestore"
import { MDBBtn, MDBCheckbox, MDBCol, MDBContainer, MDBDatatable, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRadio, MDBRow, MDBSelect } from "mdb-react-ui-kit"
import Order from "../Order"
import _ from 'lodash'
import { useEffect, useState } from "react"
import User from "../../User/User"

export default function AdminOrdersTable(props) {
    const { orders, setSelectedOrder } = props

    const [selectedRows, setSelectedRows] = useState([])
    const [filter, setFilter] = useState({
        status: 'In Progress'
    })


    const filterData = () => {
        const filtered = _.filter([...orders], o => {
            for (const [key, value] of _.entries(_.cloneDeep(filter))) {
                if (_.get(o, key) !== value) return false
            }
            return true
        })
        return filtered
    }

    const updateStatuses = async (e, orders, update) => {
        e.preventDefault()
        await Promise.all(_.map([...orders], async (o) => {
            return await updateDoc(_.get(o, 'ref'), update)
        }))
        return setSelectedRows([])
    }
    return (
        <MDBContainer className='border rounded-3 bg-white py-3' >
            <MDBRow className='mb-2'>
                <MDBCol size='6' md='3'>
                    <div>
                        <p className="border-bottom mb-1">Status</p>
                        <MDBCheckbox
                            name={`status`}
                            label='In Progress'
                            checked={_.get(filter, `status`) === 'In Progress'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'In Progress')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`status`}
                            label='Completed'
                            checked={_.get(filter, `status`) === 'Completed'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'Completed')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`status`}
                            label='Cancelled'
                            checked={_.get(filter, `status`) === 'Cancelled'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'Cancelled')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                    </div>
                </MDBCol>
                <MDBCol size='6' md='3'>
                    <div>
                        <p className="border-bottom mb-1">Billing</p>
                        <MDBCheckbox
                            name={`billing.subscription.object`}
                            label='Billed'
                            checked={_.get(filter, `billing.subscription.object`) === 'subscription'}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.set({ ...filter }, [e.target.name], 'subscription')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                        <MDBCheckbox
                            name={`billing.subscription`}
                            label='Not Billed'
                            checked={_.get(filter, `billing.subscription`) === null}
                            onChange={(e) => setFilter(filter => {
                                if (e.target.checked) {
                                    return _.omit(_.set({ ...filter }, [e.target.name], null), 'billing.subscription.object')
                                }
                                return _.omit({ ...filter }, [e.target.name])
                            })}
                        />
                    </div>
                </MDBCol>
                {['pickup', 'dropoff'].map((mode, idx) => (
                    <MDBCol key={`pickupFiltersMode${idx}`} size='6' md='3'>
                        <div>
                            <p className="border-bottom mb-1">{mode.charAt(0).toUpperCase() + mode.slice(1)}</p>
                            <MDBCheckbox
                                name={`${mode}.complete`}
                                label='Complete'
                                checked={_.get(filter, `${mode}.complete`) === true}
                                onChange={(e) => setFilter(filter => {
                                    if (e.target.checked) {
                                        return _.set({ ...filter }, [e.target.name], e.target.checked)
                                    }
                                    return _.omit({ ...filter }, [e.target.name])
                                })}
                            />
                            <MDBCheckbox
                                name={`${mode}.complete`}
                                label='Incomplete'
                                checked={_.get(filter, [`${mode}.complete`]) === false}
                                onChange={(e) => setFilter(filter => {
                                    if (e.target.checked) {
                                        return _.set({ ...filter }, [e.target.name], !e.target.checked)
                                    }
                                    return _.omit({ ...filter }, [e.target.name])
                                })}
                            />
                            <MDBCheckbox
                                name={`${mode}.date.date`}
                                label='Missing Date'
                                checked={_.get(filter, [`${mode}.date.date`]) === null}
                                onChange={(e) => setFilter(filter => {
                                    if (e.target.checked) {
                                        return _.set({ ...filter }, [e.target.name], null)
                                    }
                                    return _.omit({ ...filter }, [`${mode}.date.date`])
                                })}
                            />
                            <MDBCheckbox
                                name={`${mode}.dorm`}
                                label='Missing Dorm'
                                checked={_.get(filter, [`${mode}.dorm`]) === null}
                                onChange={(e) => setFilter(filter => {
                                    if (e.target.checked) {
                                        return _.set({ ...filter }, [e.target.name], null)
                                    }
                                    return _.omit({ ...filter }, [`${mode}.dorm`])
                                })}
                            />
                        </div>
                    </MDBCol>
                ))}
                <MDBCol md='4'>
                    <MDBSelect
                        preventFirstSelection
                        label={'Location'}
                        data={_.map(_.uniqBy(_.map([...orders], 'location'), 'id'), o => ({ text: o.name, value: o, name: 'location.name', selected: _.get(filter, 'location.name' === _.get(o, 'name')) }))}
                        onValueChange={(e) => setFilter(filter => {
                            if (e.value) {
                                return _.set({ ...filter }, [e.name], e.text)
                            }
                            return _.omit({ ...filter }, ['location.name'])
                        })}
                        clearBtn
                        className={'my-3'}
                    />
                    <MDBSelect
                        preventFirstSelection
                        label={'Season'}
                        data={_.map(_.uniqBy(_.map([...orders], 'season'), 'id'), o => ({ text: `${o.name} ${o.year}`, value: o, name: 'season', selected: _.get(filter, 'season.year' === _.get(o, 'year')) && _.get(filter, 'season.name' === _.get(o, 'name')) }))}
                        onValueChange={(e) => setFilter(filter => {
                            if (e.value) {
                                return _.set(_.set({ ...filter }, ['season.year'], e.value.year), ['season.name'], e.value.name)
                            }
                            return _.omit(_.omit({ ...filter }, ['season.year'], ['season.name']))
                        })}
                        clearBtn
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
                <MDBCol className='d-flex justify-content-end' size='auto'>
                    <MDBBtn size="sm"
                        color="light"
                        className="border"
                        onClick={async (e) => {
                            e.preventDefault()
                            const newOrder = await Order.init()
                            const ref = doc(Order.collection)
                            newOrder.ref = ref
                            newOrder.id = ref.id
                            setSelectedOrder(newOrder)
                        }}>New</MDBBtn>
                </MDBCol>
            </MDBRow>
            <MDBRow>
            </MDBRow>
            <MDBDatatable
                search
                entries={10}
                sm
                hover
                data={{
                    columns: [
                        {
                            label:
                                <div className="d-flex align-items-center justify-content-center">
                                    <MDBDropdown group>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <MDBCheckbox
                                                wrapperClass=''
                                                className='m-1 mx-2'
                                                onChange={(e) => {
                                                    if (filterData().length === selectedRows.length) return setSelectedRows([])
                                                    return setSelectedRows([...filterData()])
                                                }}
                                                checked={(selectedRows.length > 0 && (filterData().length === selectedRows.length)) || false}
                                            />
                                        </div>
                                        <MDBDropdownToggle split size='sm' color='secondary' />
                                        <div style={{ position: 'fixed' }}>
                                            <MDBDropdownMenu appendToBody style={{ width: '100px' }}>
                                                <MDBDropdownItem className="btn w-100 border-bottom shadow-0 px-1"
                                                    onClick={async (e) => await updateStatuses(e, [...selectedRows], { pickup: { complete: true } })}>Pickup Complete</MDBDropdownItem>
                                                <MDBDropdownItem className="btn w-100 border-bottom shadow-0 px-1"
                                                    onClick={async (e) => await updateStatuses(e, [...selectedRows], { dropoff: { complete: true } })}>Dropoff Complete</MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </div>
                                    </MDBDropdown>
                                </div>
                            , field: 'selected', sort: false
                        },
                        { label: 'Number', field: 'number' },
                        { label: 'Dates', field: 'dates' },
                        { label: 'Season', field: 'season' },
                        { label: 'Status', field: 'status' },
                    ],
                    rows: filterData()?.map((order, idx) => {
                        return {
                            selected:
                                <div className="d-flex align-items-center justify-content-center">
                                    <MDBCheckbox
                                        onChange={(e) => {
                                            const copy = [...selectedRows]
                                            if (e.target.checked) {
                                                copy.push(_.find([...orders], { id: _.get(order, 'id') }))
                                                return setSelectedRows(copy)
                                            }
                                            return setSelectedRows(_.filter(copy, o => _.get(o, 'id') !== _.get(order, 'id')))

                                        }}
                                        checked={(_.find([...selectedRows], { id: _.get(order, 'id') })) || false}
                                    />
                                </div>
                            ,
                            season: `${String(order?.season?.name || '')} ${String(order?.season?.year)} (${order?.season?.months} months)`,
                            number: <div>
                                <p className="m-0">{_.get(order, 'number')}</p>
                                <p className="m-0">{User.getName(_.get(order, 'user'))}</p>
                            </div>,
                            dates: <div>
                                <p className={`m-0 ${_.get(order, 'pickup.complete') ? 'text-decoration-line-through' : ''}`}>{_.get(order, 'pickup.date.date') || 'No Pickup Date'}</p>
                                <p className={`m-0 ${_.get(order, 'dropoff.complete') ? 'text-decoration-line-through' : ''}`}>{_.get(order, 'dropoff.date.date') || 'No Dropoff Date'}</p>
                            </div>,
                            id: order?.id,
                            ref: order?.ref,
                            search: JSON.stringify(order),
                            status: <div>
                                <p className="m-0">{_.get(order, 'status') || ''}</p>
                                <p className="m-0">{_.get(order, 'billing.subscription') !== null ? 'Billed' : 'Not Billed'}</p>
                            </div>


                        }
                    })
                }}
                onRowClick={async (row) => await setSelectedOrder(_.get(row, 'ref'))}
                isLoading={!_.size(orders)}
            />
        </MDBContainer>
    )

}