import imageCompression from 'browser-image-compression';
import * as fb from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import _ from 'lodash';
import { db, storage } from '../firebase/firebaseConfig';
import utils from './utils/utils';
export default class Model {
    static collectionName = null
    static get collection() {
        return fb.collection(db, this.collectionName).withConverter(this.converter)
    }

    static get ref() {
        if (this?.id) {
            return fb.doc(db, this.collection, this?.id)
        }
        return fb.doc(db, this.collection)
    }

    static set ref(ref) {
        return this.ref = ref
    }
    static get converter() {
        return {
            fromFirestore: async (snapshot, options) => await this.fromFirestore(snapshot, options),
            toFirestore: (data) => this.toFirestore(data)
        }
    }

    static async fromFirestore(snapshot, options) {
        const data = snapshot.data()
        if (options) {
            Object?.keys(options)?.forEach((key) => data[key] = options[key])
        }
        if (!data.id) {
            data.id = snapshot.id
        }
        data.ref = snapshot.ref
        const transformed = await this.init(data)
        if (!transformed.id) {
            transformed.id = snapshot?.id || null
        }
        transformed.ref = snapshot?.ref || null

        if (!data?.time) {
            const time = Date.now()
            await fb.updateDoc(transformed.ref, { time: time })
            transformed.time = time
        } else {
            transformed.time = data.time
        }

        return transformed
    }

    static async init(data) {
        return data
    }

    static toFirestore(data) {
        const transformed = this.transformToFirestore(data)
        const { id, ref, ...filtered } = transformed
        if (!filtered.time) {
            if (data.time) {
                filtered.time = data.time
            } else {
                filtered.time = Date.now()
            }
        }
        return filtered
    }

    static transformToFirestore(data) {
        return data
    }

    static async transformToFirestoreAsync(data) {
        return data
    }

    static async delete(data) {
        return await fb.deleteDoc(data.ref)
    }

    /**
     * @param {DocumentSnapshot} snapshot
     */
    static async populateSnapshot(snapshot) {
        if (snapshot.exists()) {
            return { ...snapshot.data(), ref: snapshot.ref, id: snapshot.id }
        }
        return null
    }

    static async ensureReference(property) {
        if (typeof property === 'undefined') return null
        else if (property instanceof fb.DocumentReference) return property
        else if ((typeof property === 'string') || (_.has(property, 'id') && typeof _.get(property, 'id') === 'string')) {
            const id = (() => {
                const stringId = typeof property === 'string' ? property : _.get(property, 'id')
                if (_.includes(stringId, '/')) {
                    const split = _.filter(_.map(_.split(`${stringId}`, '/'), e => _.replace(e, ' ', '')), e => _.size(e))
                    return _.last(split) || ''
                }
                return stringId
            })()
            if (typeof id !== 'string') return property
            const testRef = fb.doc(db, this.collectionName, id)
            return await fb.getDoc(testRef).then((snap) => snap.exists() ? testRef : property).catch(() => property)
        }
        return property
    }

    static async getReference(object, includeRef = true) {
        if (object instanceof fb.DocumentReference) {
            const doc = await fb.getDoc(object)
            return await this.populateSnapshot(doc)
        }
        const ref = await this.ensureReference(object)
        if (ref instanceof fb.DocumentReference) {
            const doc = await fb.getDoc(object)
            return await this.populateSnapshot(doc)
        }
        return ref
    }

    static async get(obj, options = {}) {
        if (!obj) {
            return null
        }
        if (options) {
            Object?.keys(options)?.forEach((key) => this[key] = options[key])
        }

        if (typeof obj === 'string') {
            this.id = obj
        }

        else if (obj?.id) {
            this.id = obj.id
        }



        try {
            const doc = await fb.getDoc(fb.doc(this.collection, this.id))
            if (doc.exists()) {
                return doc.data()
            }
            else {
                return null
            }
        }
        catch (e) {
            return null
        }
    }

    static async getCollection() {
        const req = await fb.getDocs(this.collection)
        return await Promise.all(req?.docs?.map(async (doc) => await doc.data()))
    }

    static async query(field, value) {
        try {
            const q1 = fb.query(this.collection, fb.where(field, '==', value))
            const req1 = await fb.getDocs(q1)
            const docs1 = await Promise.all(req1?.docs?.map(async (doc) => await doc.data()))

            if (value.id) {
                try {
                    const q2 = fb.query(this.collection, fb.where(`${field}.id`, '==', value?.id))
                    const req2 = await fb.getDocs(q2)
                    const docs2 = await Promise.all(req2?.docs?.map(async (doc) => await doc.data()))
                    return docs1.concat(docs2)
                }
                catch {
                    return docs1
                }
            }
            return docs1
        }
        catch {
            return []
        }

    }

    onChange(path, data) {
        const obj = Object.getPrototypeOf(this)?.constructor?.classOnChange(_.set(_.cloneDeep(this), path, data))
        return obj
    }

    static classOnChange(object) {
        return object
    }

    static async create(e, data, callback = null) {
        e.preventDefault()
        const object = _.cloneDeep(data)
        await fb.setDoc(object.ref, object)
        callback && callback()
        return object


    }

    static setReferenceField(data) {
        if (!data) {
            return null
        }
        else if (typeof data === 'string') {
            return fb.doc(Object.getPrototypeOf(this).constructor.collection, data)
        }
        else if (data?.ref) {
            return data.ref
        }
        else if (data?.id) {
            return fb.doc(this.collection, data?.id)
        }
        else {
            const instance = Object?.getPrototypeOf(data)
            if (instance?.constructor?.transformToFirestore) {
                return instance?.constructor?.transformToFirestore(data)
            }
            return data
        }

    }

    async submit(e = null) {
        e && e.preventDefault()
        const docRef = this?.ref ?
            fb.doc(Object.getPrototypeOf(this).constructor.collection, this?.id) :
            fb.doc(Object.getPrototypeOf(this).constructor.collection)

        if (!this?.id) {
            this.id = docRef.id
            this.ref = docRef
        }
        if (Object.getPrototypeOf(this).constructor.uploadImages) {
            this.images = await Object.getPrototypeOf(this).constructor.uploadImages(this)
        }

        return await fb.setDoc(docRef, this)
    }

    static async compressImage(image) {
        const options = {
            maxSizeMB: 0.2,
        }
        try {
            return await imageCompression(image, options)
        }
        catch {
            return image
        }

    }

    static async uploadImage(image, savePath = null) {
        const basePath = savePath || `${this.collectionName}/${this.id}`
        const relativePath = `/${image?.lastModified}-${image?.name}`
        const fullPath = `${basePath}${relativePath}`?.replace('//', '/')

        if (typeof image === 'object') {
            const compressed = await this.compressImage(image)
            const storageRef = ref(storage, fullPath)
            const upload = await uploadBytes(storageRef, compressed)
            const url = await getDownloadURL(upload.ref)

            return url
        }
        else {
            return image
        }
    }

}



