import Model from "../Model";
import * as fb from 'firebase/firestore'
import Price from "../Price/Price";

export default class Product extends Model {
    constructor(data) {
        super(data)
        this.name = data.name
        this.description = data.description
        this.active = data.active
        this.sizeUnit = data.sizeUnit
        this.sizeVisibleOnCard = data.sizeVisibleOnCard
        this.category = data.category
        this.image = data.image
        this.sortOrder = data.sortOrder
        this.pricingType = data.pricingType
        this.stripeId = data.stripeId

    }

    static collectionName = 'pricing'

    static defaultProductImage = require('../../assets/siteImages/itemPlaceHolder.png')

    async getPrices() {
        const docs = await Product.getDocs(fb.query(Price.collection, fb.where('product', '==', this.ref)))
        return docs
    }

    static async init(data) {

        const d =
        {
            name: data?.name || null,
            description: data?.description || null,
            active: data?.active || false,
            sizeUnit: data?.sizeUnit || 'cu. ft.',
            sizeVisibleOnCard: data?.sizeVisibleOnCard || false,
            category: data?.category || null,
            image: data?.image || this.defaultProductImage,
            sortOrder: data?.sortOrder || null,
            pricingType: data?.pricingType || null,
            stripeId: data?.stripeId || null

        }
        return new Product(d)
    }


}