import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import Product from '../../classes/firebase/Product/Product';
import ProductListCard from '../../classes/firebase/Product/views/ProductListCard';

export default function Pricing() {
  const [products, setProducts] = useState([])
  const [activeCategory, setActiveCategory] = useState('Boxes');
  const [tabs, setTabs] = useState(null)

  useEffect(() => {
    (async () => {
      setProducts(await Product.getActiveProducts())
    })()

  }, [])
  


  useEffect(() => {
    setTabs(pricingCategories)
  }, [])

  const navigateCategories = (value) => {
    if (value === activeCategory) {
      return;
    }

    setActiveCategory(value);
  };

  const pricingCategories = [
    {
      name: 'Boxes',
      icon: 'box'
    },
    {
      name: 'Bins',
      icon: 'inbox'
    },
    {
      name: 'Furniture',
      icon: 'couch'
    },
    {
      name: 'Luggage',
      icon: 'briefcase'
    },
    {
      name: 'Decor',
      icon: 'bed'
    },
    {
      name: 'Misc',
      icon: 'ellipsis-h'
    }
  ]


  return (
    <MDBContainer fluid className='mx-0 px-0'>
      <MDBRow className={'d-flex justify-content-center mb-2'}>
        <MDBTabs pills className={`d-flex justify-content-center text-center mx-auto`}>
          {tabs && tabs.map((t, i) => (
            <MDBTabsItem key={`${i}Tab`} className='me-1 ms-0 my-2' style={{ minWidth: '150px' }}>
              <MDBTabsLink onClick={() => navigateCategories(t.name)} active={activeCategory === t.name} className='px-1'>
                <MDBIcon fas icon={t.icon} className='me-2' size='lg' />{t.name}
              </MDBTabsLink>
            </MDBTabsItem>
          ))}
        </MDBTabs>
      </MDBRow>
      <MDBRow>
        <MDBTabsContent>
          {tabs && tabs.map((t, i) => (
            <MDBTabsPane show={activeCategory === t.name} key={`${i}Pane`}>
              <ProductsGrid products={products} activeCategory={activeCategory} />
            </MDBTabsPane>
          ))}
        </MDBTabsContent>

      </MDBRow>

    </MDBContainer>
  )
}


export const ProductsGrid = ({ products, activeCategory }) => {

  return (
    <MDBContainer>
      <MDBRow>
        {products && [...products]?.filter((product) => product?.metadata?.category === activeCategory)
          ?.sort((a, b) => a?.metadata?.sortOrder - b?.metadata?.sortOrder > 0 ? 1 : -1)
          ?.map((product, index) => (
            <MDBCol xl={4} lg={6} className='mb-4' key={`product${index}`} >
              <ProductListCard product={product} />
            </MDBCol>
          ))}
      </MDBRow>
    </MDBContainer>
  )
}


