import imageCompression from 'browser-image-compression';
import * as fb from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import _ from 'lodash';
import { db, storage } from "../firebase/firebaseConfig";

export default class Model {

    static get collection() {
        return fb.collection(db, this.collectionName).withConverter(this.converter)
    }

    static get converter() {
        return {
            fromFirestore: async (snapshot, options) => await this.fromFirestore(snapshot, options),
            toFirestore: (data) => this.toFirestore(data)
        }
    }

    static async fromFirestore(snapshot, options) {

        const data = snapshot.data()
        if (options) {
            Object?.keys(options)?.forEach((key) => data[key] = options[key])
        }
        const transformed = await this.init(data)
        transformed.id = this.id = snapshot?.id || null
        transformed.ref = this.ref = snapshot?.ref || null
        if (!data?.time) {
            const time = fb.Timestamp.now()
            await fb.updateDoc(transformed.ref, { time: time })
            transformed.time = time
        }
        return transformed
    }


    async init(data) {
        return data
    }

    static toFirestore(data) {
        const transformed = this.transformToFirestore(data)
        const { id, ref, ...filtered } = transformed
        return filtered
    }

    static transformToFirestore(data) {
        return data
    }

    static async compressImage(image) {
        const options = {
            maxSizeMB: 0.2,
        }
        try {
            return await imageCompression(image, options)
        }
        catch {
            return image
        }

    }

    static async uploadImage(image, savePath = null) {
        const basePath = savePath || `${this.collectionName}/${this.id}`
        const relativePath = `/${image?.lastModified}-${image?.name}`
        const fullPath = `${basePath}${relativePath}`?.replace('//', '/')

        if (typeof image === 'object') {
            const compressed = await this.compressImage(image)
            const storageRef = ref(storage, fullPath)
            const upload = await uploadBytes(storageRef, compressed)
            const url = await getDownloadURL(upload.ref)

            return url
        }
        else {
            return image
        }
    }

    static clone(instance) {
        return _.cloneDeep(instance)
    }

    onChange(path, data) {
        const obj = _.set(Object.getPrototypeOf(this).constructor.clone(this), path, data)
        return obj
    }

    async submit(e) {
        e.preventDefault()
        const docRef = this?.ref ?
            fb.doc(Object.getPrototypeOf(this).constructor.collection, this?.id) :
            fb.doc(Object.getPrototypeOf(this).constructor.collection)

        if (!this?.id) {
            this.id = docRef.id
            this.ref = docRef
        }
        if (Object.getPrototypeOf(this).constructor.uploadImages) {
            this.images = await Object.getPrototypeOf(this).constructor.uploadImages(this)
        }

        return await fb.setDoc(docRef, this)
    }

    static async getActiveDocs() {
        const q = fb.query(this.collection, fb.where('active', '==', true))
        const docs = await this.getDocs(q)
        return docs

    }

    static async getDocs(query = fb.query(this.collection)) {
        const req = await fb.getDocs(query)
        return await Promise.all(req?.docs?.map(async (doc) => await doc.data()))

    }

    static async get(obj, options = {}) {
        if (options) {
            Object?.keys(options)?.forEach((key) => this[key] = options[key])
        }

        if (obj?.id) {
            this.id = obj.id
        }
        try {
            const doc = await fb.getDocFromCache(fb.doc(this.collection, this.id))
            if (doc.exists) {
                return doc.data()
            }
        }
        catch { }

        try {
            const doc = await fb.getDoc(fb.doc(this.collection, this.id))
            if (doc.exists) {
                return doc.data()
            }
            else {
                return null
            }
        }
        catch (e) {
            return null
        }
    }

    static async getByRef(ref, options = {}) {

        if (ref?.id) {
            return await this.getById(ref.id, options)
        }
        return null
    }

    static async getById(id, options = {}) {
        if (options) {
            Object?.keys(options)?.forEach((key) => this[key] = options[key])
        }
        if (id) {

            this.id = id
            return await this.get()
        }
        return null
    }





}