import React from 'react'

export default function FormInput({
    label = '',
    name = '',
    value = '',
    type = 'text',
    onChange = (e) => { },
    disabled = false
}) {
    return (
        <>
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                value={value}
                name={name}
                onChange={onChange}
                disabled={disabled}
            />
        </>
    )
}
