import { MDBCollapse, MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarItem, MDBNavbarLink, MDBNavbarNav, MDBNavbarToggler } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

export default function NavigationBar() {
  const { user, isAuthenticated, logout } = useAuth()
  const location = useLocation()
  const [navCollapsed, setNavCollapsed] = useState(false)
  if (!location.pathname.toLowerCase().includes('/admin')) {
    return (
      <MDBNavbar expand='md' fixed='top' light bgColor='light' className='mb-0' style={{ height: navCollapsed ? 'auto' : '95px' }}>
        <MDBContainer fluid >
          <MDBNavbarBrand href='/'>
            <img
              src={require('../assets/siteImages/navBarLogo.png')}
              style={{ height: '75px' }}
              alt='Valet Student Storage'
              loading='lazy'
            />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setNavCollapsed(!navCollapsed)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={navCollapsed}>
            <NavLinks user={user} location={location} logout={logout} isAuthenticated={isAuthenticated} />
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    )
  }
}


const NavLinks = (props) => {
  const user = props.user
  const location = props.location
  const logout = props.logout
  const isAuthenticated = props.isAuthenticated
  const pages = [
    {
      path: '/pricing',
      title: 'Pricing'
    },
    {
      path: '/about',
      title: 'About'
    },
    {
      path: '/faq',
      title: 'FAQ'
    }
  ]
  return (
    <>
      <MDBNavbarNav>
        {isAuthenticated && user && user.role === 'admin' && <MDBNavbarLink tag='a' href={'/admin/'} >Admin</MDBNavbarLink>}
        {pages && pages.map((page, index) => (
          <MDBNavbarLink key={index} tag='a' href={page.path} active={location.pathname.toLowerCase() === page.path.toLowerCase()} aria-current={location.pathname.toLowerCase() === page.path.toLowerCase()} >{page.title}</MDBNavbarLink>
        ))}
      </MDBNavbarNav>
      <MDBNavbarNav right fullWidth={false}>
        <MDBNavbarLink tag='a' href={isAuthenticated ? "/account" : "/authenticate"} active={location.pathname.toLowerCase() === (isAuthenticated ? "/account" : "/authenticate".toLowerCase())} aria-current={(location.pathname.toLowerCase() === isAuthenticated ? "/account" : "/authenticate".toLowerCase())}>{isAuthenticated ? "Account" : "Login"}
        </MDBNavbarLink>
        {isAuthenticated && <MDBNavbarLink onClick={async (e) => await logout(e)} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
          Sign Out
        </MDBNavbarLink>}
      </MDBNavbarNav>
    </>
  )
}
